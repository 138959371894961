import { Pipe, PipeTransform } from "@angular/core";
import { MlpHelper } from "../shared/mlpHelper";
import { Pitch } from "../shared/model/pitch";

@Pipe({
    name: "sportFormatSurfaceText",
})
export class SportFormatSurfaceTextPipe implements PipeTransform {
    transform(pitch: Pitch): any {
        let returnText;

        if (pitch) {
            const format = pitch.attributes.format.toLowerCase();
            const surface = pitch.attributes.surface.toLowerCase();
            const sport = pitch.attributes.sport.toLowerCase();

            if (SportFormatSurfaceTextPipe.isValid(format)) {
                returnText = MlpHelper.uppercaseFirstLetter(format);
            }

            if (
                SportFormatSurfaceTextPipe.isValid(surface) &&
                format != surface
            ) {
                if (!returnText) {
                    returnText = surface;
                } else {
                    returnText = `${returnText} | ${MlpHelper.uppercaseFirstLetter(
                        surface
                    )}`;
                }
            }

            if (!returnText) {
                returnText = MlpHelper.uppercaseFirstLetter(sport);
            }

            returnText = returnText
                .replace("3g", "3G")
                .replace("astroturf", "Astroturf")
                .replace("It room", "IT room");
        }

        return returnText;
    }

    private static isValid(format: string) {
        if (format == "n/a") {
            return false;
        } else if (!format) {
            return false;
        }

        return true;
    }
}
