import { Injectable } from "@angular/core";
import {
    IBootstrapDateFormat,
    IBootstrapTimeFormat,
} from "./model/ngBootstrap.interface";

@Injectable({
    providedIn: "root",
})
export class DateService {
    prependZero(data: string | number): string {
        return `0${data}`.slice(-2);
    }

    addHours(date: Date, hours = 3): Date {
        return new Date(date.setTime(date.getTime() + hours * 60 * 60 * 1000));
    }

    createFilterDate(
        year: number | string,
        month: number | string,
        day: number | string
    ): string {
        const updateMonth = this.prependZero(month);
        const updateDay = this.prependZero(day);

        return `${year}-${updateMonth}-${updateDay}`;
    }

    createFilterTime(hours: number | string, minutes: number | string): string {
        const updateHours = this.prependZero(hours);
        const updateMinutes = this.prependZero(minutes);

        return `${updateHours}:${updateMinutes}`;
    }

    createFullDateObject(
        date: IBootstrapDateFormat | string,
        timeData?: IBootstrapTimeFormat
    ): Date {
        let time: IBootstrapTimeFormat = { hour: 0, minute: 0, second: 0 };

        if (typeof timeData === "object") {
            time = timeData;
            time.second = 0;
        }

        if (typeof date === "string") {
            time = this.formatTimeString(date);
            date = this.formatDateString(date);
        }

        return new Date(
            date.year,
            date.month,
            date.day,
            time.hour,
            time.minute,
            time.second
        );
    }

    /**
     * String format 2017-08-08 04:00
     * The space in between date and time is important and order.
     */
    formatDateString(sentDate: string, toDateObj = true): IBootstrapDateFormat {
        const splitDate = sentDate.split(" ");
        const dateHolder = splitDate[0];
        const dateArray = dateHolder.split("-");

        const date: IBootstrapDateFormat = {
            year: parseInt(dateArray[0], 10),
            month: toDateObj
                ? parseInt(dateArray[1], 10) - 1
                : parseInt(dateArray[1], 10),
            day: parseInt(dateArray[2], 10),
        };

        return date;
    }

    formatTimeString(date: string): IBootstrapTimeFormat {
        const splitTime = date.split(" ")[1].split(":");
        const time: IBootstrapTimeFormat = {
            hour: parseInt(splitTime[0], 10),
            minute: parseInt(splitTime[1], 10),
            second: 0,
        };

        return time;
    }

    fullDateSearchFormat(date: Date): string {
        const newDate = this.createFilterDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
        const newTime = this.createFilterTime(
            date.getHours(),
            date.getMinutes()
        );

        return `${newDate} ${newTime}`;
    }

    getTime(date?: Date): number {
        return date.getTime();
    }

    sameDay(d1, d2): boolean {
        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    }

    now(): Date {
        return new Date();
    }

    createTimeRange(hourOnly = false): Array<string> {
        const data = [];
        let val: string;

        for (let v = 7; v < 24; v++) {
            if (hourOnly) val = `0${v}`.slice(-2);
            if (!hourOnly) val = `0${v}:00`.slice(-5);
            data.push(val);
        }

        return data;
    }
}
