import { IMegaFooter } from "pf_styleguide";

export const MEGAFOOTERDATA: IMegaFooter = {
    links1: {
        title: "Quick Links",
        description: "Find what you’re looking for in a flash",
        links: [
            {
                name: "Playfinder",
                href: "/",
            },
            {
                name: "About us",
                href: "/about",
            },
            {
                name: "In the press",
                href: "/promo/in-the-press",
            },
            {
                name: "App",
                href: "/app",
            },
            {
                name: "Blog",
                href: "/blog",
            },
            {
                name: "Careers",
                href: "/promo/careers",
            },
            {
                name: "Contact us",
                href: "/contact",
            },
            {
                name: "Terms and conditions",
                href: "/terms",
            },
            {
                name: "Privacy policy",
                href: "/privacy",
            },
            {
                name: "Sitemap",
                href: "/sitemap",
            },
        ],
    },
    links2: {
        title: "Book Sports Facilities",
        sports: {
            description: "Find a facility in",
        },
        city: {
            description: "Find a facility in your city",
        },
    },
    links3: {
        title: "For Sports Venue Operators",
        links: [
            {
                name: "Increase exposure with Playfinder",
                href: "/partner",
                description: `Get listed on the UK's leading facility booking marketplace which
                 has helped millions find somewhere to play sport. 
                We will help you fill spare capacity and reach a new audience`,
            },
            {
                name: "Manage bookings with Bookteq",
                href: "https://www.bookteq.com/",
                description: `Bookteq is the complete facility booking software for operators
                such as councils, schools and sports clubs. Manage real-time
                bookings, payments and customers with our market leading service`,
            },
            {
                name: "",
                href: "",
                description: ``,
            },
        ],
    },
    links4: {
        title: "Community",
        description: "What we’re doing to improve the world of sport",
        links: [
            {
                name: "Supporting Grassroots Projects",
                href: "/promo/community",
            },
            {
                name: "Outstanding Sports Facility Award",
                href: "/promo/outstanding-sports-facility-award",
            },
        ],
    },
    links5: {
        title: "Events",
        description: "Organise events and hire space",
        links: [
            {
                name: "Corporate events",
                href: "/promo/corporate-events",
            },
            {
                name: "Kids parties",
                href: "/promo/kids-football-parties",
            },
            {
                name: "Filming at sports facilities",
                href: "/promo/filming-sports-facilities",
            },
            {
                name: "Stadium hire",
                href: "/promo/stadium-hire",
            },
            {
                name: "Bubble football",
                href: "/promo/bubble-football",
            },
        ],
    },
    payments: [
        "custom-cc-stripe",
        "custom-cc-visa",
        "custom-cc-mastercard",
        "custom-cc-amex",
    ],
    app: {
        title: "Get the App",
        images: [
            {
                name: "ios",
                src: "/ng-assets/download-app.svg",
                href: "https://itunes.apple.com/gb/app/playfinder/id1020056658?pt=117881873&ct=MLPwebsite_footer&mt=8",
            },
            {
                name: "android",
                src: "/ng-assets/google-play-badge.svg",
                href: "https://play.google.com/store/apps/details?id=com.mylocalpitch&referrer=utm_source%3DMLPwebsite_footer",
            },
        ],
    },
    signup: {
        title: "The best emails in the game",
        description:
            "The latest offers and availability delivered straight to your inbox",
    },
    social: {
        title: "Follow Playfinder",
        description: "Follow us for great sporting content",
        links: [
            {
                href: "https://www.facebook.com/playfinder",
                icon: "social-media-facebook-1",
            },
            {
                href: "https://www.twitter.com/playfinder",
                icon: "social-media-twitter",
            },
            {
                href: "https://www.instagram.com/playfinder/",
                icon: "social-instagram",
            },
            {
                href: "https://www.linkedin.com/company/playfinder",
                icon: "professional-network-linkedin",
            },
        ],
    },
    logo: "/ng-assets/styleguide/logo/playfinder_bookteq_stacked.svg",
    bcorpLogo: "/ng-assets/styleguide/img/certified_b_corporation_logo.svg",
    logoPin: "/ng-assets/styleguide/logo/pf_logo_pin_only.svg",
    logoPinAlt: "logo pin",
    gdprLogo: {
        width: 95,
        src: "/ng-assets/styleguide/img/gdpr.svg",
        alt: "GDPR compliant",
    },
    somewhere: "#SportMadeSimple",
};
