import { Component, forwardRef, Input, OnInit } from "@angular/core";

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms";

import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { MlpHelper } from "../../mlpHelper";
import addDays from "date-fns/addDays";

import { DateService } from "../../date.service";

@Component({
    selector: "mlp-select-date",
    templateUrl: "./select-date.component.html",
    styleUrls: ["./select-date.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => SelectDateComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectDateComponent,
        },
    ],
})
export class SelectDateComponent
    implements ControlValueAccessor, Validator, OnInit
{
    // tslint:disable-next-line:no-input-rename
    @Input("value") _value: NgbDate;
    @Input() placeholder = "Select Date";
    @Input() showError: boolean;
    @Input() maxDateDays: number;
    @Input() minDate: NgbDateStruct;
    @Input() maxDate: NgbDateStruct;

    now: Date = this.dateService.now();

    formControl: AbstractControl;

    constructor(public dateService: DateService) {}

    ngOnInit(): void {
        if (this.maxDateDays) {
            this.maxDate = this.createDate(this.now, this.maxDateDays);
        }
        if (!this.minDate) {
            this.minDate = this.createDate(this.now);
        }
    }

    get value(): NgbDate {
        return this._value;
    }
    set value(val: NgbDate) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    writeValue(value: NgbDate): void {
        this.value = value;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        this.formControl = c;

        return c.valid ? undefined : c.errors;
    }

    // tslint:disable-next-line:no-empty
    onChange: any = () => {};
    // tslint:disable-next-line:no-empty
    onTouched: any = () => {};

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    selectedDate($event: NgbDate): void {
        this.value = $event;
    }

    formatValue(value: NgbDate): string {
        return `${MlpHelper.pad(value.day, 2)}/${MlpHelper.pad(
            value.month,
            2
        )}/${value.year}`;
    }

    createDate(date: Date, days?: number): NgbDateStruct {
        if (days) date = addDays(date, days);

        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };
    }
}
