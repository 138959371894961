import { CookieService } from "ngx-cookie";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "../../server/httpService";
import { BrowserService } from "./browser.service";
import { MlpHelper } from "./mlpHelper";
import * as i0 from "@angular/core";
import * as i1 from "../../server/httpService";
import * as i2 from "ngx-cookie";
import * as i3 from "./browser.service";
var SportSelectService = /** @class */ (function () {
    function SportSelectService(httpService, cookie, browserService) {
        this.httpService = httpService;
        this.cookie = cookie;
        this.browserService = browserService;
        this.sports = new BehaviorSubject([]);
        this.sportsCollected = [];
    }
    SportSelectService.prototype.getSportsAsync = function (city) {
        return this.httpService
            .get("/sports" + (city ? "?filter[city]=" + city : ""))
            .pipe(map(function (data) { return data.data; }));
    };
    SportSelectService.prototype.getSports = function () {
        var _this = this;
        this.getSportsAsync().subscribe(function (items) {
            _this.sportsCollected = items;
            _this.sports.next(items);
        });
    };
    SportSelectService.prototype.setSport = function (sport) {
        if (this.browserService.isBrowser) {
            this.cookie.put("sport", sport, MlpHelper.oneYearFromNow());
        }
    };
    SportSelectService.prototype.getSport = function () {
        if (this.browserService.isBrowser) {
            return this.cookie.get("sport");
        }
    };
    SportSelectService.ngInjectableDef = i0.defineInjectable({ factory: function SportSelectService_Factory() { return new SportSelectService(i0.inject(i1.HttpService), i0.inject(i2.CookieService), i0.inject(i3.BrowserService)); }, token: SportSelectService, providedIn: "root" });
    return SportSelectService;
}());
export { SportSelectService };
