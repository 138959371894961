import { EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import { mlpEmailValidator } from "pf_styleguide";
import { tap } from "rxjs/operators";
import { ConfigureService } from "./../../../../configure/configure.service";
import { EventTrackingService } from "./../../../eventTracking.service";
import { GenericService } from "./../../../generic.service";
import { ErrorCollection } from "./../../../model/errorCollection";
import { AuthService } from "./../auth.service";
var SignInComponent = /** @class */ (function () {
    function SignInComponent(authService, genericService, fb, config, eventTrackingService, router) {
        this.authService = authService;
        this.genericService = genericService;
        this.fb = fb;
        this.config = config;
        this.eventTrackingService = eventTrackingService;
        this.router = router;
        this.loginSuccess = new EventEmitter();
        this.closeModal = new EventEmitter();
        this.showSignUp = new EventEmitter();
        this.back = new EventEmitter();
        this.guest = new EventEmitter();
        this.checkout = false;
        this.errorCollection = new ErrorCollection();
        this.success = false;
        this.buildForm();
    }
    Object.defineProperty(SignInComponent.prototype, "username", {
        get: function () {
            return this.formGroup.get("username");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInComponent.prototype, "password", {
        get: function () {
            return this.formGroup.get("password");
        },
        enumerable: true,
        configurable: true
    });
    SignInComponent.prototype.ngOnInit = function () {
        this.resetForm();
    };
    SignInComponent.prototype.closeForm = function () {
        this.closeModal.emit();
    };
    SignInComponent.prototype.onSubmit = function () {
        var _this = this;
        this.genericService.markFormControlsAsTouched(this.formGroup);
        if (this.formGroup.valid) {
            this.resetForm();
            this.authService
                .login(this.username.value, this.password.value)
                .pipe(tap(function (_) {
                _this.eventTrackingService.trackEvent("Account Log In", "user", _this.router.url);
            }))
                .subscribe(function () {
                _this.success = true;
                _this.loginSuccess.emit(_this.success);
            }, function (errorData) {
                _this.errorCollection.errors = errorData;
                _this.loginSuccess.emit(_this.success);
            });
        }
    };
    SignInComponent.prototype.loginSuccessProcess = function ($event) {
        this.loginSuccess.emit($event);
    };
    SignInComponent.prototype.resetForm = function () {
        this.success = false;
        this.errorCollection.reset();
    };
    SignInComponent.prototype.buildForm = function () {
        var _this = this;
        this.formGroup = this.fb.group({
            username: ["", [mlpEmailValidator()]],
            password: ["", [Validators.required]],
        });
        this.formGroup.controls.username.valueChanges.subscribe(function (v) { return (_this.errorCollection.errors = undefined); });
        this.formGroup.controls.password.valueChanges.subscribe(function (v) { return (_this.errorCollection.errors = undefined); });
    };
    return SignInComponent;
}());
export { SignInComponent };
