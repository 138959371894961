import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
    NgbDatepickerModule,
    NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";

import { IconSharedModule } from "pf_styleguide";
import { SelectDateComponent } from "./select-date.component";

@NgModule({
    declarations: [SelectDateComponent],
    imports: [
        CommonModule,
        NgbDropdownModule,
        NgbDatepickerModule,

        IconSharedModule,
    ],
    exports: [SelectDateComponent],
    providers: [],
})
export class SelectDateModule {}
