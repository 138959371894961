import {
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

import { ISelectMolecule } from "pf_styleguide";
import { MlpHelper } from "../mlpHelper";

@Component({
    selector: "pf-location-select",
    templateUrl: "./location-select.component.html",
    styleUrls: ["./location-select.component.scss"],
    // tslint:disable-next-line:use-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class LocationSelectComponent implements OnChanges, OnInit {
    defaultImages = [
        "/ng-assets/guys-background.jpg?" +
            MlpHelper.imageServiceDefaultParams(),
        "/ng-assets/guys-alpha-1920x1080.png?" +
            MlpHelper.imageServiceDefaultParams(),
    ];
    @Input() showCities = false;
    @Input() cities: Array<ISelectMolecule>;
    @Input() title = "<span>Find</span> your <span>play</span>";
    @Input() description =
        "Book and play at over <b>7000</b> pitches and courts";
    @Input() sport: string;
    @Input() backgroundUrl: Array<string> = this.defaultImages;
    @Input() logo: string;
    @Input() checkImage = false;
    images: SafeStyle;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(): void {
        this.updateImages();
    }

    ngOnInit(): void {
        this.updateImages();
    }

    updateImages(): void {
        this.logo = this.logo;
        this.backgroundUrl = this.backgroundUrl.length
            ? this.backgroundUrl
            : this.defaultImages;
        this.images = this.sanitizer.bypassSecurityTrustStyle(
            this.checkImages()
        );
    }

    checkImages(): any {
        return this.checkImage || this.sport
            ? `url('/ng-assets/shard-ok.png'), url(${this.backgroundUrl[0]})`
            : `url(${this.backgroundUrl[1]}), url('/ng-assets/shard-ok.png'), url(${this.backgroundUrl[0]})`;
    }
}
