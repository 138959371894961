import { Pipe, PipeTransform } from "@angular/core";
import { VenueData } from "../shared/model/venue";
import { Pitch } from "../shared/model/pitch";

@Pipe({
    name: "sportToPitchCourtText",
})
export class SportToPitchCourtTextPipe implements PipeTransform {
    transform(
        sportName: string | undefined,
        defaultValue: string,
        venueData: VenueData = null,
        forcePlural: boolean = false
    ): any {
        let returnValue = defaultValue;

        if (sportName) {
            let plural = false;

            if (forcePlural) {
                plural = true;
            } else {
                if (venueData) {
                    let countPitchesWithSameSport = 0;

                    venueData.included.forEach((pitch: Pitch) => {
                        if (
                            pitch.attributes.sport.toLowerCase() ==
                            sportName.toLowerCase()
                        ) {
                            countPitchesWithSameSport++;
                        }
                    });

                    if (countPitchesWithSameSport == 0) {
                        return defaultValue;
                    }

                    if (countPitchesWithSameSport > 1) {
                        plural = true;
                    }
                }
            }

            switch (sportName.toLowerCase()) {
                case "athletics": {
                    returnValue = "Athletics " + (!plural ? "Track" : "Tracks");
                    break;
                }
                case "badminton": {
                    returnValue = "Badminton " + (!plural ? "Court" : "Courts");
                    break;
                }
                case "basketball": {
                    returnValue =
                        "Basketball " + (!plural ? "Court" : "Courts");
                    break;
                }
                case "cricket": {
                    returnValue = "Cricket Facilities";
                    break;
                }
                case "cycling": {
                    returnValue = "Cycling " + (!plural ? "Venue" : "Venues");
                    break;
                }
                case "football": {
                    returnValue = "Football " + (!plural ? "Pitch" : "Pitches");
                    break;
                }
                case "futsal": {
                    returnValue = "Futsal " + (!plural ? "Pitch" : "Pitches");
                    break;
                }
                case "gaa": {
                    returnValue = "GAA " + (!plural ? "Pitch" : "Pitches");
                    break;
                }
                case "golf": {
                    returnValue = "Golf " + (!plural ? "Course" : "Courses");
                    break;
                }
                case "gym": {
                    returnValue = !plural ? "Gym" : "Gyms";
                    break;
                }
                case "hockey": {
                    returnValue = "Hockey " + (!plural ? "Pitch" : "Pitches");
                    break;
                }
                case "netball": {
                    returnValue = "Netball " + (!plural ? "Court" : "Courts");
                    break;
                }
                case "rowing": {
                    returnValue = "Rowing " + (!plural ? "Club" : "Clubs");
                    break;
                }
                case "rugby": {
                    returnValue = "Rugby " + (!plural ? "Pitch" : "Pitches");
                    break;
                }
                case "squash": {
                    returnValue = "Squash " + (!plural ? "Court" : "Courts");
                    break;
                }
                case "swimming": {
                    returnValue = "Swimming " + (!plural ? "Pool" : "Pools");
                    break;
                }
                case "tennis": {
                    returnValue = "Tennis " + (!plural ? "Court" : "Courts");
                    break;
                }
                case "american football":
                case "american-football": {
                    returnValue = `American Football ${
                        !plural ? "Field" : "Fields"
                    }`;
                    break;
                }
                case "esports": {
                    returnValue = `Esports ${!plural ? "Arena" : "Arenas"}`;
                    break;
                }
                case "handball": {
                    returnValue = `Handball ${!plural ? "Court" : "Courts"}`;
                    break;
                }
                case "lacrosse": {
                    returnValue = `Pitch ${!plural ? "Pitch" : "Pitches"}`;
                    break;
                }
                case "quidditch": {
                    returnValue = `Quidditch ${!plural ? "Pitch" : "Pitchs"}`;
                    break;
                }
                case "space hire":
                case "space-hire": {
                    returnValue = "Space Hire";
                    break;
                }
                case "table tennis":
                case "table-tennis": {
                    returnValue = `Table Tennis ${
                        !plural ? "Table" : "Tables"
                    }`;
                    break;
                }
                case "padel tennis":
                case "padel-tennis": {
                    returnValue = `Padel Tennis ${
                        !plural ? "Court" : "Courts"
                    }`;
                    break;
                }
                case "volleyball": {
                    returnValue = `Volleyball ${!plural ? "Court" : "Courts"}`;
                    break;
                }
            }
        }

        return returnValue;
    }
}
