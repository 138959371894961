/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./errors.component";
var styles_ErrorsComponent = [];
var RenderType_ErrorsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorsComponent, data: {} });
export { RenderType_ErrorsComponent as RenderType_ErrorsComponent };
function View_ErrorsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "sg-color__danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n    ", " ", "\n"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; var currVal_1 = _v.context.$implicit.description; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ErrorsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorsComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorCollection.errors; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-errors", [], null, null, null, View_ErrorsComponent_0, RenderType_ErrorsComponent)), i0.ɵdid(1, 49152, null, 0, i2.ErrorsComponent, [], null, null)], null, null); }
var ErrorsComponentNgFactory = i0.ɵccf("mlp-errors", i2.ErrorsComponent, View_ErrorsComponent_Host_0, { errorCollection: "errorCollection" }, {}, []);
export { ErrorsComponentNgFactory as ErrorsComponentNgFactory };
