import { Injectable } from "@angular/core";

@Injectable()
export class DiscontinuedSportService {
    private discontinuedSports = ["cycling", "rowing"];

    isDiscontinuedSport(sport: string): boolean {
        return this.discontinuedSports.indexOf(sport) > -1;
    }
}
