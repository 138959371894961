import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

import { INavDropdown } from "pf_styleguide";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { ConfigureService } from "../configure/configure.service";
import { GenericService } from "./generic.service";
import { Blog, BlogAuthor, BlogCategory } from "./model/blogs";

@Injectable()
export class BlogService {
    blogsLatestPage = 1;
    blogsLatestPerPage = 5;
    blogsLatestTotal = 0;
    blogPostsInCategoryTotal = 0;
    loading = false;
    blogMenuLinks: Array<{ name: string; url: string }> = [];

    categories: Array<BlogCategory> = [];
    categoriesLoadedEvent: EventEmitter<Array<BlogCategory>> = new EventEmitter(
        true
    );
    blogsPopular: Array<Blog> = [];
    blogsFeatured: Array<Blog> = [];

    constructor(
        private http: HttpClient,
        private genServ: GenericService,
        private config: ConfigureService
    ) {}

    /**
     * Currently cities are london-2, manchester, dublin
     * @return Observable of 3 blog posts
     */
    getBlogPosts(city?: string | undefined): Observable<Array<Blog>> {
        let cityParams = "";

        if (city !== "uk") {
            cityParams = `&filter[taxonomy]=category&filter[term]=${city}`;
        }

        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?per_page=3${cityParams}`
            )
            .pipe(catchError(this.genServ.handleError));
    }

    getAllBlogPosts(): Observable<Array<Blog>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?per_page=100`
            )
            .pipe(catchError(this.genServ.handleError));
    }

    getBlogLatest(page: number, perPage: number): Observable<Array<Blog>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?per_page=${perPage}&page=${page}`,
                { observe: "response" }
            )
            .pipe(
                map((response: any) => {
                    this.blogsLatestTotal = Number(
                        response.headers.get("X-WP-Total")
                    );

                    return response.body;
                }),
                catchError(this.genServ.handleError)
            );
    }

    callBlogPopular(): Observable<Array<Blog>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?per_page=5&categories=1936`
            )
            .pipe(catchError(this.genServ.handleError));
    }

    callBlogFeatured(): Observable<Array<Blog>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?per_page=5&categories=1935`
            )
            .pipe(catchError(this.genServ.handleError));
    }

    callBlogCategories(): Observable<Array<BlogCategory>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/categories?per_page=100`
            )
            .pipe(
                tap((res: Array<any>) => {
                    if (res) {
                        res.map((cat) =>
                            this.blogMenuLinks.push({
                                name: cat.name,
                                url: `category/${cat.slug}`,
                            })
                        );
                    }
                }),
                catchError(this.genServ.handleError)
            );
    }

    getBlogPostFromSlug(slug: string): Observable<Array<Blog>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?slug=${slug}`
            )
            .pipe(catchError(this.genServ.handleError));
    }

    getBlogPostFromPreview(
        previewId: number,
        wpNonce: string
    ): Observable<Array<Blog>> {
        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts/${previewId}/revisions`,
                this.addAuthHeaders(wpNonce)
            )
            .pipe(catchError(this.genServ.handleError));
    }

    getBlogAuthorFromId(id: number): Observable<BlogAuthor> {
        return this.http
            .get(`${this.config.params.wordPress}/wp-json/wp/v2/users/${id}`)
            .pipe(catchError(this.genServ.handleError));
    }

    getBlogFromCategory(
        categoryId: number,
        page: number,
        perPage: number
    ): Observable<Array<Blog>> {
        this.loading = true;

        return this.http
            .get(
                `${this.config.params.wordPress}/wp-json/wp/v2/posts?per_page=${perPage}&page=${page}&categories=${categoryId}`,
                { observe: "response" }
            )
            .pipe(
                map((response: any) => {
                    this.blogPostsInCategoryTotal = Number(
                        response.headers.get("X-WP-Total")
                    );
                    this.loading = false;

                    return response.body;
                }),
                catchError(this.genServ.handleError)
            );
    }

    stripOutScriptTags(blogPost: Blog): Blog {
        blogPost.content.rendered = blogPost.content.rendered.replace(
            /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
            ""
        );

        return blogPost;
    }

    stripOutScriptTagsCollection(blogPosts: Array<Blog>): Array<Blog> {
        const items = [];

        blogPosts.forEach((item) => {
            items.push(this.stripOutScriptTags(item));
        });

        return items;
    }

    // tslint:disable-next-line:prefer-function-over-method
    private addAuthHeaders(wpNonce: string): { headers: HttpHeaders } {
        return { headers: new HttpHeaders({ "X-WP-Nonce": wpNonce }) };
    }
}
