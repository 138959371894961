export class Slot {
    id: number;
    attributes: {
        name: string | null;
        starts: string | null;
        ends: string | null;
        price: number;
        admin_fee: number;
        currency: string | null;
        availabilities: number | null;
    };

    constructor(
        id = 0,
        name = null,
        starts = null,
        ends = null,
        price: string | number = 0,
        adminFee: string | number = 0,
        currency = null,
        availabilities = null
    ) {
        this.id = id;
        this.attributes = {
            name: name,
            starts: starts,
            ends: ends,
            price: Number(price),
            admin_fee: Number(adminFee),
            currency: currency,
            availabilities: availabilities,
        };
    }
}
