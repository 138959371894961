import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { StatsSharedComponent } from "./stats.component";

@NgModule({
    declarations: [StatsSharedComponent],
    imports: [CommonModule],
    exports: [StatsSharedComponent],
})
export class StatsSharedModule {}
