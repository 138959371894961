/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "./shared/module/auth/auth.service";
import * as i5 from "./header/header.service";
import * as i6 from "./shared/city-select.service";
import * as i7 from "@angular/router";
import * as i8 from "./shared/module/featured/featured.component.ngfactory";
import * as i9 from "./shared/module/featured/featured.component";
import * as i10 from "./shared/eventTracking.service";
import * as i11 from "./shared/module/featured/featured.service";
import * as i12 from "./footer/footer.component.ngfactory";
import * as i13 from "./footer/footer.component";
import * as i14 from "./shared/sport-select.service";
import * as i15 from "./configure/configure.service";
import * as i16 from "./shared/module/auth/auth.component.ngfactory";
import * as i17 from "./shared/module/auth/auth.component";
import * as i18 from "./app.component";
import * as i19 from "@angular/platform-browser";
import * as i20 from "angulartics2/ga";
import * as i21 from "./shared/browser.service";
import * as i22 from "./shared/window.service";
import * as i23 from "./shared/link.service";
import * as i24 from "./shared/cookie.policy.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pf-header", [["class", "d-print-none"]], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.AuthService, i5.HeaderService, i6.CitySelectService, i7.Router], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "pf-featured", [], null, null, null, i8.View_FeaturedComponent_0, i8.RenderType_FeaturedComponent)), i1.ɵdid(4, 114688, null, 0, i9.FeaturedComponent, [i10.EventTrackingService, i7.Router, i11.FeatureService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "pf-footer", [["class", "d-print-none"]], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(10, 114688, null, 0, i13.FooterComponent, [i6.CitySelectService, i14.SportSelectService, i15.ConfigureService, i7.Router, i10.EventTrackingService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "mlp-auth", [], null, null, null, i16.View_AuthComponent_0, i16.RenderType_AuthComponent)), i1.ɵdid(13, 49152, null, 0, i17.AuthComponent, [i7.ActivatedRoute, i4.AuthService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); _ck(_v, 10, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i18.AppComponent, [i7.Router, i19.Meta, i19.Title, i20.Angulartics2GoogleAnalytics, i21.BrowserService, i22.WindowRefService, i15.ConfigureService, i23.LinkService, i24.CookiePolicyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
