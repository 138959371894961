<mlp-ng-header (signUp)="signUp()" (login)="logIn()" (logout)="logout()" [links]="links" [loggedIn]="!!authService.user" [homeUrl]="['/']" logoUrl="/ng-assets/styleguide/logo/pf_logo.svg" [trailing]="trailing">
    <section class="container" search>
        <p class="font-weight-medium mt-4 pr-4 pr-md-0">
            Choose a sport and search by location or venue
        </p>
        <ng-container>
            <pf-search-form layout="horizontal" (close)="close()" uniqueInputId="locationHeader"></pf-search-form>
            <mlp-ng-icon name="close" class="close" size="small" (click)="close()"></mlp-ng-icon>
        </ng-container>
    </section>

    <section *ngIf="!!authService.user" mobile>
        <strong class="text-uppercase sg-color__primary"><mlp-ng-icon
                name="single-neutral-circle"
                class="mr-1"
            ></mlp-ng-icon>
            My Account</strong
        >

        <hr class="mt-2 mb-1" />

        <ul class="list-unstyled mb-2">
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERLINKS.nav.mainLinks[0].url]"
                    >{{ USERLINKS.nav.mainLinks[0].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERLINKS.nav.mainLinks[1].url]"
                    >{{ USERLINKS.nav.mainLinks[1].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERLINKS.nav.mainLinks[2].url]"
                    >{{ USERLINKS.nav.mainLinks[2].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERVERTICALLINKS.data[3].url]"
                    >{{ USERVERTICALLINKS.data[3].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERVERTICALLINKS.data[0].url]"
                    >{{ USERVERTICALLINKS.data[0].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERVERTICALLINKS.data[4].url]"
                    >{{ USERVERTICALLINKS.data[4].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERVERTICALLINKS.data[5].url]"
                    >{{ USERVERTICALLINKS.data[5].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERVERTICALLINKS.data[1].url]"
                    >{{ USERVERTICALLINKS.data[1].name }}</a
                >
            </li>
            <li>
                <a
                    href=""
                    class="d-inline-block py-2"
                    (click)="close()"
                    [routerLink]="[USERVERTICALLINKS.data[2].url]"
                    >{{ USERVERTICALLINKS.data[2].name }}</a
                >
            </li>
            <li>
                <a href="" class="d-inline-block py-2" (click)="logout()"
                    >Logout</a
                >
            </li>
        </ul>
    </section>
</mlp-ng-header>