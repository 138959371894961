// tslint:disable: no-unbound-method file-name-casing

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import parse from 'date-fns/parse';


import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { HttpService } from "../../server/httpService";
import { ConfigureService } from "../configure/configure.service";
import { DateService } from "./date.service";
import { EventTrackingService } from "./eventTracking.service";
import { GenericService } from "./generic.service";
import { Cities } from "./model/cities";
import { OperatorData } from "./model/operator";
import { Pitch, PitchData } from "./model/pitch";
import { RiskAssessmentHistoryData } from "./model/risk-assessment-history";
import { Slot } from "./model/slot";
import { SlotCollection } from "./model/slotCollection";
import { VenueData } from "./model/venue";
import { ContactVenuePayload, NotifyMePayload } from "./model/venue-content.interface";

@Injectable()
export class ApiEndpoints {
    cities: Observable<Cities>;

    constructor(
        public http: HttpClient,
        private readonly httpService: HttpService,
        private readonly eventTrackingService: EventTrackingService,
        private readonly dateService: DateService,
        private readonly genericService: GenericService,
        private readonly config: ConfigureService
    ) {}

    findVenueBySlug(slug: string, city: string): Observable<any> {
        return this.httpService
            .get(
                `/venues?filter[url]=${slug}&filter[county]=${city}&include=pitches&page[size]=1`
            )
            .pipe(
                map((venueData: VenueData) => {
                    if (venueData.included) {
                        venueData.included = this.orderPitchesByAvail(
                            venueData.included
                        );
                    }

                    return venueData;
                })
            );
    }

    callMlpApiPitch(pitchId): Observable<any> {
        return this.httpService.get(`/pitches/${pitchId}?include=venues`);
    }

    callMlpApiOperator(operatorId): Observable<OperatorData> {
        return this.httpService.get(`/operators/${operatorId}`);
    }

    callMlpApiRiskAssessmentHistory(
        userEmail,
        facilityId
    ): Observable<RiskAssessmentHistoryData> {
        return this.httpService.get(
            `/riskassessment/${userEmail}/${facilityId}`
        );
    }

    callMlpAddApiRiskAssessmentHistory(
        response,
        userEmail,
        facilityId
    ): Observable<any> {
        return this.http.post(
            `${this.config.params.apiClientUri}/riskassessment`,
            {
                data: {
                    type: "RiskAssessmentHistory",
                    // tslint:disable-next-line:no-null-keyword
                    id: null, // must be null else id property is not sent
                    attributes: {
                        response,
                        userEmail,
                        facilityId,
                    },
                },
            }
        );
    }

    callMlpApiVenue(venueId): Observable<any> {
        return this.httpService.get(`/venues/${venueId}?include=pitches`).pipe(
            map((venueData: VenueData) => {
                venueData.included = this.orderPitchesByAvail(
                    venueData.included
                );

                return venueData;
            })
        );
    }

    callMlpApiGetPitchCollectionFromLocation(
        pitchData: PitchData,
        limit: number,
        is_partner?: number
    ): Observable<any> {
        const lat = pitchData.included[0].attributes.latitude;
        const lng = pitchData.included[0].attributes.longitude;
        const sport = pitchData.data.attributes.sport.toLowerCase();
        const format = pitchData.data.attributes.format;
        const partner = (is_partner) ? `&filter[is_partner]=${is_partner}` : '';
        return this.httpService.get(
            `/pitches?filter[location]=${lat},${lng}&filter[sport]=${sport}&filter[format]=${format}&include=venues&page[size]=${limit}${partner}`
        );
    }

    callMlpApiEnquiry(enquiryBody): Observable<any> {
        return this.http.post(
            `${this.config.params.apiClientUri}/messages`,
            enquiryBody,
            { observe: "response" }
        );
    }

    callSports(city: string): Observable<any> {
        return this.httpService.get(`/sports?filter[city]=${city}`);
    }

    notifyMe(payload: NotifyMePayload): Observable<any> {
        return this.http.post(
                `${this.config.params.apiClientUri}/notifications?include=conditions`,
                payload,
                { observe: "response" }
        );
    }

    contactedVenue(payload: ContactVenuePayload): Observable<any> {
        return this.http.post(
                `${this.config.params.apiClientUri}/contact-venues`,
                payload,
                { observe: "response" }
        );
    }

    getSlot(slotId: number, pitchId: number): Observable<any> {
        return this.httpService.get(`/pitches/${pitchId}/slots/${slotId}`).pipe(
            map((rawData: { data: Slot }) => {
                const rawSlot: { data: Slot } = rawData;
                return new Slot(
                    rawSlot.data.id,
                    rawSlot.data.attributes.name,
                    parse(rawSlot.data.attributes.starts, 'yyyy-MM-dd HH:mm:ss', new Date()),
                    parse(rawSlot.data.attributes.ends, 'yyyy-MM-dd HH:mm:ss', new Date()),
                    rawSlot.data.attributes.price,
                    rawSlot.data.attributes.admin_fee,
                    rawSlot.data.attributes.currency,
                    rawSlot.data.attributes.availabilities
                );
            })
        );
    }

    callPitchSlots(id: number, date: Date): Observable<any> {
        const filterStarts = `?filter[starts]=${format(
            date,
            "yyyy-MM-dd"
        )}`;
        const filterEnds = `&filter[ends]=${format(
            addDays(date, 6),
            "yyyy-MM-dd"
        )}`;

        return this.httpService
            .get(`/pitches/${id}/slots${filterStarts}${filterEnds}`)
            .pipe(
                map((res: any) => {
                    const rawJson = res;
                    const slots = [];

                    rawJson.data.forEach((rawSlot) => {
                        slots.push(
                            new Slot(
                                rawSlot.id,
                                rawSlot.attributes.name,
                                rawSlot.attributes.starts,
                                rawSlot.attributes.ends,
                                rawSlot.attributes.price,
                                rawSlot.attributes.admin_fee,
                                rawSlot.attributes.currency,
                                rawSlot.attributes.availabilities
                            )
                        );
                    });

                    // tslint:disable-next-line:new-parens
                    const calendarSlotCollection = new SlotCollection();
                    calendarSlotCollection.data = slots;
                    calendarSlotCollection.meta = rawJson.meta;

                    return calendarSlotCollection;
                })
            );
    }

    /**
     * __example__
     * getAnyData('/getme?filter[name]=test')
     *
     */
    getAnyData(endpoint: string): Observable<any> {
        return this.httpService
            .get(`${endpoint}`)
            .pipe(catchError(this.genericService.handleError));
    }

    /**
     * Lets add up each pitch availabilities and order them descending
     */
    protected orderPitchesByAvail(pitches: Array<Pitch>): Array<Pitch> {
        const pitchesWithAvail: Array<{ 0: number; 1: Pitch }> = [];
        const pitchesWithoutAvail: Array<Pitch> = [];

        pitches.forEach((pitch) => {
            const counted = pitch.attributes.available_slots_total;

            if (pitch.attributes.online_booking && counted > 0) {
                pitchesWithAvail.push([counted, pitch]);
            } else {
                pitchesWithoutAvail.push(pitch);
            }
        });

        pitchesWithAvail.sort(this.sortFunction).reverse();

        const finalOrder: Array<Pitch> = [];

        pitchesWithAvail.forEach((data: { 0: number; 1: Pitch }) =>
            finalOrder.push(data[1])
        );

        return [...finalOrder, ...pitchesWithoutAvail];
    }

    // sort an array by [0] integer and keep [1] with data
    protected sortFunction(a, b): any {
        if (a[0] === b[0]) {
            return 0;
            // tslint:disable-next-line: unnecessary-else
        } else {
            return a[0] < b[0] ? -1 : 1;
        }
    }
}
