export const discontinuedData = {
    cycling: {
        description: `If you'd like more information how to get into cycling, then we recommend you visit the 
<a href="https://www.britishcycling.org.uk" target="_blank" class="sg-color">British Cycling</a> website.`,
    },
    rowing: {
        description: `If you'd like more information about how to get into rowing, then we recommend you visit the 
<a href="https://www.britishrowing.org" target="_blank" class="sg-color">British Rowing</a> website.`,
    },
};
