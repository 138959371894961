import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { ConfigureService } from "./../app/configure/configure.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app/configure/configure.service";
var HttpService = /** @class */ (function () {
    function HttpService(platformId, http, config) {
        this.platformId = platformId;
        this.http = http;
        this.config = config;
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
    HttpService.prototype.get = function (url) {
        if (this.isBrowser) {
            return this.http.get(this.config.params.apiClientUri + url);
        }
        else {
            return this.http.get(this.config.params.apiServerUri + url);
        }
    };
    HttpService.ngInjectableDef = i0.defineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.HttpClient), i0.inject(i2.ConfigureService)); }, token: HttpService, providedIn: "root" });
    return HttpService;
}());
export { HttpService };
