import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { CookieService } from "ngx-cookie";
import { Observable, throwError as observableThrowError } from "rxjs";

import { ErrorCollection } from "./model/errorCollection";

@Injectable({
    providedIn: "root",
})
export class GenericService {
    constructor(private cookieService: CookieService) {}

    handleError(response: HttpErrorResponse | any): Observable<any> {
        if (response && response.error) {
            if (response.status >= 500) {
                const err = response.error;

                return observableThrowError(err.errors || [err]);
            } else if (response.status === 401) {
                return observableThrowError([
                    { title: "The credentials you entered are incorrect" },
                ]);
            } else {
                const err = response.error;
                if (err && err.errors) {
                    return observableThrowError(err.errors);
                }
            }
        }

        return observableThrowError(response);
    }

    handleErrorCollection(error: HttpErrorResponse | any): Observable<any> {
        const contentType = error.headers.get("Content-Type");

        if (error.status >= 500) {
            return observableThrowError(
                contentType === "application/json"
                    ? error
                    : new ErrorCollection()
            );
        } else if (error.status >= 400 && error.status <= 499) {
            return observableThrowError(
                contentType === "application/json"
                    ? error
                    : new ErrorCollection()
            );
        }

        return observableThrowError(new ErrorCollection());
    }

    processErrors(errorData: any): ErrorCollection {
        if (
            errorData.error &&
            errorData.error.errors &&
            errorData.error.errors.length > 0
        ) {
            return errorData.error as ErrorCollection;
        }

        return new ErrorCollection();
    }

    addAuthHeaders(): { headers: HttpHeaders } {
        if (this.cookieService.get("token")) {
            return {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.cookieService.get("token")}`,
                }),
            };
        }

        return { headers: new HttpHeaders() };
    }

    addAuthHeadersWithBody(body = ""): { headers: HttpHeaders; body: any } {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.cookieService.get("token")}`,
        });

        return { headers, body };
    }

    /**
     * In some cases form does not behave like it was submitted. We need to touch all form elements
     *
     * @param formGroup FormGroup
     */
    markFormControlsAsTouched(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }
}
