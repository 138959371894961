import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { customSelectRequiredValidator, ISelect, } from "pf_styleguide";
var CitySelectComponent = /** @class */ (function () {
    function CitySelectComponent(fb, router) {
        this.fb = fb;
        this.router = router;
        this.options = [];
        this.form = this.fb.group({
            city: [
                {
                    description: "Select City",
                    // tslint:disable-next-line:no-null-keyword
                    data: null,
                },
                customSelectRequiredValidator(),
            ],
        });
    }
    Object.defineProperty(CitySelectComponent.prototype, "city", {
        get: function () {
            return this.form.get("city");
        },
        enumerable: true,
        configurable: true
    });
    CitySelectComponent.prototype.submitted = function () {
        if (this.city.valid && this.city.value.data) {
            this.router.navigate([this.city.value.data]);
        }
    };
    return CitySelectComponent;
}());
export { CitySelectComponent };
