<section class="location-select" [ngClass]="{ '-higher': checkImage }">
    <section
        class="location-select__background"
        [style.background-image]="images"
        [ngClass]="{ '-higher': checkImage }"
    ></section>

    <section class="location-select__container">
        <div class="location-select__content">
            <div class="container">
                <p
                    class="sg-heading__h1 location-select__title text-uppercase mb-2"
                    [innerHTML]="title"
                ></p>

                <img
                    class="location-select__logo d-md-flex justify-content-center lazyload"
                    [src]="logo"
                    (error)="checkImage = false"
                    *ngIf="checkImage && logo"
                    alt="councils logo"
                    loading="lazy"
                />

                <h1
                    class="sg-heading__h1 location-select__description mb-3"
                    [innerHTML]="description"
                ></h1>

                <pf-search-form
                    *ngIf="!showCities"
                    uniqueInputId="locationPage"
                ></pf-search-form>

                <pf-city-select
                    [options]="cities"
                    *ngIf="showCities"
                ></pf-city-select>
            </div>
        </div>
    </section>
</section>

<pf-process [sport]="sport"></pf-process>
