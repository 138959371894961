import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { PartnerBannerStaticItem } from "./partner-banner.static";

@Component({
    selector: "mlp-item",
    templateUrl: "./item.component.html",
    styleUrls: ["./item.component.scss"],
})
export class ItemComponent {
    @Input() item: PartnerBannerStaticItem;

    constructor(private router: Router) {}

    goTo(): void {
        window.scrollTo(0, 0);
        this.router.navigate(["/partner"]);
    }
}
