import { Injectable } from "@angular/core";

@Injectable()
export class MlpHelper {
    static imageServiceDefaultParams(): string {
        return "auto=format,compress";
    }

    static oneYearFromNow(): { expires: Date } {
        const now = new Date();
        const expires = new Date(
            now.getFullYear() + 1,
            now.getMonth(),
            now.getDate(),
            0,
            0
        );

        return { expires };
    }

    static oneWeekFromNow(): { expires: Date } {
        const now = new Date();
        const expires = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 7,
            0,
            0
        );

        return { expires };
    }

    /**
     * Extract pitch id from url
     *
     * @param url
     * @returns {Number}
     */
    public static getPitchIdFromUrl(url) {
        let result = 0;

        if (url) {
            let found = Number(url.replace(/.+-([0-9]+)/g, "$1"));

            if (isNaN(found)) {
                found = Number(0);
            }

            result = found;
        }

        return result;
    }

    /**
     * Pad strings (000)
     *
     * @param number
     * @param width
     * @param padCharacter
     * @returns {any}
     */
    public static pad(number, width, padCharacter = "0") {
        let result;

        if (number || number == 0) {
            number = number + "";
            result =
                number.length >= width
                    ? number
                    : new Array(width - number.length + 1).join(padCharacter) +
                      number;
        }

        return result;
    }

    /**
     * Add three dots to the text
     *
     * @param string
     * @param limit number
     * @returns {any}
     */
    public static add3Dots(string: string, limit: number) {
        if (string && string.length > limit) {
            // limit characters first
            string = string.substring(0, limit + 1);

            // make sure we finish at last completed word and add dots
            string = string.substring(0, string.lastIndexOf(" ")) + "...";
        }

        return string;
    }

    /**
     * Make sure we check correctly url from client to weblink from api
     * @param url
     * @param webLink
     * @returns {boolean}
     */
    public static isUrlEqual(url: string, webLink: string) {
        if (url) {
            let removeUrlParams = url.split("?")[0];
            let removeUrlRandomCharacters = removeUrlParams
                .replace("%C2%A0", " ")
                .replace("%2C", ",");
            let decodedUrl = decodeURI(removeUrlRandomCharacters);

            if (decodedUrl.indexOf(webLink) != -1) {
                return true;
            }
        }

        return false;
    }

    /**
     * Uppercase only first letter
     *
     * @param string
     * @returns {string}
     */
    public static uppercaseFirstLetter(string) {
        if (string) {
            string =
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }

        return string;
    }

    static findAddressInput(data): string {
        if (!data) return "";
        if (typeof data === "string") return data;

        if (data.hasOwnProperty("input") && data.input) {
            return data.input;
        }

        return "";
    }

    /**
     * Sort alpha numeric values within objects
     * Usage: yourArray.sort((one, two) => MlpHelper.sortAlphaNum(one.key, two.key)
     * Source: https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
     *
     * @param a
     * @param b
     * @returns {number}
     */
    public static sortAlphaNum(a, b) {
        let reA = /[^a-zA-Z]/g;
        let reN = /[^0-9]/g;

        let AInt = parseInt(a, 10);
        let BInt = parseInt(b, 10);

        if (isNaN(AInt) && isNaN(BInt)) {
            let aA = a.replace(reA, "");
            let bA = b.replace(reA, "");
            if (aA === bA) {
                let aN = parseInt(a.replace(reN, ""), 10);
                let bN = parseInt(b.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        } else if (isNaN(AInt)) {
            return 1;
        } else if (isNaN(BInt)) {
            return -1;
        }
        return AInt > BInt ? 1 : -1;
    }

    /**
     * In order to fix javascript for calculations like this: 35.87 * 100 = 3586.9999999999995
     * wee need to convert it like below
     */
    static multiplyBy100(total: number): number {
        return Number((total * 100).toFixed());
    }
}
