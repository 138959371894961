import { ApiCollectionInterface } from "./api.interface";
import { Slot } from "./slot";

export class SlotCollection implements ApiCollectionInterface {
    data: Array<Slot>;
    meta: {
        total_items: number;
        filter: {
            starts: string;
            ends: string;
        };
    };
}
