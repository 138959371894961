import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { BlogService } from "../../blog.service";
import { Blog } from "../../model/blogs";

@Component({
    selector: "mlp-ng-shared-blog",
    templateUrl: "./blogs.component.html",
    styleUrls: ["./blogs.component.scss"],
})
export class BlogsSharedComponent implements OnInit {
    blogs: Array<Blog> = [];
    activeImage = 0;
    @Input() city = "";
    @Output() blogsContentChanged: EventEmitter<number> = new EventEmitter();
    private blogLength = 0;

    constructor(private blogService: BlogService) {}

    ngOnInit(): void {
        this.blogService.getBlogPosts(this.city).subscribe((res) => {
            this.blogs = res;
            this.blogLength = this.blogs.length - 1;
            this.blogsContentChanged.emit(this.blogLength);
        });
    }

    nextImage(): void {
        if (this.activeImage === this.blogLength) {
            this.activeImage = 0;

            return;
        }

        this.activeImage = this.activeImage + 1;
    }

    previousImage(): void {
        if (this.activeImage === 0) {
            this.activeImage = this.blogLength;

            return;
        }

        this.activeImage = this.activeImage - 1;
    }
}
