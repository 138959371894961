import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { CookieModule } from "ngx-cookie";
import { IconSharedModule } from "pf_styleguide";

import { CookiePolicyService } from "./../../cookie.policy.service";
import { CookiePolicyComponent } from "./cookie.policy.component";

@NgModule({
    declarations: [CookiePolicyComponent],
    imports: [
        CommonModule,
        IconSharedModule,
        RouterModule,
        CookieModule.forRoot(),
    ],
    exports: [CookiePolicyComponent],
    providers: [CookiePolicyService],
})
export class CookiePolicyModule {}
