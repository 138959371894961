var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SocialUser } from 'angular-6-social-login';
/**
 * Taken mostly from https://dev.to/rrdlpl/sign-in-with-appleid-angular-nestjs-5ejp but with modifications for Angular6
 */
var AppleLoginProvider = /** @class */ (function () {
    function AppleLoginProvider(clientId, _initOptions, apiClientUri) {
        if (_initOptions === void 0) { _initOptions = { scope: 'email name' }; }
        this.clientId = clientId;
        this._initOptions = _initOptions;
        this.apiClientUri = apiClientUri;
    }
    AppleLoginProvider.prototype.initialize = function () {
        var _this = this;
        return new Promise(function (resolve, _reject) {
            _this.loadScript(AppleLoginProvider.PROVIDER_ID, 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', function () {
                AppleID.auth.init({
                    clientId: _this.clientId,
                    scope: 'name email',
                    redirectURI: _this.apiClientUri + "/login_apple",
                    state: '357bddab-fe64-422d-944c-ace8084deacd',
                    usePopup: true,
                });
                resolve();
            });
        });
    };
    AppleLoginProvider.prototype.signIn = function (signInOptions) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        try {
                            var promise = AppleID.auth.signIn();
                            promise.then(function (data) {
                                var user = new SocialUser();
                                if (!!data.user) {
                                    user.name = data.user.firstName + " " + data.user.lastName;
                                    user.email = data.user.email;
                                }
                                user.token = data.authorization.id_token;
                                user.idToken = data.authorization.id_token;
                                resolve(user);
                            });
                        }
                        catch (er) {
                            reject('Please complete the Apple signin process to sign in via Apple.');
                        }
                    })];
            });
        });
    };
    AppleLoginProvider.prototype.signOut = function (revoke) {
        return new Promise(function (resolve, reject) {
            // AppleID doesnt have revoke method
            resolve();
        });
    };
    AppleLoginProvider.prototype.loadScript = function (id, src, onload, parentElement) {
        if (parentElement === void 0) { parentElement = null; }
        // get document if platform is only browser
        if (typeof document !== 'undefined' && !document.getElementById(id)) {
            var signInJS = document.createElement('script');
            signInJS.async = true;
            signInJS.src = src;
            signInJS.onload = onload;
            if (!parentElement) {
                parentElement = document.head;
            }
            parentElement.appendChild(signInJS);
        }
    };
    AppleLoginProvider.PROVIDER_ID = 'APPLE';
    return AppleLoginProvider;
}());
export { AppleLoginProvider };
