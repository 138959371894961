<mlp-ng-social-banner
    [icons]="icons"
    [action]="action"
    (clickEvent)="trackEvent($event)"
></mlp-ng-social-banner>

<mlp-ng-mega-footer
    *ngIf="
        ((sportSelectService.sports | async).length === 0 &&
            cities.data.length > 1) ||
        (eventUrl === '/' && cities.data.length > 1)
    "
    [data]="data"
    [cities]="cities"
></mlp-ng-mega-footer>

<mlp-ng-mega-footer
    *ngIf="(sportSelectService.sports | async).length > 0 && eventUrl !== '/'"
    [data]="data"
    [sports]="{ data: sportSelectService.sportsCollected }"
    [city]="'/' + citySelectService.city?.slug"
    [cityUppercase]="
        citySelectService?.city && citySelectService?.city?.slug === 'uk'
            ? 'the UK'
            : citySelectService?.city?.name
    "
></mlp-ng-mega-footer>
