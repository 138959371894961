import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import { mlpEmailValidator } from "pf_styleguide";
import { tap } from "rxjs/operators";

import { ConfigureService } from "./../../../../configure/configure.service";
import { EventTrackingService } from "./../../../eventTracking.service";
import { GenericService } from "./../../../generic.service";
import { ErrorCollection } from "./../../../model/errorCollection";
import { AuthService } from "./../auth.service";

@Component({
    selector: "pf-sign-in",
    templateUrl: "sign-in.component.html",
})
export class SignInComponent implements OnInit {
    @Output() readonly loginSuccess = new EventEmitter();
    @Output() readonly closeModal = new EventEmitter();
    @Output() readonly showSignUp = new EventEmitter();
    @Output() readonly back = new EventEmitter();
    @Output() readonly guest = new EventEmitter();

    @Input() checkout = false;

    get username(): AbstractControl {
        return this.formGroup.get("username");
    }
    get password(): AbstractControl {
        return this.formGroup.get("password");
    }

    errorCollection: ErrorCollection = new ErrorCollection();
    formGroup: FormGroup;
    success = false;

    constructor(
        private authService: AuthService,
        private genericService: GenericService,
        private fb: FormBuilder,
        public config: ConfigureService,
        private eventTrackingService: EventTrackingService,
        private router: Router
    ) {
        this.buildForm();
    }

    ngOnInit(): void {
        this.resetForm();
    }

    closeForm(): void {
        this.closeModal.emit();
    }

    onSubmit(): void {
        this.genericService.markFormControlsAsTouched(this.formGroup);

        if (this.formGroup.valid) {
            this.resetForm();
            this.authService
                .login(this.username.value, this.password.value)
                .pipe(
                    tap((_) => {
                        this.eventTrackingService.trackEvent(
                            "Account Log In",
                            "user",
                            this.router.url
                        );
                    })
                )
                .subscribe(
                    () => {
                        this.success = true;
                        this.loginSuccess.emit(this.success);
                    },
                    (errorData) => {
                        this.errorCollection.errors = errorData;
                        this.loginSuccess.emit(this.success);
                    }
                );
        }
    }

    loginSuccessProcess($event): void {
        this.loginSuccess.emit($event);
    }

    private resetForm(): void {
        this.success = false;
        this.errorCollection.reset();
    }

    private buildForm(): void {
        this.formGroup = this.fb.group({
            username: ["", [mlpEmailValidator()]],
            password: ["", [Validators.required]],
        });

        this.formGroup.controls.username.valueChanges.subscribe(
            (v) => (this.errorCollection.errors = undefined)
        );
        this.formGroup.controls.password.valueChanges.subscribe(
            (v) => (this.errorCollection.errors = undefined)
        );
    }
}
