var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { AuthServiceConfig, FacebookLoginProvider, } from "angular-6-social-login";
import { AppleLoginProvider } from "./apple-login/apple.provider";
import { GoogleNewLoginProvider } from "./google-login/google.provider";
// tslint:disable-next-line:no-require-imports
var configs = require("./../../../../config.json");
var SocialAuthHelper = /** @class */ (function (_super) {
    __extends(SocialAuthHelper, _super);
    function SocialAuthHelper(http) {
        var _this = _super.call(this, []) || this;
        _this.http = http;
        var providers = [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider(configs.facebookAppId),
            },
            {
                id: GoogleNewLoginProvider.PROVIDER_ID,
                provider: new GoogleNewLoginProvider(configs.googleAuthId, http, configs.apiClientUri),
            },
            {
                id: AppleLoginProvider.PROVIDER_ID,
                provider: new AppleLoginProvider(configs.appleAppId, { scope: 'email name' }, configs.canonicalDomain),
            }
        ];
        _this.providers = new Map();
        for (var i = 0; i < providers.length; i++) {
            var element = providers[i];
            _this.providers.set(element.id, element.provider);
        }
        return _this;
    }
    return SocialAuthHelper;
}(AuthServiceConfig));
export { SocialAuthHelper };
