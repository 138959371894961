import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "mlp-ng-shared-prev-next",
    templateUrl: "./prev.next.btn.component.html",
})
export class PrevNextSharedComponent implements OnInit {
    activeImage = 0;
    @Input() max = 0;
    @Output() visible = new EventEmitter();

    ngOnInit(): void {
        this.max = this.max - 1;
        this.emitData();
    }

    nextImage(): void {
        if (this.activeImage === this.max) {
            this.activeImage = 0;
            this.emitData();

            return;
        }

        this.activeImage = this.activeImage + 1;
        this.emitData();
    }

    previousImage(): void {
        if (this.activeImage === 0) {
            this.activeImage = this.max;
            this.emitData();

            return;
        }

        this.activeImage = this.activeImage - 1;
        this.emitData();
    }

    emitData(): void {
        this.visible.emit(this.activeImage);
    }
}
