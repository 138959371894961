import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from "@angular/core";

import { AuthServiceConfig, SocialLoginModule } from "angular-6-social-login";

import { AlertSharedModule, ButtonSocialSharedModule } from "pf_styleguide";
import { AuthModule } from '../auth.module';

import { SocialLoginComponent } from "./../social-login/social-login.component";
import { SocialAuthHelper } from "./../social.auth.helper";

@NgModule({
    declarations: [SocialLoginComponent],
    imports: [
        CommonModule,
        SocialLoginModule,
        AlertSharedModule,
        ButtonSocialSharedModule,
        HttpClientModule,
    ],
    exports: [SocialLoginComponent],
    providers: [
        SocialAuthHelper,
        {
            provide: AuthServiceConfig,
            useClass: SocialAuthHelper
        },
    ],
})
export class PfSocialLoginModule {}
