<h2
    class="sports-icons__title text-uppercase text-center"
    (click)="isOpen = !isOpen"
>
    {{ title }}
    <mlp-ng-icon
        class="d-md-none pull-right"
        [name]="isOpen ? 'subtract-circle' : 'add-circle'"
    ></mlp-ng-icon>
</h2>

<div class="sports-icons__container" [ngClass]="{ 'd-block': isOpen }">
    <ng-container *ngIf="!latLong">
        <a
            class="sports-icons__link"
            *ngFor="let sport of sports"
            [routerLink]="['/', city, sport.id]"
        >
            <mlp-ng-icon
                [name]="sport.id"
                class="sports-icons__icon"
            ></mlp-ng-icon>
            {{ sport.attributes.name }}
        </a>
    </ng-container>

    <ng-container *ngIf="latLong">
        <a
            class="sports-icons__link"
            *ngFor="let sport of sports"
            [routerLink]="['/', city, 'results', sport.id]"
            [queryParams]="{ latitude: latLong?.lat, longitude: latLong?.long }"
        >
            <mlp-ng-icon
                [name]="sport.id"
                class="sports-icons__icon"
            ></mlp-ng-icon>
            {{ sport.attributes.name }}
        </a>
    </ng-container>
</div>
