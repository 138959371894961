import { HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie";
import { throwError as observableThrowError } from "rxjs";
import { ErrorCollection } from "./model/errorCollection";
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
var GenericService = /** @class */ (function () {
    function GenericService(cookieService) {
        this.cookieService = cookieService;
    }
    GenericService.prototype.handleError = function (response) {
        if (response && response.error) {
            if (response.status >= 500) {
                var err = response.error;
                return observableThrowError(err.errors || [err]);
            }
            else if (response.status === 401) {
                return observableThrowError([
                    { title: "The credentials you entered are incorrect" },
                ]);
            }
            else {
                var err = response.error;
                if (err && err.errors) {
                    return observableThrowError(err.errors);
                }
            }
        }
        return observableThrowError(response);
    };
    GenericService.prototype.handleErrorCollection = function (error) {
        var contentType = error.headers.get("Content-Type");
        if (error.status >= 500) {
            return observableThrowError(contentType === "application/json"
                ? error
                : new ErrorCollection());
        }
        else if (error.status >= 400 && error.status <= 499) {
            return observableThrowError(contentType === "application/json"
                ? error
                : new ErrorCollection());
        }
        return observableThrowError(new ErrorCollection());
    };
    GenericService.prototype.processErrors = function (errorData) {
        if (errorData.error &&
            errorData.error.errors &&
            errorData.error.errors.length > 0) {
            return errorData.error;
        }
        return new ErrorCollection();
    };
    GenericService.prototype.addAuthHeaders = function () {
        if (this.cookieService.get("token")) {
            return {
                headers: new HttpHeaders({
                    Authorization: "Bearer " + this.cookieService.get("token"),
                }),
            };
        }
        return { headers: new HttpHeaders() };
    };
    GenericService.prototype.addAuthHeadersWithBody = function (body) {
        if (body === void 0) { body = ""; }
        var headers = new HttpHeaders({
            Authorization: "Bearer " + this.cookieService.get("token"),
        });
        return { headers: headers, body: body };
    };
    /**
     * In some cases form does not behave like it was submitted. We need to touch all form elements
     *
     * @param formGroup FormGroup
     */
    GenericService.prototype.markFormControlsAsTouched = function (formGroup) {
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    };
    GenericService.ngInjectableDef = i0.defineInjectable({ factory: function GenericService_Factory() { return new GenericService(i0.inject(i1.CookieService)); }, token: GenericService, providedIn: "root" });
    return GenericService;
}());
export { GenericService };
