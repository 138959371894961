import { isPlatformBrowser } from "@angular/common";
import * as i0 from "@angular/core";
var BrowserService = /** @class */ (function () {
    function BrowserService(platformId) {
        this.platformId = platformId;
        this.isBrowser = isPlatformBrowser(this.platformId);
    }
    BrowserService.ngInjectableDef = i0.defineInjectable({ factory: function BrowserService_Factory() { return new BrowserService(i0.inject(i0.PLATFORM_ID)); }, token: BrowserService, providedIn: "root" });
    return BrowserService;
}());
export { BrowserService };
