<ng-template #signUpContent let-closeModal="close" let-d="dismiss">
    <mlp-ng-modal>
        <section header class="sg-heading__h5">Welcome to Playfinder</section>

        <section body>
            <pf-sign-up
                (closeModal)="closeModal()"
                (showSignIn)="closeModal(); switchModal(); (false)"
                (loginSuccess)="loginSuccess($event)"
                *ngIf="!success"
            ></pf-sign-up>

            <!--Notification once the SignUp is successful.-->
            <div *ngIf="success">
                <p class="mb-3 text-center">
                    Thanks for signing up. You are now logged in.
                </p>
                <div class="mb-2">
                    <mlp-ng-button
                        label="Book Sports Facilities!"
                        fullWidth="1"
                        btnType="submit"
                        (click)="closeModal()"
                    ></mlp-ng-button>
                </div>
            </div>
        </section>
    </mlp-ng-modal>
</ng-template>
