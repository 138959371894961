/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i3 from "pf_styleguide";
import * as i4 from "./social-login.component";
import * as i5 from "../auth.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../generic.service";
import * as i8 from "../../../eventTracking.service";
var styles_SocialLoginComponent = [];
var RenderType_SocialLoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SocialLoginComponent, data: {} });
export { RenderType_SocialLoginComponent as RenderType_SocialLoginComponent };
export function View_SocialLoginComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.TitleCasePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 2, "sg-ng-button-social", [["class", "mr-3 mb-3 d-block"], ["path", "/ng-assets/styleguide/svg/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.socialSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonSocialSharedComponent_0, i2.RenderType_ButtonSocialSharedComponent)), i0.ɵdid(2, 49152, null, 0, i3.ButtonSocialSharedComponent, [], { label: [0, "label"], social: [1, "social"], path: [2, "path"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "mlp-ng-alerts", [], null, null, null, i2.View_AlertsSharedComponent_0, i2.RenderType_AlertsSharedComponent)), i0.ɵdid(6, 49152, null, 0, i3.AlertsSharedComponent, [i3.AlertService], null, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "Continue with ", i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.socialPlatform)), ""); var currVal_1 = _co.socialPlatform; var currVal_2 = "/ng-assets/styleguide/svg/"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SocialLoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-social-login", [], null, null, null, View_SocialLoginComponent_0, RenderType_SocialLoginComponent)), i0.ɵdid(1, 49152, null, 0, i4.SocialLoginComponent, [i5.AuthService, i6.Router, i7.GenericService, i3.AlertService, i8.EventTrackingService], null, null)], null, null); }
var SocialLoginComponentNgFactory = i0.ɵccf("mlp-social-login", i4.SocialLoginComponent, View_SocialLoginComponent_Host_0, { socialPlatform: "socialPlatform", accessType: "accessType", trackevent: "trackevent" }, { loginSuccess: "loginSuccess" }, []);
export { SocialLoginComponentNgFactory as SocialLoginComponentNgFactory };
