<form
    [formGroup]="form"
    (submit)="submit()"
    autocomplete="off"
    #ngForm="ngForm"
>
    <div [ngClass]="{ 'row mb-4': layout === 'horizontal' }">
        <div class="mb-2" [class.col-lg-3]="layout === 'horizontal'">
            <mlp-ng-select
                formControlName="sport"
                [options]="sports"
                shadow="1"
                noBorder="1"
                errors="false"
                [errors]="
                    (ngForm.submitted || location.touched || sport.touched) &&
                    sport.invalid
                        ? [{ title: 'Please select sport' }]
                        : []
                "
            ></mlp-ng-select>
        </div>

        <div class="mb-2" [class.col-lg-6]="layout === 'horizontal'">
            <pf-auto-suggest
                [uniqueInputId]="uniqueInputId"
                formControlName="location"
                [sport]="getSportFromSelect(sport.value)"
                [layout]="layout"
                [forceReload]="forceReload"
                (venueSelected)="venueSelected($event)"
                (pitchSelected)="pitchSelected($event)"
                (locationSelected)="locationSelected($event)"
                (dateTimeSelected)="dateTimeSelected($event)"
                (closeDropdown)="close.emit()"
                (submit)="submit()"
            ></pf-auto-suggest>
        </div>

        <div [class.col-lg-3]="layout === 'horizontal'">
            <mlp-ng-button-atom
                label="Find "
                labelSecondary="Venue"
                fullWidth="1"
                btnShadow="1"
                btnType="submit"
            ></mlp-ng-button-atom>
        </div>
    </div>
</form>
