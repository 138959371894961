import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, switchMapTo } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { SignInModalComponent } from "./sign-in-modal/sign-in-modal.component";
import { SignUpModalComponent } from "./sign-up-modal/sign-up-modal.component";

@Component({
    selector: "mlp-auth",
    templateUrl: "auth.component.html",
})
export class AuthComponent {
    @ViewChild(SignUpModalComponent) signUpModalComponent: SignUpModalComponent;
    @ViewChild(SignInModalComponent) signInModalComponent: SignInModalComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
        this.authService.openSignInEvent.subscribe(() => {
            this.signInModalComponent.open(
                this.signInModalComponent.signInContent
            );
        });

        this.authService.openSignUpEvent.subscribe(() => {
            this.signUpModalComponent.open(
                this.signUpModalComponent.signUpContent
            );
        });

        this.activatedRoute.queryParams
            .pipe(
                filter((data) => !!data.popup),
                switchMapTo(
                    this.authService.sessionChecked.pipe(
                        filter((data) => !!data)
                    )
                )
            )
            .subscribe(() => {
                if (!this.authService.user) {
                    this.authService.openSignInEvent.emit();
                }
            });
    }
}
