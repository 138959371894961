import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie";
import { TransformApiData } from "pf_styleguide/shared";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ConfigureService } from "../../../configure/configure.service";
import { BrowserService } from "../../browser.service";
import { GenericService } from "../../generic.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../configure/configure.service";
import * as i3 from "../../generic.service";
import * as i4 from "ngx-cookie";
import * as i5 from "../../browser.service";
import * as i6 from "pf_styleguide/shared";
var AutoSuggestService = /** @class */ (function () {
    function AutoSuggestService(http, config, genericService, cookieService, browserService, transformApiData) {
        this.http = http;
        this.config = config;
        this.genericService = genericService;
        this.cookieService = cookieService;
        this.browserService = browserService;
        this.transformApiData = transformApiData;
        this.location = "";
        this.validTypes = ["postal_code", "postal_code_prefix", "locality", "geocode"];
    }
    AutoSuggestService.prototype.callAutoComplete = function (input, countries) {
        var _this = this;
        var locationParams = "";
        var country = countries
            .map(function (country) { return "country:" + country; })
            .join("|");
        return this.http
            .get(
        // tslint:disable-next-line:max-line-length
        this.config.params.googleMapsDomainAutoComplete + "/maps/api/place/autocomplete/json?input=" + input + locationParams + "&components=" + country + "&key=" + this.config.params.googleMapsAPIkey)
            .pipe(map(function (data) { return data.predictions; }), map(function (data) { return _this.processAutoCompleteResponse(data); }));
    };
    AutoSuggestService.prototype.callGoogleGeoCoder = function (placeId) {
        return this.http
            .get(
        // tslint:disable-next-line:max-line-length
        this.config.params.googleMapsDomain + "/maps/api/geocode/json?place_id=" + placeId + "&key=" + this.config.params.googleMapsAPIkey)
            .pipe(map(function (data) { return data.results; }));
    };
    AutoSuggestService.prototype.callGoogleGeoCoderLatLng = function (lat, lng) {
        return this.http
            .get(
        // tslint:disable-next-line:max-line-length
        this.config.params.googleMapsDomain + "/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + this.config.params.googleMapsAPIkey)
            .pipe(map(function (data) { return data.results; }));
    };
    AutoSuggestService.prototype.callVenueByNameSport = function (venueName, sport) {
        var _this = this;
        return this.http
            .get(
        // tslint:disable-next-line:max-line-length
        this.config.params.apiClientUri + "/venues?filter[name]=" + venueName + "&filter[sport]=" + sport + "&page[size]=2&include=pitches")
            .pipe(catchError(function (_) { return of([]); }), map(function (data) {
            return _this.transformApiData.mapArrayInCollection(data, [
                { pitches: "pitches" },
            ]);
        }), map(function (data) { return data.data; }));
    };
    AutoSuggestService.prototype.callPitchesByLocationSport = function (location, sport) {
        return this.http
            .get(
        // tslint:disable-next-line:max-line-length no-unbound-method
        this.config.params.apiClientUri + "/pitches?filter[location]=" + location.lat + "," + location.lng + "&filter[sport]=" + sport + "&filter[is_online]=true&filter[radius]=5mi&sort=relevance&include=venues&page[number]=1&page[size]=1")
            .pipe(
        // tslint:disable-next-line: no-object-literal-type-assertion
        catchError(function (_) { return of({}); }));
    };
    // tslint:disable-next-line:max-line-length
    AutoSuggestService.prototype.processAutoCompleteResponse = function (data) {
        var _this = this;
        var collected = [];
        data.forEach(function (prediction) {
            var validType = false;
            prediction.types.forEach(function (type) {
                _this.validTypes.forEach(function (validTypeValue) {
                    if (type === validTypeValue) {
                        validType = true;
                    }
                });
            });
            if (validType) {
                collected.push(prediction);
            }
        });
        return collected;
    };
    AutoSuggestService.ngInjectableDef = i0.defineInjectable({ factory: function AutoSuggestService_Factory() { return new AutoSuggestService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigureService), i0.inject(i3.GenericService), i0.inject(i4.CookieService), i0.inject(i5.BrowserService), i0.inject(i6.TransformApiData)); }, token: AutoSuggestService, providedIn: "root" });
    return AutoSuggestService;
}());
export { AutoSuggestService };
