import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { SocialUser } from 'angular-6-social-login';
import { LoginProvider } from "angular-6-social-login/entities/login-provider";

declare let AppleID: any;

/**
 * Taken mostly from https://dev.to/rrdlpl/sign-in-with-appleid-angular-nestjs-5ejp but with modifications for Angular6
 */
export class AppleLoginProvider implements LoginProvider {
    public static readonly PROVIDER_ID: string = 'APPLE';

    constructor(
        private clientId: string,
        private _initOptions: any = { scope: 'email name' },
        private readonly apiClientUri: string
    ) {
    }

    public initialize(): Promise<SocialUser> {
        return new Promise((resolve, _reject) => {
            this.loadScript(
                AppleLoginProvider.PROVIDER_ID,
                'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
                () => {
                    AppleID.auth.init({
                        clientId: this.clientId,
                        scope: 'name email',
                        redirectURI: `${this.apiClientUri}/login_apple`,
                        state: '357bddab-fe64-422d-944c-ace8084deacd', //used to prevent CSFR
                        usePopup: true,
                    });
                    resolve();
                }
            );
        });
    }

    public async signIn(signInOptions?: any): Promise<SocialUser> {
            return new Promise<SocialUser>((resolve, reject) => {
                try {
                    let promise = AppleID.auth.signIn();
                    promise.then((data) => {
                        let user = new SocialUser();
                        if (!!data.user) {
                            user.name = `${data.user.firstName} ${data.user.lastName}`;
                            user.email = data.user.email;
                        }
                        user.token = data.authorization.id_token;
                        user.idToken = data.authorization.id_token;
                        resolve(user);
                    });
                } catch (er) {
                    reject('Please complete the Apple signin process to sign in via Apple.');
                }
            });
    }

    public signOut(revoke?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            // AppleID doesnt have revoke method
            resolve();
        });
    }

    protected loadScript(
        id: string,
        src: string,
        onload: any,
        parentElement = null
    ): void {
        // get document if platform is only browser
        if (typeof document !== 'undefined' && !document.getElementById(id)) {
            let signInJS = document.createElement('script');

            signInJS.async = true;
            signInJS.src = src;
            signInJS.onload = onload;

            if (!parentElement) {
                parentElement = document.head;
            }

            parentElement.appendChild(signInJS);
        }
    }
}
