import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

import { EventTrackingService } from "./../../../shared/eventTracking.service";
import { FeatureService } from "./featured.service";

@Component({
    selector: "pf-featured",
    templateUrl: "./featured.component.html",
    styleUrls: ["./featured.component.scss"],
    // tslint:disable-next-line: use-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class FeaturedComponent implements OnInit {
    hasMadeSmall = false;
    banner: string;

    constructor(
        private readonly eventTrackingService: EventTrackingService,
        private readonly router: Router,
        private readonly featureService: FeatureService
    ) {}

    ngOnInit(): void {
        this.featureService.getBannerContent().subscribe((banner) => {
            this.banner = banner;
        });
    }

    clickEvent(action): void {
        this.eventTrackingService.trackEvent(
            "General - Campaign Banner Interaction",
            action,
            this.router.url
        );
    }
}
