import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import {
    V_EMAIL,
    V_FULL_NAME,
    V_PASSWORD,
    V_PASSWORD_REPEAT,
} from "pf_styleguide/shared";

import { IErrors } from "pf_styleguide";

import { EventTrackingService } from "../../../eventTracking.service";
import { GenericService } from "../../../generic.service";
import { CreateUserApi } from "../../../model/createUserApi";
import { ErrorCollection } from "../../../model/errorCollection";
import { AuthService } from "../auth.service";
import { ConfigureService } from "./../../../../configure/configure.service";

@Component({
    selector: "pf-sign-up",
    templateUrl: "sign-up.component.html",
})
export class SignUpComponent implements OnInit {
    @Output() readonly showSignIn = new EventEmitter();
    @Output() readonly loginSuccess = new EventEmitter();
    @Output() readonly loginError = new EventEmitter();
    @Output() readonly back = new EventEmitter();
    @Output() readonly guest = new EventEmitter();

    @Input() checkout = false;

    success = false;
    formGroup: FormGroup;
    errorCollection: ErrorCollection = new ErrorCollection();
    emailErrors: IErrors;

    V_EMAIL = V_EMAIL;
    V_FULL_NAME = V_FULL_NAME;
    V_PASSWORD = V_PASSWORD;
    V_PASSWORD_REPEAT = V_PASSWORD_REPEAT;

    get fullName(): AbstractControl {
        return this.formGroup.get("fullName");
    }
    get email(): AbstractControl {
        return this.formGroup.get("email");
    }
    get password(): AbstractControl {
        return this.formGroup.get("password");
    }
    get isAgreedOnTerms(): AbstractControl {
        return this.formGroup.get("isAgreedOnTerms");
    }

    constructor(
        private readonly authService: AuthService,
        private readonly eventTracking: EventTrackingService,
        private readonly fb: FormBuilder,
        private readonly router: Router,
        private readonly genericService: GenericService,
        public config: ConfigureService
    ) {
        this.resetForm();
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm(): void {
        this.formGroup = this.fb.group({
            fullName: ["", V_FULL_NAME.requirements],
            email: ["", V_EMAIL.requirements],
            password: ["", V_PASSWORD.requirements],
            isAgreedOnTerms: [null, [Validators.required]],
        });

        this.formGroup.controls.email.valueChanges.subscribe(
            (v) => (this.emailErrors = undefined)
        );
    }

    onSubmit(): void {
        this.genericService.markFormControlsAsTouched(this.formGroup);

        if (this.formGroup.valid && this.isAgreedOnTerms.value) {
            this.resetForm();
            const user = new CreateUserApi(
                this.fullName.value,
                this.email.value,
                this.password.value
            );
            this.authService.signUp(user).subscribe(
                // tslint:disable-next-line: no-void-expression
                () => this.processSuccess(),
                (errorData) => {
                    this.errorCollection.errors = errorData.filter(
                        (e) => e.source !== "users.email"
                    );
                    this.emailErrors = errorData.filter(
                        (e) => e.source === "users.email"
                    );

                    this.loginError.emit();
                }
            );
        }
    }

    resetForm(): void {
        this.success = false;
        this.errorCollection.reset();
        this.emailErrors = undefined;
    }

    loginSuccessProcess($event): void {
        if ($event) this.processSuccess();
        if (!$event) this.unprocessed();
    }

    private unprocessed(): void {
        this.success = false;
        this.loginSuccess.emit(false);
    }

    private processSuccess(): void {
        this.success = true;
        this.loginSuccess.emit(true);
        this.eventTracking.trackEvent(
            "Account Sign Up",
            "User",
            this.router.url
        );
    }
}
