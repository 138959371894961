import { DefaultUrlSerializer, UrlTree } from "@angular/router";

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        return super.parse(this.fixUrlRedirection(url));
    }

    fixUrlRedirection(url: string): string {
        return url.startsWith("/results/") ? `/london${url}` : url;
    }
}
