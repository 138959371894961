/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./featured.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./featured.component";
import * as i4 from "../../eventTracking.service";
import * as i5 from "@angular/router";
import * as i6 from "./featured.service";
var styles_FeaturedComponent = [i0.styles];
var RenderType_FeaturedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FeaturedComponent, data: {} });
export { RenderType_FeaturedComponent as RenderType_FeaturedComponent };
function View_FeaturedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "section", [["class", "featured"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickEvent("CTA Click - Button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.banner; _ck(_v, 0, 0, currVal_0); }); }
export function View_FeaturedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeaturedComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.banner; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FeaturedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pf-featured", [], null, null, null, View_FeaturedComponent_0, RenderType_FeaturedComponent)), i1.ɵdid(1, 114688, null, 0, i3.FeaturedComponent, [i4.EventTrackingService, i5.Router, i6.FeatureService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeaturedComponentNgFactory = i1.ɵccf("pf-featured", i3.FeaturedComponent, View_FeaturedComponent_Host_0, {}, {}, []);
export { FeaturedComponentNgFactory as FeaturedComponentNgFactory };
