import { Component, Input } from "@angular/core";

import { BookingProcessInterface } from "./booking.process.interface";

@Component({
    selector: "mlp-ng-shared-booking-process",
    templateUrl: "./booking.process.component.html",
})
export class BookingProcessSharedComponent {
    @Input() steps: BookingProcessInterface;
}
