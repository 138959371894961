// tslint:disable: no-null-keyword
import { EventEmitter, forwardRef, OnInit, } from "@angular/core";
import { FormBuilder, NG_VALUE_ACCESSOR, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, mapTo, mergeMap, switchMap, tap, } from "rxjs/operators";
import { DateService } from "../../date.service";
import { EventTrackingService } from "../../eventTracking.service";
import { Sport } from "../../model/sport";
import { AutoSuggestService } from "./auto-suggest.service";
export var INPUT_CUSTOM_INPUT_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-forward-ref no-use-before-declare
    useExisting: forwardRef(function () { return AutoSuggestComponent; }),
    multi: true,
};
var AutoSuggestComponent = /** @class */ (function () {
    function AutoSuggestComponent(formBuilder, autoSuggestService, eventTrackingService, router, dateService) {
        this.formBuilder = formBuilder;
        this.autoSuggestService = autoSuggestService;
        this.eventTrackingService = eventTrackingService;
        this.router = router;
        this.dateService = dateService;
        this.layout = "vertical";
        // tslint:disable-next-line:prefer-output-readonly
        this.countries = ["UK", "IE"];
        this.forceReload = new EventEmitter();
        // tslint:disable-next-line:prefer-output-readonly
        this.venueSelected = new EventEmitter();
        // tslint:disable-next-line:prefer-output-readonly
        this.pitchSelected = new EventEmitter();
        // tslint:disable-next-line:prefer-output-readonly
        this.locationSelected = new EventEmitter();
        // tslint:disable-next-line:prefer-output-readonly
        this.dateTimeSelected = new EventEmitter();
        // tslint:disable-next-line:prefer-output-readonly
        this.closeDropdown = new EventEmitter();
        // tslint:disable-next-line:prefer-output-readonly no-output-named-after-standard-event
        this.submit = new EventEmitter();
        this.googleResults = [];
        this.venueResults = [];
        this.selection = -1;
        this.pitches = [];
        this.closed = true;
        this.gettingLocation = false;
        this.sbt = false;
        this.allowedSbtSports = [
            "football",
            "badminton",
            "tennis",
            "squash",
        ];
        // tslint:disable-next-line:no-empty
        this.onChange = function () { };
        // tslint:disable-next-line:no-empty
        this.onTouched = function () { };
        this.buildForm();
        this.setupSuggestions();
        this.setupPitches();
        this.setupDateTime();
    }
    Object.defineProperty(AutoSuggestComponent.prototype, "input", {
        get: function () {
            return this.formGroup.get("input");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoSuggestComponent.prototype, "pitch", {
        get: function () {
            return this.formGroup.get("pitch");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoSuggestComponent.prototype, "time", {
        get: function () {
            return this.formGroup.get("time");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoSuggestComponent.prototype, "date", {
        get: function () {
            return this.formGroup.get("date");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoSuggestComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this._value = val;
            this.onChange(val);
            this.onTouched();
        },
        enumerable: true,
        configurable: true
    });
    AutoSuggestComponent.prototype.ngOnInit = function () {
        this.selection = -1;
    };
    AutoSuggestComponent.prototype.focusInput = function () {
        if (this.input.value && this.closed) {
            this.googleLocation = undefined;
            this.forceReload.emit();
        }
    };
    AutoSuggestComponent.prototype.getLocation = function () {
        var _this = this;
        if (navigator.geolocation) {
            this.gettingLocation = true;
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.autoSuggestService
                    .callGoogleGeoCoderLatLng(position.coords.latitude, position.coords.longitude)
                    .subscribe(function (data) {
                    _this.googleLocation = data[0];
                    _this.locationSelected.emit(_this.googleLocation);
                    _this.formGroup.setValue({
                        input: [data[0].formatted_address],
                        pitch: null,
                        date: null,
                        time: null,
                    });
                    _this.gettingLocation = false;
                    if (_this.sport) {
                        _this.autoSuggestService
                            .callPitchesByLocationSport(_this.googleLocation.geometry.location, _this.sport.id)
                            .subscribe(function (pitches) {
                            if (pitches.meta.total_online_venues &&
                                _this.allowedSbtSports.indexOf(_this.sport.id) !== -1) {
                                _this.showSbt();
                            }
                        });
                    }
                });
            });
        }
        else {
            alert("Could not determine your current location.");
        }
    };
    AutoSuggestComponent.prototype.buildForm = function () {
        this.formGroup = this.formBuilder.group({
            input: [this.autoSuggestService.location, Validators.required],
            pitch: null,
            date: null,
            time: null,
        });
    };
    AutoSuggestComponent.prototype.close = function () {
        this.closed = true;
        this.selection = -1;
        this.googleResults = [];
        this.venueResults = [];
    };
    AutoSuggestComponent.prototype.showSbt = function () {
        this.sbt = true;
    };
    AutoSuggestComponent.prototype.openFromGooglePlace = function (result) {
        var _this = this;
        this.autoSuggestService
            .callGoogleGeoCoder(result.place_id)
            .pipe(tap(function (_) {
            _this.close();
            _this.selection = -1;
        }), tap(function (data) { return (_this.googleLocation = data[0]); }), 
        // tslint:disable-next-line: no-void-expression
        tap(function (data) { return _this.setInputLocation(); }), 
        // tslint:disable-next-line: no-void-expression
        map(function (_) { return _this.locationSelected.emit(_this.googleLocation); }), filter(function (_) { return !!_this.sport; }), mergeMap(function (_) {
            return _this.autoSuggestService.callPitchesByLocationSport(_this.googleLocation.geometry.location, _this.sport.id);
        }))
            .subscribe(function (pitches) {
            _this.location = result;
            _this.pitches = [];
            _this.googleResults = [];
            _this.venueResults = [];
            if (pitches &&
                pitches.meta &&
                pitches.meta.total_online_venues &&
                _this.allowedSbtSports.indexOf(_this.sport.id) !== -1) {
                _this.showSbt();
            }
            else {
                _this.input.setValue(result.structured_formatting.main_text);
                var queryParams = {
                    // tslint:disable-next-line: no-unbound-method
                    latitude: _this.googleLocation.geometry.location.lat,
                    // tslint:disable-next-line: no-unbound-method
                    longitude: _this.googleLocation.geometry.location.lng,
                };
                _this.closeDropdown.emit();
                // tslint:disable-next-line: no-floating-promises
                _this.router.navigate(["uk", "results", _this.sport.id], {
                    queryParams: queryParams,
                });
            }
        });
        this.eventTrackingService.trackAutoCompleteGooglePlace(result.structured_formatting.main_text, this.input.value);
    };
    AutoSuggestComponent.prototype.setInputLocation = function () {
        var location = this.googleLocation.address_components[0].long_name;
        // Prevent location from being "40" when it should be "40 Goodge Street"
        // tslint:disable-next-line: radix
        if (parseInt(location).toString() === location &&
            this.googleLocation.address_components.length > 1) {
            location += " " + this.googleLocation.address_components[1].long_name;
        }
        this.input.setValue(location);
    };
    AutoSuggestComponent.prototype.removeLocation = function () {
        this.location = undefined;
    };
    AutoSuggestComponent.prototype.disableSBT = function () {
        this.sbt = false;
    };
    AutoSuggestComponent.prototype.openFromVenue = function (venue) {
        this.autoSuggestService.lastInputValue = this.input.value;
        this.input.reset();
        this.pitches = [];
        var pitches = this.getPitchesFromVenueWithSelectedSport(venue, this.sport.id);
        if (pitches.length === 1) {
            this.input.markAsPristine();
            this.closeDropdown.emit();
            this.trackOpenFromPitch(pitches[0]);
            // tslint:disable-next-line: no-floating-promises
            this.router.navigate([pitches[0].links.weblink]);
        }
        else if (pitches.length !== 0) {
            this.pitches = pitches;
            this.venue = venue;
            this.venueSelected.emit(venue);
            this.googleResults = [];
            this.venueResults = [];
            this.selection = -1;
        }
        this.close();
    };
    AutoSuggestComponent.prototype.removeVenue = function () {
        this.venue = undefined;
    };
    AutoSuggestComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    AutoSuggestComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    AutoSuggestComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    AutoSuggestComponent.prototype.markAsDirty = function () {
        this.input.markAsDirty();
    };
    // tslint:disable-next-line: cyclomatic-complexity
    AutoSuggestComponent.prototype.keyboardControl = function (event) {
        if (this.closed) {
            if (event.key === "Enter") {
                this.submit.emit();
            }
        }
        else {
            // Enter: select or close results list
            if (event.key === "Enter") {
                event.preventDefault();
                if (this.googleResults &&
                    this.selection >= 0 &&
                    this.selection < Math.min(this.googleResults.length, 3)) {
                    this.openFromGooglePlace(this.googleResults[this.selection]);
                }
                else if (this.googleResults &&
                    this.venueResults &&
                    this.selection >= Math.min(this.googleResults.length, 3) &&
                    this.selection <
                        Math.min(this.googleResults.length, 3) +
                            Math.min(this.venueResults.length, 3)) {
                    this.openFromVenue(this.venueResults[this.selection -
                        Math.min(this.googleResults.length, 3)]);
                }
                else if (this.selection === -1) {
                    this.submit.emit();
                }
                else {
                    this.close();
                }
            }
            // Arrow Down or Tab, move selection down if possible
            if ((event.key === "ArrowDown" ||
                (event.key === "Tab" && !event.shiftKey)) &&
                // tslint:disable-next-line: max-line-length
                this.selection <
                    (this.googleResults ? this.googleResults.length : 0) +
                        (this.venueResults
                            ? Math.min(this.venueResults.length, 3)
                            : 0)) {
                event.preventDefault();
                this.selection++;
            }
            // Arrow Up or Shift + Tab, move selection up if possible
            if ((event.key === "ArrowUp" ||
                (event.key === "Tab" && event.shiftKey)) &&
                this.selection > 0) {
                event.preventDefault();
                this.selection--;
            }
        }
    };
    AutoSuggestComponent.prototype.getPitchesFromVenueWithSelectedSport = function (venue, sportSlug) {
        return venue.relationships.pitches.data.filter(function (pitch) { return pitch.attributes.sport.replace(" ", "-") === sportSlug; });
    };
    AutoSuggestComponent.prototype.setupSuggestions = function () {
        var _this = this;
        this.input.valueChanges
            .pipe(
        // tslint:disable-next-line: no-void-expression
        tap(function (_) { return _this.removeLocation(); }), tap(function (value) { return (_this.autoSuggestService.location = value); }), tap(function (value) { return (_this.value = value); }), // we update parent formcontrol to stay sync
        filter(function (_) { return !!_this.sport; }), tap(function (value) { return (!value ? _this.resetSuggestions() : ""); }), debounceTime(250), 
        // tslint:disable-next-line: no-void-expression
        tap(function (_) { return _this.disableSBT(); }), distinctUntilChanged(), switchMap(function (value) { return _this.getData(value); }))
            .subscribe();
    };
    AutoSuggestComponent.prototype.setupPitches = function () {
        var _this = this;
        this.pitch.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (pitch) {
            _this.pitchSelected.emit(pitch);
        });
    };
    AutoSuggestComponent.prototype.setupDateTime = function () {
        var _this = this;
        this.date.valueChanges.subscribe(function (date) {
            // tslint:disable-next-line: no-object-literal-type-assertion object-literal-shorthand
            _this.dateTimeSelected.emit({
                date: date,
                time: _this.time.value,
            });
        });
        this.time.valueChanges.subscribe(function (time) {
            // tslint:disable-next-line: no-object-literal-type-assertion
            _this.dateTimeSelected.emit({
                date: _this.date.value,
                time: time,
            });
        });
    };
    AutoSuggestComponent.prototype.resetSuggestions = function () {
        this.googleLocation = undefined;
        this.googleResults = [];
        this.venueResults = [];
        this.selection = -1;
    };
    AutoSuggestComponent.prototype.getData = function (valuePassed) {
        var _this = this;
        return of(valuePassed).pipe(filter(function (value) { return value; }), switchMap(function (value) {
            return _this.autoSuggestService
                .callAutoComplete(value, _this.countries)
                .pipe(tap(function (data) {
                if (!_this.googleLocation &&
                    !_this.venue &&
                    value === _this.input.value) {
                    _this.googleResults = data;
                    _this.closed = false;
                }
            }), mapTo(value));
        }), switchMap(function (value) {
            return _this.autoSuggestService
                .callVenueByNameSport(value, _this.sport.id)
                .pipe(map(function (venues) {
                if (!_this.googleLocation &&
                    !_this.location &&
                    value === _this.input.value) {
                    _this.venueResults = venues;
                    _this.closed = false;
                }
            }), mapTo(value));
        }));
    };
    AutoSuggestComponent.prototype.trackOpenFromPitch = function (pitch) {
        this.eventTrackingService.trackAutoCompleteVenueSuggestion(pitch.attributes.name + " | " + pitch.attributes.sport + " | " + pitch.attributes.format + " | " + pitch.attributes.surface, this.autoSuggestService.lastInputValue);
    };
    return AutoSuggestComponent;
}());
export { AutoSuggestComponent };
