import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
    ButtonSharedModule,
    CitySelectSharedModule,
    IconSharedModule,
    JumbotronSharedModule,
    SelectSharedModule,
} from "pf_styleguide";

import { SearchFormModule } from "../../shared/module/search-form/search-form.module";
import { CitySelectComponent } from "./city-select/city-select.component";
import { LocationSelectComponent } from "./location-select.component";
import { ProcessComponent } from "./process/process.component";

@NgModule({
    declarations: [
        LocationSelectComponent,
        ProcessComponent,
        CitySelectComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        ReactiveFormsModule,

        SearchFormModule,
        JumbotronSharedModule,
        IconSharedModule,
        CitySelectSharedModule,
        ButtonSharedModule,
        SelectSharedModule,
    ],
    exports: [LocationSelectComponent, ProcessComponent, CitySelectComponent],
})
export class LocationSelectModule {}
