import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import {
    ButtonSharedModule,
    InputSharedModule,
    SelectSharedModule,
} from "pf_styleguide";

import { AutoSuggestModule } from "../auto-suggest/auto-suggest.module";
import { SearchFormComponent } from "./search-form.component";

@NgModule({
    declarations: [SearchFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        AutoSuggestModule,
        ButtonSharedModule,
        InputSharedModule,
        SelectSharedModule,
    ],
    exports: [SearchFormComponent],
})
export class SearchFormModule {}
