<pf-location-select
    [title]="cityStatic?.title"
    [description]="cityStatic?.sub_title"
>
</pf-location-select>

<section class="sg-background__primary -light sg-color__white">
    <pf-sports-icons
        *ngIf="cityStatic"
        [sports]="sports | async"
        title="Choose your sport"
        [city]="cityStatic?.slug"
        class="container d-block"
    >
    </pf-sports-icons>
</section>

<div
    class="container sg-mt-2 sg-mb-1"
    *ngIf="cityStatic?.venues?.section_0.length > 0"
>
    <div class="row">
        <div class="col-12 sg-mb-1">
            <h2
                class="sg-heading__h3 text-center mb-3 pb-3 pb-md-0 text-uppercase"
            >
                Recommended for you
            </h2>
        </div>
    </div>

    <mlp-ng-city-venue
        [venue]="cityStatic?.venues.section_0"
    ></mlp-ng-city-venue>
</div>

<pf-app-banner></pf-app-banner>

<div class="container sg-mt-2 sg-mb-1">
    <mlp-ng-shared-booking-process
        [steps]="cityStatic?.steps"
    ></mlp-ng-shared-booking-process>
    <mlp-ng-shared-stats [stats]="cityStatic?.stats"></mlp-ng-shared-stats>
</div>

<div class="page__section--smoke sg-pb-1 sg-pb-md-0" *ngIf="cityStatic?.blog">
    <div class="container-fluid sg-pt-2 sg-mb-2 sg-mb-sm-0">
        <div class="container">
            <div class="row">
                <div class="col-12 sg-mb-1">
                    <h2
                        class="sg-heading__h3 text-center mb-3 pb-3 text-uppercase"
                    >
                        Sports Blog
                    </h2>
                    <p class="sg-subheading__h3 text-center mb-0">
                        {{ cityStatic?.blog.blogDescription }}
                    </p>
                </div>
            </div>

            <mlp-ng-shared-blog
                [city]="cityStatic?.blog.city"
            ></mlp-ng-shared-blog>
        </div>
    </div>
</div>

<mlp-partner-banner></mlp-partner-banner>
