import { Component, ElementRef, ViewChild } from "@angular/core";

import { ModalService } from "pf_styleguide";

import { AuthService } from "../auth.service";

@Component({
    selector: "pf-sign-up-modal",
    templateUrl: "sign-up-modal.component.html",
})
export class SignUpModalComponent {
    @ViewChild("signUpContent") signUpContent: ElementRef;
    success = false;

    constructor(
        private authService: AuthService,
        private modalService: ModalService
    ) {}

    open(content): void {
        this.signUpContent = content;
        this.modalService.open(content, {
            windowClass: "sg-modal-secondary -title-left",
        });
    }

    loginSuccess($event): void {
        this.success = $event;
    }

    switchModal(): void {
        this.authService.openSignInEvent.emit();
    }
}
