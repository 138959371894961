<div
    ngbDropdown
    #dropDownSelectTime="ngbDropdown"
    placement="bottom-right"
    autoClose="outside"
>
    <div ngbDropdownAnchor id="dropdownSelectDuration">
        <button
            class="pf-form-button"
            type="button"
            ngbDropdownToggle
            [ngClass]="{
                '-active': value,
                '-error':
                    (formControl.touched && !formControl.valid) || showError
            }"
        >
            {{ !value ? placeholder : formatValue(value) }}
            <mlp-ng-icon
                *ngIf="!value"
                class="pf-form-button__icon"
                name="arrow-down-1"
            ></mlp-ng-icon>
        </button>
    </div>

    <div
        class="dropdown-menu dropdown-menu-right shadow-sm p-3"
        ngbDropdownMenu
        aria-labelledby="dropdownSelectDate"
    >
        <ngb-timepicker
            [(ngModel)]="time"
            (ngModelChange)="selected()"
            (select)="selectedTime($event)"
            [hourStep]="1"
            [minuteStep]="15"
        ></ngb-timepicker>

        <mlp-ng-button
            fullWidth="1"
            [button]="{
                label: 'Select',
                type: 'button'
            }"
            btnStyle="lighter"
            (click)="selected(); dropDownSelectTime.close()"
        >
        </mlp-ng-button>
    </div>
</div>
