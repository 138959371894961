import { Injectable } from "@angular/core";
import { Enquiry } from "./model/api/enquiry";
import { PitchData } from "./model/pitch";
import { Angulartics2 } from "angulartics2";
import { MlpHelper } from "./mlpHelper";
import { MinutesToTextPipe } from "../pipe/minutes-to-text.pipe";
import format from "date-fns/format";
import parse from "date-fns/parse";
import diffInMinutes from "date-fns/differenceInMinutes";
import { SportFormatSurfaceTextPipe } from "../pipe/sport-format-surface-text.pipe";
import { Slot } from "./model/slot";
import { Booking } from "./model/booking";
import { BrowserService } from "./browser.service";
import { DiscountData } from "./model/discountData";

declare var gtag: any;
declare var ga: any;
declare var fbq: any;

@Injectable({
    providedIn: "root",
})
export class EventTrackingService {
    constructor(
        private angulartics2: Angulartics2,
        private browserService: BrowserService
    ) {}

    trackOptInMarketing(source: string, value: boolean) {
        this.angulartics2.eventTrack.next({
            action: "Opt-in Email Subscription",
            properties: {
                category: source,
                label: `${value}`,
            },
        });
    }

    trackEnquiry(enquiry: Enquiry, pitch: PitchData, city: string) {
        let category = "Enquiry sent MLP pitch";
        let action = "Enquiry sent MLP pitch";

        if (!pitch.data.attributes.mlp) {
            category = "Enquiry sent non MLP pitch";
            action = "Enquiry sent non MLP pitch";
        }

        let label = this.createLabelFromEnquiry(enquiry, pitch, city);

        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    }

    trackEnquiryBlockBookingConverted(
        enquiry: Enquiry,
        pitch: PitchData,
        city: string
    ) {
        let category = "Enquiry sent MLP pitch";
        let action = "Enquiry sent MLP pitch - Convert to Block from Checkout";

        if (!pitch.data.attributes.mlp) {
            category = "Enquiry sent non MLP pitch";
            action =
                "Enquiry sent non MLP pitch - Convert to Block from Checkout";
        }

        let label = this.createLabelFromEnquiry(enquiry, pitch, city);

        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    }

    /**
     * CITY | Google place | searchTerm
     *
     * @param googlePlace
     * @param searchTerm
     */
    trackAutoCompleteGooglePlace(googlePlace: string, searchTerm: string) {
        let category = "General - Autocomplete";
        let action = "Google places suggestion";
        let label = googlePlace + " | " + searchTerm;

        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    }

    /**
     *
     * __example__
     * trackAuto('Category', 'action', 'label')
     *
     * CITY | Venue name | searchTerm
     * @param action
     * @param category
     * @param label
     */

    trackEvent(category: string, action: string, label: string) {
        this.angulartics2.eventTrack.next({
            action,
            properties: { category, label },
        });
    }

    /**
     * CITY | Venue name | searchTerm
     * @param venueName
     * @param searchTerm
     */

    trackAutoCompleteVenueSuggestion(venueName: string, searchTerm: string) {
        let category = "General - Autocomplete";
        let action = "MLP venue suggestion";
        let label = venueName + " | " + searchTerm;

        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });

    }

    /**
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD
     * @param slot
     * @param pitch
     * @param city
     * @param booking
     * @param finalPrice
     * @param discountData: undefined | DiscountData
     * @returns {string}
     */
    trackBooking(
        city: string,
        pitch: PitchData,
        slot: Slot,
        booking: Booking,
        finalPrice: number,
        discountData: undefined | DiscountData
    ) {
        let category = "Booking confirmed";
        let action = "Customer paid";
        let label = this.createLabelFromSlot(slot, pitch, city, discountData);


        gtag ? gtag('event', action, 
            {
                category,
                label
            }
        ) : null;

        fbq("track", "Purchase", {
            value: finalPrice,
            currency: slot.attributes.currency,
        });
    }

    /**
     *
     * @param action
     * @param label
     */
    trackFilters(action: string, label: string): void {
        // Turn off tracking filters
        return;
    }

    /**
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD | Block booking: 10
     * @param enquiry
     * @param pitch
     * @param city
     * @returns {string}
     */
    private createLabelFromEnquiry(
        enquiry: Enquiry,
        pitch: PitchData,
        city: string
    ) {
        let minutesToTextPipe = new MinutesToTextPipe();
        let sportFormatSurfacePipe = new SportFormatSurfaceTextPipe();
        let date = parse(
            enquiry.data.attributes.date + " " + enquiry.data.attributes.time,
            'yyyy-MM-dd HH:mm:ss',
            new Date(),
        );
        let blockBooking =
            enquiry.data.attributes.block_booking.toLowerCase() == "yes"
                ? " | Block booking: " + enquiry.data.attributes.weeks
                : "";

        return (
            MlpHelper.uppercaseFirstLetter(city) +
            " | " +
            pitch.data.attributes.name +
            " | " +
            pitch.data.attributes.sport +
            " | " +
            sportFormatSurfacePipe.transform(pitch.data) +
            " | " +
            enquiry.data.attributes.time +
            " | " +
            minutesToTextPipe.transform(enquiry.data.attributes.duration) +
            " | " +
            format(date, "dddd") +
            " | " +
            enquiry.data.attributes.date +
            blockBooking
        );
    }

    /**
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD
     * @param slot
     * @param pitch
     * @param city
     * @param discountData: undefined | DiscountData
     * @returns {string}
     */
    private createLabelFromSlot(
        slot: Slot,
        pitch: PitchData,
        city: string,
        discountData: undefined | DiscountData
    ) {
        let minutesToTextPipe = new MinutesToTextPipe();
        let sportFormatSurfacePipe = new SportFormatSurfaceTextPipe();
        let ends = new Date(slot.attributes.ends);
        let starts = new Date(slot.attributes.starts);
        let durationInMinutes = diffInMinutes(
            ends,
            starts
        );

        const promoCode = discountData ? ` | Promo: ${discountData.code}` : "";

        return (
            MlpHelper.uppercaseFirstLetter(city) +
            " | " +
            pitch.data.attributes.name +
            " | " + // | Promo: WORLDCUP
            pitch.data.attributes.sport +
            " | " +
            sportFormatSurfacePipe.transform(pitch.data) +
            " | " +
            format(starts, "HH:mm") +
            " | " +
            minutesToTextPipe.transform(durationInMinutes) +
            " | " +
            format(starts, "dddd") +
            " | " +
            format(starts, "yyyy-MM-dd") +
            promoCode
        );
    }
}
