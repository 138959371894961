import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "minutesToText",
})
export class MinutesToTextPipe implements PipeTransform {
    transform(value: number | undefined): any {
        let returnText = "";

        if (value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            if (hours > 0) {
                returnText = hours + (hours == 1 ? " hour " : " hours ");
            }

            if (minutes > 0) {
                returnText = returnText + minutes + " minutes";
            }
        }

        return returnText.trim();
    }
}
