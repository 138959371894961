import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, } from "@angular/router";
import { CitySelectService } from "../shared/city-select.service";
import { City } from "../shared/model/city";
import { Observable } from "rxjs";
import { filter, take } from "rxjs/operators";

@Injectable()
export class CityResolver implements Resolve<any> {

    constructor(
        private citySelectService: CitySelectService
    ) {
    }

    resolve(
        activatedRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<City>|Promise<City>|City {
        const city = activatedRoute.params.city;
        return new Observable(subscriber => this.citySelectService.cities.subscribe(
            (cities: City[]) => cities
                    .filter((c: City) => city === c.slug)
                    .map((c: City) => {
                        subscriber.next(c);
                        subscriber.complete();
                    })
        ));
    }
}
