/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-date.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i3 from "pf_styleguide";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i7 from "@angular/forms";
import * as i8 from "./select-date.component";
import * as i9 from "../../date.service";
var styles_SelectDateComponent = [i0.styles];
var RenderType_SelectDateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectDateComponent, data: {} });
export { RenderType_SelectDateComponent as RenderType_SelectDateComponent };
function View_SelectDateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mlp-ng-icon", [["class", "pf-form-button__icon"], ["name", "arrow-down-1"]], null, null, null, i2.View_IconSharedComponent_0, i2.RenderType_IconSharedComponent)), i1.ɵdid(1, 49152, null, 0, i3.IconSharedComponent, [i3.TransformSportToIconNamePipe], { setIconName: [0, "setIconName"] }, null)], function (_ck, _v) { var currVal_0 = "arrow-down-1"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SelectDateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["autoClose", "outside"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 212992, [["dropDownSelectDate", 4]], 2, i4.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i5.DOCUMENT, i1.NgZone], { autoClose: [0, "autoClose"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["id", "dropdownSelectDuration"], ["ngbDropdownAnchor", ""]], [[1, "aria-expanded", 0]], null, null, null, null)), i1.ɵdid(6, 16384, [[2, 4]], 0, i4.ɵq, [i4.NgbDropdown, i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "button", [["aria-haspopup", "true"], ["class", "pf-form-button dropdown-toggle"], ["ngbDropdownToggle", ""], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "-active": 0, "-error": 1 }), i1.ɵdid(11, 16384, null, 0, i4.ɵr, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i4.ɵq, null, [i4.ɵr]), (_l()(), i1.ɵted(13, null, ["\n            ", "\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDateComponent_1)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(19, 0, null, null, 8, "div", [["aria-labelledby", "dropdownSelectDate"], ["class", "dropdown-menu shadow-sm p-0 border-0"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(20, 16384, [[1, 4]], 0, i4.ɵp, [i4.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(22, 0, null, null, 4, "ngb-datepicker", [["formControlName", "date"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        _co.selectedDate($event);
        var pd_0 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NgbDatepicker_0, i6.RenderType_NgbDatepicker)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.NgbDatepicker]), i1.ɵprd(512, null, i4.ɵx, i4.ɵx, [i4.NgbCalendar, i4.NgbDatepickerI18n]), i1.ɵprd(512, null, i4.ɵy, i4.ɵy, [i4.ɵx, i4.NgbCalendar]), i1.ɵdid(26, 770048, [["dp", 4]], 0, i4.NgbDatepicker, [i4.ɵy, i4.ɵx, i4.NgbCalendar, i4.NgbDatepickerI18n, i4.NgbDatepickerConfig, i1.ChangeDetectorRef, i1.ElementRef, i4.NgbDateAdapter, i1.NgZone], { maxDate: [0, "maxDate"], minDate: [1, "minDate"] }, { select: "select" }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "outside"; _ck(_v, 1, 0, currVal_1); var currVal_4 = "pf-form-button"; var currVal_5 = _ck(_v, 10, 0, _co.value, ((_co.formControl.touched && !_co.formControl.valid) || _co.showError)); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_7 = !_co.value; _ck(_v, 15, 0, currVal_7); var currVal_11 = _co.maxDate; var currVal_12 = _co.minDate; _ck(_v, 26, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 11).dropdown.isOpen(); _ck(_v, 8, 0, currVal_3); var currVal_6 = (!_co.value ? _co.placeholder : _co.formatValue(_co.value)); _ck(_v, 13, 0, currVal_6); var currVal_8 = true; var currVal_9 = i1.ɵnov(_v, 20).dropdown.isOpen(); var currVal_10 = i1.ɵnov(_v, 20).placement; _ck(_v, 19, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_SelectDateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mlp-select-date", [], null, null, null, View_SelectDateComponent_0, RenderType_SelectDateComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.SelectDateComponent]), i1.ɵprd(5120, null, i7.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i8.SelectDateComponent]), i1.ɵdid(3, 114688, null, 0, i8.SelectDateComponent, [i9.DateService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var SelectDateComponentNgFactory = i1.ɵccf("mlp-select-date", i8.SelectDateComponent, View_SelectDateComponent_Host_0, { _value: "value", placeholder: "placeholder", showError: "showError", maxDateDays: "maxDateDays", minDate: "minDate", maxDate: "maxDate" }, {}, []);
export { SelectDateComponentNgFactory as SelectDateComponentNgFactory };
