/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i2 from "pf_styleguide";
import * as i3 from "ngx-cookie";
import * as i4 from "@angular/common";
import * as i5 from "./footer.component";
import * as i6 from "../shared/city-select.service";
import * as i7 from "../shared/sport-select.service";
import * as i8 from "../configure/configure.service";
import * as i9 from "@angular/router";
import * as i10 from "../shared/eventTracking.service";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-ng-mega-footer", [], null, null, null, i1.View_ɵu_0, i1.RenderType_ɵu)), i0.ɵdid(1, 573440, null, 0, i2.ɵu, [i3.CookieService], { data: [0, "data"], cities: [1, "cities"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.cities; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mlp-ng-mega-footer", [], null, null, null, i1.View_ɵu_0, i1.RenderType_ɵu)), i0.ɵdid(1, 573440, null, 0, i2.ɵu, [i3.CookieService], { data: [0, "data"], city: [1, "city"], sports: [2, "sports"], cityUppercase: [3, "cityUppercase"] }, null), i0.ɵpod(2, { data: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = ("/" + ((_co.citySelectService.city == null) ? null : _co.citySelectService.city.slug)); var currVal_2 = _ck(_v, 2, 0, _co.sportSelectService.sportsCollected); var currVal_3 = ((((_co.citySelectService == null) ? null : _co.citySelectService.city) && (((_co.citySelectService == null) ? null : ((_co.citySelectService.city == null) ? null : _co.citySelectService.city.slug)) === "uk")) ? "the UK" : ((_co.citySelectService == null) ? null : ((_co.citySelectService.city == null) ? null : _co.citySelectService.city.name))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-ng-social-banner", [], null, [[null, "clickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickEvent" === en)) {
        var pd_0 = (_co.trackEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SocialBannerSharedComponent_0, i1.RenderType_SocialBannerSharedComponent)), i0.ɵdid(1, 49152, null, 0, i2.SocialBannerSharedComponent, [], { icons: [0, "icons"], action: [1, "action"] }, { clickEvent: "clickEvent" }), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FooterComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FooterComponent_2)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icons; var currVal_1 = _co.action; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (((i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.sportSelectService.sports)).length === 0) && (_co.cities.data.length > 1)) || ((_co.eventUrl === "/") && (_co.cities.data.length > 1))); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.sportSelectService.sports)).length > 0) && (_co.eventUrl !== "/")); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pf-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i5.FooterComponent, [i6.CitySelectService, i7.SportSelectService, i8.ConfigureService, i9.Router, i10.EventTrackingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i0.ɵccf("pf-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
