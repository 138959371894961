<div class="text-center">
    <div class="mb-3 pb-3">
        <mlp-ng-icon [name]="item.icon" size="large"></mlp-ng-icon>
    </div>
    <div class="sg-heading__h3">
        {{ item.title }}
    </div>
    <div class="d-flex d-flex justify-content-center mt-1 sg-mb-1">
        <div class="sg-divider__short"></div>
    </div>
    <div class="sg-subheading__h4 mb-3 description">
        {{ item.description }}
    </div>
    <div class="mb-5 pb-3 mb-md-3 d-flex justify-content-center text-nowrap">
        <div>
            <mlp-ng-button
                btnSize="small"
                btnStyle="secondary"
                [button]="{ label: 'Find Out More' }"
                fullWidth="1"
                (click)="goTo()"
            ></mlp-ng-button>
        </div>
    </div>
</div>
