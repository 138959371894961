import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

import { Observable } from "rxjs";

import { ConfigureService } from "./../app/configure/configure.service";

@Injectable({
    providedIn: "root",
})
export class HttpService {
    isBrowser: boolean = isPlatformBrowser(this.platformId);

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        public http: HttpClient,
        private config: ConfigureService
    ) {}

    get(url): Observable<any> {
        if (this.isBrowser) {
            return this.http.get(this.config.params.apiClientUri + url);
        } else {
            return this.http.get(this.config.params.apiServerUri + url);
        }
    }
}
