import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";

import { ConfigureService } from "../../../configure/configure.service";
import { GenericService } from "../../../shared/generic.service";
import { FeaturePage } from "./featured.interface";

const visibilityFlag = "banner-shown-true";

@Injectable()
export class FeatureService {
    constructor(
        private readonly configureService: ConfigureService,
        private readonly http: HttpClient,
        private readonly genericService: GenericService
    ) {}

    getBannerContent(): Observable<string> {
        return this.http
            .get<FeaturePage>(
                `${this.configureService.params.wordPress}/wp-json/wp/v2/pages/${this.configureService.params.mktBannerWpPage}`
            )
            .pipe(
                filter(
                    (page: FeaturePage) =>
                        page &&
                        !!page.content &&
                        page.meta_keywords === visibilityFlag
                ),
                map((page: FeaturePage) => page.content.rendered),
                catchError((error) => this.genericService.handleError(error))
            );
    }
}
