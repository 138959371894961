import { OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator, } from "@angular/forms";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { MlpHelper } from "../../mlpHelper";
import addDays from "date-fns/addDays";
import { DateService } from "../../date.service";
var SelectDateComponent = /** @class */ (function () {
    function SelectDateComponent(dateService) {
        this.dateService = dateService;
        this.placeholder = "Select Date";
        this.now = this.dateService.now();
        // tslint:disable-next-line:no-empty
        this.onChange = function () { };
        // tslint:disable-next-line:no-empty
        this.onTouched = function () { };
    }
    SelectDateComponent.prototype.ngOnInit = function () {
        if (this.maxDateDays) {
            this.maxDate = this.createDate(this.now, this.maxDateDays);
        }
        if (!this.minDate) {
            this.minDate = this.createDate(this.now);
        }
    };
    Object.defineProperty(SelectDateComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this._value = val;
            this.onChange(val);
            this.onTouched();
        },
        enumerable: true,
        configurable: true
    });
    SelectDateComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    SelectDateComponent.prototype.validate = function (c) {
        this.formControl = c;
        return c.valid ? undefined : c.errors;
    };
    SelectDateComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    SelectDateComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    SelectDateComponent.prototype.selectedDate = function ($event) {
        this.value = $event;
    };
    SelectDateComponent.prototype.formatValue = function (value) {
        return MlpHelper.pad(value.day, 2) + "/" + MlpHelper.pad(value.month, 2) + "/" + value.year;
    };
    SelectDateComponent.prototype.createDate = function (date, days) {
        if (days)
            date = addDays(date, days);
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };
    };
    return SelectDateComponent;
}());
export { SelectDateComponent };
