import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";

import { AuthService } from "../auth.service";

@Component({
    selector: "pf-keep-me-logged-in",
    templateUrl: "kepp-me-logged-in.component.html",
})
export class KeepMeLoggedInComponent implements OnInit {
    get keepMeLoggedIn(): AbstractControl {
        return this.keepLogged.get("keepMeLoggedIn");
    }
    keepLogged: FormGroup;

    constructor(private fb: FormBuilder, private authService: AuthService) {}

    ngOnInit(): void {
        this.keepLogged = this.fb.group({
            keepMeLoggedIn: [null, []],
        });

        this.keepMeLoggedIn.valueChanges.subscribe(
            (val) => (this.authService.rememberMe = val)
        );
    }
}
