import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ButtonSharedModule, IconSharedModule } from "pf_styleguide";

import { FeaturedComponent } from "./featured.component";
import { FeatureService } from "./featured.service";

@NgModule({
    declarations: [FeaturedComponent],
    imports: [CommonModule, ButtonSharedModule, RouterModule, IconSharedModule],
    exports: [FeaturedComponent],
    providers: [FeatureService],
})
export class FeaturedModule {}
