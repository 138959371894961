import { NgModule } from "@angular/core";

import { ClickOutsideDirective } from "./click.outside.directive";

@NgModule({
    declarations: [ClickOutsideDirective],
    imports: [],
    exports: [ClickOutsideDirective],
})
export class ClickOutsideSharedDirectiveModule {}
