import { ApiInterface } from "./api.interface";

export class CreateUserApi implements ApiInterface {
    data: {
        type: "users";
        id: null;
        attributes: {
            full_name: string;
            email: string;
            password: string;
        };
    };

    constructor(fullName: string, email: string, password: string) {
        this.data = {
            type: "users",
            id: null,
            attributes: {
                full_name: fullName,
                email,
                password,
            },
        };
    }
}
