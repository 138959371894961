/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../sign-in/sign-in.component.ngfactory";
import * as i2 from "../sign-in/sign-in.component";
import * as i3 from "../auth.service";
import * as i4 from "../../../generic.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../configure/configure.service";
import * as i7 from "../../../eventTracking.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i11 from "pf_styleguide";
import * as i12 from "./sign-in-modal.component";
var styles_SignInModalComponent = [];
var RenderType_SignInModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignInModalComponent, data: {} });
export { RenderType_SignInModalComponent as RenderType_SignInModalComponent };
function View_SignInModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "pf-sign-in", [], null, [[null, "closeModal"], [null, "showSignUp"], [null, "loginSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_v.parent.context.close() !== false);
        ad = (pd_0 && ad);
    } if (("showSignUp" === en)) {
        _v.parent.context.close();
        _co.switchModal();
        var pd_1 = (false !== false);
        ad = (pd_1 && ad);
    } if (("loginSuccess" === en)) {
        var pd_2 = (_co.loginSuccess($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_SignInComponent_0, i1.RenderType_SignInComponent)), i0.ɵdid(3, 114688, null, 0, i2.SignInComponent, [i3.AuthService, i4.GenericService, i5.FormBuilder, i6.ConfigureService, i7.EventTrackingService, i8.Router], null, { loginSuccess: "loginSuccess", closeModal: "closeModal", showSignUp: "showSignUp" }), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_SignInModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["class", "mb-3 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You have been signed in."])), (_l()(), i0.ɵted(-1, null, ["\n\n                "])), (_l()(), i0.ɵeld(5, 0, null, null, 8, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(7, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_v.parent.context.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                        "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "mlp-ng-button", [["fullWidth", "1"], ["label", "Go to dashboard"]], null, null, null, i10.View_ButtonSharedComponent_0, i10.RenderType_ButtonSharedComponent)), i0.ɵdid(11, 49152, null, 0, i11.ButtonSharedComponent, [], { fullWidth: [0, "fullWidth"], label: [1, "label"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(-1, null, ["\n\n                "])), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "mlp-ng-button", [["fullWidth", "1"], ["label", "Close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ButtonSharedComponent_0, i10.RenderType_ButtonSharedComponent)), i0.ɵdid(18, 49152, null, 0, i11.ButtonSharedComponent, [], { fullWidth: [0, "fullWidth"], label: [1, "label"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.dashboardLink; _ck(_v, 8, 0, currVal_2); var currVal_3 = "1"; var currVal_4 = "Go to dashboard"; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = "1"; var currVal_6 = "Close"; _ck(_v, 18, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 8).target; var currVal_1 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
function View_SignInModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 14, "mlp-ng-modal", [], null, null, null, i10.View_ModalSharedComponent_0, i10.RenderType_ModalSharedComponent)), i0.ɵdid(2, 49152, null, 0, i11.ModalSharedComponent, [i11.ModalService], null, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "section", [["class", "sg-heading__h5"], ["header", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Welcome to Playfinder"])), (_l()(), i0.ɵted(-1, null, ["\n\n        "])), (_l()(), i0.ɵeld(7, 0, null, 1, 7, "section", [["body", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignInModalComponent_2)), i0.ɵdid(10, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignInModalComponent_3)), i0.ɵdid(13, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.success; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.success; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_SignInModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { signInContent: 0 }), (_l()(), i0.ɵand(0, [[1, 2], ["signInContent", 2]], null, 0, null, View_SignInModalComponent_1)), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_SignInModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pf-sign-in-modal", [], null, null, null, View_SignInModalComponent_0, RenderType_SignInModalComponent)), i0.ɵdid(1, 49152, null, 0, i12.SignInModalComponent, [i3.AuthService, i11.ModalService], null, null)], null, null); }
var SignInModalComponentNgFactory = i0.ɵccf("pf-sign-in-modal", i12.SignInModalComponent, View_SignInModalComponent_Host_0, {}, {}, []);
export { SignInModalComponentNgFactory as SignInModalComponentNgFactory };
