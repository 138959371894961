import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { TransformApiData } from "pf_styleguide/shared";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ConfigureService } from "../../../configure/configure.service";
import { BrowserService } from "../../browser.service";
import { GenericService } from "../../generic.service";
import { City } from "../../model/city";
import { PitchCollection } from "../../model/pitchCollection";
import { Venue } from "../../model/venue";

import {
    GoogleAutocompletePrediction,
    GooglePlaceResult,
} from "./auto-suggest.interface";

@Injectable({
    providedIn: "root",
})
export class AutoSuggestService {
    location = "";
    lastInputValue: string;
    validTypes = ["postal_code", "postal_code_prefix", "locality", "geocode"];

    constructor(
        private readonly http: HttpClient,
        private readonly config: ConfigureService,
        private readonly genericService: GenericService,
        private readonly cookieService: CookieService,
        private readonly browserService: BrowserService,
        private readonly transformApiData: TransformApiData
    ) {}

    callAutoComplete(
        input: string,
        countries: string[]
    ): Observable<Array<google.maps.places.AutocompletePrediction>> {
        let locationParams = "";
        let country = countries
            .map((country: string) => `country:${country}`)
            .join("|");

        return this.http
            .get(
                // tslint:disable-next-line:max-line-length
                `${this.config.params.googleMapsDomainAutoComplete}/maps/api/place/autocomplete/json?input=${input}${locationParams}&components=${country}&key=${this.config.params.googleMapsAPIkey}`
            )
            .pipe(
                map((data: GoogleAutocompletePrediction) => data.predictions),
                map((data) => this.processAutoCompleteResponse(data))
            );
    }

    callGoogleGeoCoder(
        placeId
    ): Observable<Array<google.maps.places.PlaceResult>> {
        return this.http
            .get(
                // tslint:disable-next-line:max-line-length
                `${this.config.params.googleMapsDomain}/maps/api/geocode/json?place_id=${placeId}&key=${this.config.params.googleMapsAPIkey}`
            )
            .pipe(map((data: GooglePlaceResult) => data.results));
    }

    callGoogleGeoCoderLatLng(
        lat: number,
        lng: number
    ): Observable<Array<google.maps.places.PlaceResult>> {
        return this.http
            .get(
                // tslint:disable-next-line:max-line-length
                `${this.config.params.googleMapsDomain}/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.config.params.googleMapsAPIkey}`
            )
            .pipe(map((data: GooglePlaceResult) => data.results));
    }

    callVenueByNameSport(
        venueName: string,
        sport: string
    ): Observable<Array<Venue>> {
        return this.http
            .get(
                // tslint:disable-next-line:max-line-length
                `${this.config.params.apiClientUri}/venues?filter[name]=${venueName}&filter[sport]=${sport}&page[size]=2&include=pitches`
            )
            .pipe(
                catchError((_) => of([] as Array<Venue>)),
                map((data: any) =>
                    this.transformApiData.mapArrayInCollection(data, [
                        { pitches: "pitches" },
                    ])
                ),
                map((data: { data: Array<Venue> }) => data.data)
            );
    }

    callPitchesByLocationSport(
        location: google.maps.LatLng,
        sport: string
    ): Observable<PitchCollection> {
        return this.http
            .get<PitchCollection>(
                // tslint:disable-next-line:max-line-length no-unbound-method
                `${this.config.params.apiClientUri}/pitches?filter[location]=${location.lat},${location.lng}&filter[sport]=${sport}&filter[is_online]=true&filter[radius]=5mi&sort=relevance&include=venues&page[number]=1&page[size]=1`
            )
            .pipe(
                // tslint:disable-next-line: no-object-literal-type-assertion
                catchError((_) => of({} as PitchCollection))
            );
    }

    // tslint:disable-next-line:max-line-length
    protected processAutoCompleteResponse(
        data: Array<google.maps.places.AutocompletePrediction>
    ): Array<google.maps.places.AutocompletePrediction> {
        const collected = [];
        data.forEach((prediction) => {
            let validType = false;

            prediction.types.forEach((type) => {
                this.validTypes.forEach((validTypeValue) => {
                    if (type === validTypeValue) {
                        validType = true;
                    }
                });
            });

            if (validType) {
                collected.push(prediction);
            }
        });

        return collected;
    }
}
