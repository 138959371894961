import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { NgbDropdownModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import {
    ButtonSharedModule,
    HeaderSharedModule,
    IconSharedModule,
    ModalSharedModule,
    TextButtonInternalAtomSharedModule,
} from "pf_styleguide";

import { SearchFormModule } from "../shared/module/search-form/search-form.module";
import { HeaderComponent } from "./header.component";
import { HeaderService } from "./header.service";

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        RouterModule,
        HeaderSharedModule,
        IconSharedModule,
        NgbDropdownModule,
        TextButtonInternalAtomSharedModule,
        ModalSharedModule,
        ButtonSharedModule,
        SearchFormModule,
        NgbModalModule, // do not remove this as it will break if removed after aot build completes
    ],
    exports: [HeaderComponent],
    providers: [HeaderService],
})
export class HeaderModule {}
