import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
    ButtonSharedModule,
    InputSharedModule,
    PasswordSharedModule,
} from "pf_styleguide";

import { ErrorsModule } from "../../errors/errors.module";
import { KeepMeLoggedInModule } from "../keep-me-logged-in/keep-me-logged-in.module";
import { PfSocialLoginModule } from "../social-login/social-login.module";
import { SignInComponent } from "./../sign-in/sign-in.component";

@NgModule({
    declarations: [SignInComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,

        KeepMeLoggedInModule,
        InputSharedModule,
        ButtonSharedModule,
        ErrorsModule,
        PfSocialLoginModule,
        PasswordSharedModule,
    ],
    exports: [SignInComponent],
})
export class SignInModule {}
