var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, zip } from "rxjs";
import { filter, map } from "rxjs/operators";
import { HttpService } from "../../server/httpService";
import { BrowserService } from "./browser.service";
import { ConfigureService } from "../configure/configure.service";
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
import * as i2 from "../../server/httpService";
import * as i3 from "./browser.service";
import * as i4 from "@angular/router";
import * as i5 from "../configure/configure.service";
var CitySelectService = /** @class */ (function () {
    function CitySelectService(cookie, httpService, browserService, router, rootRoute, configService) {
        var _this = this;
        this.cookie = cookie;
        this.httpService = httpService;
        this.browserService = browserService;
        this.router = router;
        this.rootRoute = rootRoute;
        this.configService = configService;
        this.currentCity = new BehaviorSubject(null);
        this.cities = new BehaviorSubject([]);
        this.citiesMenu = new BehaviorSubject(null);
        this.configService.paramsLoadedSubject.pipe(filter(function (data) { return !!data; }))
            .subscribe(function () {
            _this.httpService
                .get("/cities")
                .pipe(map(function (cities) { return _this.filterCities(cities); }))
                .subscribe(function (cities) {
                _this.cities.next(cities);
            });
            _this.router.events
                .pipe(filter(function (e) { return e instanceof NavigationEnd; }), map(function (e) { return _this.getParams(_this.rootRoute); }))
                .subscribe(function (params) {
                _this.cityHolder = params.city;
                _this.setCurrentCity(params.city);
            });
            zip(_this.currentCity, _this.cities).subscribe(function (res) {
                _this.setCurrentCity(_this.cityHolder);
                _this.setCityMenu(res[1]);
            });
        });
    }
    CitySelectService.prototype.filterCities = function (cities) {
        var citiesResult = [];
        cities.data.forEach(function (city) {
            city.attributes.name =
                city.attributes.slug === "uk"
                    ? "Rest of the UK"
                    : city.attributes.name;
            citiesResult.push(city.attributes);
        });
        this.citiesStaticData = citiesResult;
        return citiesResult;
    };
    CitySelectService.prototype.setCurrentCity = function (cityPassed) {
        this.findSelectedCity(cityPassed);
    };
    CitySelectService.prototype.findSelectedCity = function (slug) {
        this.cityHolder = slug;
        if (this.citiesStaticData && this.citiesStaticData.length && slug) {
            var citySelected = this.citiesStaticData.find(function (city) { return city.slug === slug; });
            this.city = citySelected;
            this.currentCity.next(citySelected);
            this.setCityMenu(this.citiesStaticData);
        }
        else {
            this.currentCity.next(undefined);
        }
    };
    CitySelectService.prototype.setCityMenu = function (cities) {
        var createCities = cities
            ? {
                currentCity: this.city ? this.city.name : "Select city",
                cities: cities,
            }
            : null;
        this.citiesMenu.next(createCities);
    };
    // tslint:disable-next-line:prefer-function-over-method
    CitySelectService.prototype.getParams = function (route) {
        // route param names (eg /a/:personId) must be ditinct within
        // a route otherwise they'll be overwritten
        var params = route.snapshot.params;
        params = __assign({}, route.snapshot.queryParams, params, route.snapshot.data);
        if (route.children) {
            for (var _i = 0, _a = route.children; _i < _a.length; _i++) {
                var r = _a[_i];
                params = __assign({}, this.getParams(r), params);
            }
        }
        return params;
    };
    CitySelectService.ngInjectableDef = i0.defineInjectable({ factory: function CitySelectService_Factory() { return new CitySelectService(i0.inject(i1.CookieService), i0.inject(i2.HttpService), i0.inject(i3.BrowserService), i0.inject(i4.Router), i0.inject(i4.ActivatedRoute), i0.inject(i5.ConfigureService)); }, token: CitySelectService, providedIn: "root" });
    return CitySelectService;
}());
export { CitySelectService };
