import { Component, Input } from "@angular/core";
import { ErrorCollection } from "../../model/errorCollection";

@Component({
    selector: "mlp-errors",
    templateUrl: "./errors.component.html",
})
export class ErrorsComponent {
    @Input() errorCollection: ErrorCollection = new ErrorCollection();
}
