/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i2 from "pf_styleguide";
import * as i3 from "./prev.next.btn.component";
var styles_PrevNextSharedComponent = [];
var RenderType_PrevNextSharedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PrevNextSharedComponent, data: {} });
export { RenderType_PrevNextSharedComponent as RenderType_PrevNextSharedComponent };
export function View_PrevNextSharedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "d-lg-none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "mlp-ng-button-nav", [["side", "left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonNavSharedComponent_0, i1.RenderType_ButtonNavSharedComponent)), i0.ɵdid(3, 114688, null, 0, i2.ButtonNavSharedComponent, [], { side: [0, "side"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "mlp-ng-button-nav", [["side", "right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonNavSharedComponent_0, i1.RenderType_ButtonNavSharedComponent)), i0.ɵdid(6, 114688, null, 0, i2.ButtonNavSharedComponent, [], { side: [0, "side"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "left"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "right"; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PrevNextSharedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-ng-shared-prev-next", [], null, null, null, View_PrevNextSharedComponent_0, RenderType_PrevNextSharedComponent)), i0.ɵdid(1, 114688, null, 0, i3.PrevNextSharedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrevNextSharedComponentNgFactory = i0.ɵccf("mlp-ng-shared-prev-next", i3.PrevNextSharedComponent, View_PrevNextSharedComponent_Host_0, { max: "max" }, { visible: "visible" }, []);
export { PrevNextSharedComponentNgFactory as PrevNextSharedComponentNgFactory };
