/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./city.venue.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i4 from "pf_styleguide";
import * as i5 from "@angular/router";
import * as i6 from "../../shared/module/prev-next-btn/prev.next.btn.component.ngfactory";
import * as i7 from "../../shared/module/prev-next-btn/prev.next.btn.component";
import * as i8 from "./city.venue.component";
var styles_CityVenueComponent = [i0.styles];
var RenderType_CityVenueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CityVenueComponent, data: {} });
export { RenderType_CityVenueComponent as RenderType_CityVenueComponent };
function View_CityVenueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "city-card mx-1 px-2 mb-3"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "d-none d-md-block": 0 }), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "mlp-ng-card-plain", [], null, null, null, i3.View_CardPlainSharedComponent_0, i3.RenderType_CardPlainSharedComponent)), i1.ɵdid(5, 49152, null, 0, i4.CardPlainSharedComponent, [], { card: [0, "card"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n            "])), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "mlp-ng-button-atom", [["btnSize", "small"], ["btnStyle", "secondary"]], null, null, null, i3.View_ButtonAtomSharedComponent_0, i3.RenderType_ButtonAtomSharedComponent)), i1.ɵdid(11, 49152, null, 0, i4.ButtonAtomSharedComponent, [], { btnSize: [0, "btnSize"], btnStyle: [1, "btnStyle"], label: [2, "label"] }, null), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "city-card mx-1 px-2 mb-3"; var currVal_1 = _ck(_v, 2, 0, (_co.activeImage !== _v.context.index)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 5, 0, currVal_2); var currVal_5 = _v.context.$implicit.cardButton.link; _ck(_v, 8, 0, currVal_5); var currVal_6 = "small"; var currVal_7 = "secondary"; var currVal_8 = _v.context.$implicit.cardButton.title; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 8).target; var currVal_4 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_CityVenueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row d-flex flex-wrap justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CityVenueComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "text-center d-md-none mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mlp-ng-shared-prev-next", [], null, [[null, "visible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visible" === en)) {
        var pd_0 = (_co.isVisible($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_PrevNextSharedComponent_0, i6.RenderType_PrevNextSharedComponent)), i1.ɵdid(9, 114688, null, 0, i7.PrevNextSharedComponent, [], { max: [0, "max"] }, { visible: "visible" }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.venue; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.venue.length; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_CityVenueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mlp-ng-city-venue", [], null, null, null, View_CityVenueComponent_0, RenderType_CityVenueComponent)), i1.ɵdid(1, 49152, null, 0, i8.CityVenueComponent, [], null, null)], null, null); }
var CityVenueComponentNgFactory = i1.ɵccf("mlp-ng-city-venue", i8.CityVenueComponent, View_CityVenueComponent_Host_0, { venue: "venue" }, {}, []);
export { CityVenueComponentNgFactory as CityVenueComponentNgFactory };
