import { Component, Input } from "@angular/core";

@Component({
    selector: "mlp-ng-shared-stats",
    templateUrl: "./stats.component.html",
    styleUrls: ["./stats.components.scss"],
})
export class StatsSharedComponent {
    @Input() stats = [];
}
