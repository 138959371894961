<section class="row mb-4">
    <div class="col-4">
        <h4 class="sg-heading__h4 text-uppercase">Log In</h4>
    </div>

    <div class="col-8 text-right">
        <span class="small d-none d-md-inline">Don't have an account?</span>

        <mlp-ng-button-atom
            class="ml-sm-3"
            labelSecondary="Join Now"
            btnSize="xsmall"
            (click)="showSignUp.emit()"
        ></mlp-ng-button-atom>
    </div>
</section>

<form
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
    novalidate="novalidate"
    name="signInFormName"
    #ngForm="ngForm"
    *ngIf="!success"
>
    <div>
        <div class="mb-2">
            <mlp-ng-input
                inputType="email"
                autocomplete="email"
                formControlName="username"
                placeholder="Email Address"
                icon="email-action-unread"
                inputId="emailAddress"
                [errors]="
                    errorCollection.errors
                        ? errorCollection.errors
                        : ngForm.submitted && !username.valid
                        ? [{ title: 'Please enter a valid email address' }]
                        : []
                "
            ></mlp-ng-input>
        </div>

        <div class="mb-2">
            <p class="text-right small mb-1">
                <a
                    [routerLink]="['/resetting/request']"
                    (click)="closeForm()"
                    class="sg-link"
                    >Forgot your password?</a
                >
            </p>

            <mlp-ng-password
                formControlName="password"
                placeholder="Password"
                inputType="password"
                icon="lock-1"
                inputId="current-password"
                [errors]="
                    errorCollection.errors
                        ? errorCollection.errors
                        : ngForm.submitted && password.invalid
                        ? [{ title: 'Please enter a password' }]
                        : []
                "
            ></mlp-ng-password>
        </div>

        <div class="mb-3">
            <pf-keep-me-logged-in class="d-block my-4"></pf-keep-me-logged-in>
        </div>

        <div class="row mb-3">
            <div class="col order-1 order-lg-2 mb-3 mb-lg-0">
                <mlp-ng-button
                    label=" "
                    labelSecondary="Log In To Playfinder"
                    fullWidth="1"
                    btnType="submit"
                ></mlp-ng-button>
            </div>

            <div
                *ngIf="checkout"
                class="order-2 order-lg-1 col-12 col-lg-3 pr-lg-0"
            >
                <mlp-ng-button
                    label="Back"
                    fullWidth="1"
                    btnStyle="tertiary"
                    (click)="back.emit()"
                ></mlp-ng-button>
            </div>
        </div>
    </div>
</form>

<ng-container *ngIf="config.params.showSocialLogin || checkout">
    <div class="sg-divider__text my-4">
        <span class="sg-divider__text--content">Or</span>
    </div>
</ng-container>

<ng-container *ngIf="config.params.showSocialLogin">
    <mlp-social-login
        socialPlatform="facebook"
        (loginSuccess)="loginSuccessProcess($event)"
        class="mb-1"
    >
    </mlp-social-login>

    <mlp-social-login
        socialPlatform="google"
        (loginSuccess)="loginSuccessProcess($event)"
    >
    </mlp-social-login>


    <mlp-social-login
        socialPlatform="apple"
        (loginSuccess)="loginSuccessProcess($event)"
    >
    </mlp-social-login>

</ng-container>

<mlp-ng-button
    *ngIf="checkout"
    class="mt-1"
    label=" "
    labelSecondary="Continue as guest"
    fullWidth="1"
    btnStyle="secondary"
    (click)="guest.emit()"
></mlp-ng-button>
