import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { CheckBoxSharedModule } from "pf_styleguide";

import { KeepMeLoggedInComponent } from "./../keep-me-logged-in/keep-me-logged-in.component";

@NgModule({
    declarations: [KeepMeLoggedInComponent],
    imports: [CommonModule, ReactiveFormsModule, CheckBoxSharedModule],
    exports: [KeepMeLoggedInComponent],
})
export class KeepMeLoggedInModule {}
