import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { IconSharedModule } from "pf_styleguide";

import { SportsIconsComponent } from "./sports-icons.component";

@NgModule({
    declarations: [SportsIconsComponent],
    imports: [CommonModule, IconSharedModule, RouterModule.forChild([])],
    exports: [SportsIconsComponent],
})
export class SportsIconsModule {}
