import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ICitiesCollection, IMegaFooter } from "pf_styleguide";
import { ISocialBannerIcon } from "pf_styleguide/src/patterns/organisms/social-banner/social-banner.interface";
import { filter } from "rxjs/operators";
import { MEGAFOOTERDATA } from "../app.mega.footer.data";
import { ConfigureService } from "../configure/configure.service";
import { CitySelectService } from "../shared/city-select.service";
import { EventTrackingService } from "../shared/eventTracking.service";
import { SportSelectService } from "../shared/sport-select.service";

@Component({
    selector: "pf-footer",
    templateUrl: "footer.component.html",
})
export class FooterComponent implements OnInit {
    data: IMegaFooter = MEGAFOOTERDATA;
    icons = [
        {
            iconName: "social-media-facebook-1",
            iconLink: "https://www.facebook.com/playfinder",
            className: "col-3",
        },
        {
            iconName: "social-media-twitter",
            iconLink: "https://twitter.com/Playfinder",
            className: "col-3",
        },
        {
            iconName: "social-instagram",
            iconLink: "https://www.instagram.com/playfinder/",
            className: "col-3",
        },
        {
            iconName: "professional-network-linkedin",
            iconLink: "https://www.linkedin.com/company/playfinder",
            className: "col-3",
        },
    ];

    action =
        "https://playfinder.us7.list-manage.com/subscribe/post?u=a8becbd3c11bcdb30e3ca06cb&amp;id=c2e0463071";

    cities: ICitiesCollection = { data: [] };
    eventUrl = "";

    constructor(
        public citySelectService: CitySelectService,
        public sportSelectService: SportSelectService,
        private readonly config: ConfigureService,
        private readonly router: Router,
        private readonly eventTrackingService: EventTrackingService
    ) {}

    ngOnInit(): void {
        this.citySelectService.cities.subscribe((cities) => {
            cities.forEach((city: any) => {
                const cityClone = { ...city, slug: `/${city.slug}` };
                this.cities.data.push({ attributes: cityClone });
            });
        });

        this.router.events
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe((navEnd: NavigationEnd) => {
                this.eventUrl = navEnd.urlAfterRedirects;
            });
    }

    trackEvent(item: ISocialBannerIcon): void {
        this.eventTrackingService.trackEvent(
            "Social footer",
            this.resolveName(item.iconName),
            this.eventUrl
        );
    }

    resolveName(iconName: string): string {
        const arrayResolvedNames = [
            {
                iconName: "social-media-facebook-1",
                gaName: "Facebook",
            },
            {
                iconName: "social-media-twitter",
                gaName: "Twitter",
            },
            {
                iconName: "social-instagram",
                gaName: "Instagram",
            },
            {
                iconName: "professional-network-linkedin",
                gaName: "LinkedIn",
            },
        ];

        return arrayResolvedNames.find(
            (item: any) => item.iconName === iconName
        ).gaName;
    }
}
