<section class="row mb-4">
    <div class="col-6">
        <h4 class="sg-heading__h4 text-uppercase">Create an account</h4>
    </div>

    <div class="col-6 text-right">
        <span class="small d-none d-md-inline">Have an account?</span>

        <mlp-ng-button-atom
            class="ml-sm-3"
            labelSecondary="Log In"
            btnSize="xsmall"
            (click)="showSignIn.emit()"
        ></mlp-ng-button-atom>
    </div>
</section>

<form
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
    *ngIf="!success"
    name="signUpNew"
    #ngForm="ngForm"
    novalidate="novalidate"
>
    <div class="mb-2">
        <mlp-ng-input
            formControlName="fullName"
            placeholder="Full Name"
            icon="single-neutral-circle"
            inputId="fullName"
            [errors]="
                ngForm.submitted && !fullName.valid
                    ? [{ title: V_FULL_NAME.errorMessage[0] }]
                    : []
            "
        ></mlp-ng-input>
    </div>

    <div class="mb-2">
        <mlp-ng-input
            inputType="email"
            autocomplete="email"
            formControlName="email"
            placeholder="Email Address"
            icon="email-action-unread"
            inputId="emailAddress"
            [errors]="
                emailErrors
                    ? emailErrors
                    : ngForm.submitted && !email.valid
                    ? [{ title: V_EMAIL.errorMessage[0] }]
                    : []
            "
        ></mlp-ng-input>
    </div>

    <div class="mb-2">
        <mlp-ng-password-strength
            formControlName="password"
            placeholder="Password"
            inputType="password"
            autocomplete="new-password"
            icon="lock-1"
            inputId="current-password"
            [errors]="
                ngForm.submitted && password.invalid
                    ? [{ title: V_PASSWORD.errorMessage[0] }]
                    : []
            "
        ></mlp-ng-password-strength>
    </div>

    <div class="mb-3">
        <label class="d-block my-4">
            <mlp-ng-shared-checkbox
                formControlName="isAgreedOnTerms"
                size="small"
                class="d-inline-block"
                [class.sg-color__danger]="
                    ngForm.submitted && !isAgreedOnTerms.value
                "
                >Agree to our
                <a href="/terms" target="_blank" class="sg-link"
                    >Terms and conditions</a
                >
                and
                <a href="/privacy" target="_blank" class="sg-link"
                    >Privacy Policy</a
                >.
            </mlp-ng-shared-checkbox>

            <p
                class="sg-color__danger small"
                *ngIf="ngForm.submitted && !isAgreedOnTerms.value"
            >
                Please agree to our terms and conditions/Privacy Policy
            </p>
        </label>

        <div class="row mb-3">
            <div class="col order-1 order-lg-2 mb-3 mb-lg-0">
                <mlp-ng-button
                    label=" "
                    labelSecondary="Sign Up"
                    fullWidth="1"
                    btnType="submit"
                ></mlp-ng-button>
            </div>

            <div
                *ngIf="checkout"
                class="order-2 order-lg-1 col-12 col-lg-3 pr-lg-0"
            >
                <mlp-ng-button
                    label="Back"
                    fullWidth="1"
                    btnStyle="tertiary"
                    (click)="back.emit()"
                ></mlp-ng-button>
            </div>
        </div>

        <mlp-errors [errorCollection]="errorCollection"></mlp-errors>
    </div>
</form>

<ng-container *ngIf="config.params.showSocialLogin || checkout">
    <div class="sg-divider__text my-4">
        <span class="sg-divider__text--content">Or</span>
    </div>
</ng-container>

<ng-container *ngIf="config.params.showSocialLogin">
    <mlp-social-login
        socialPlatform="facebook"
        (loginSuccess)="loginSuccessProcess($event)"
        accessType="Sign Up"
        class="mb-1"
    >
    </mlp-social-login>

    <mlp-social-login
        socialPlatform="google"
        (loginSuccess)="loginSuccessProcess($event)"
        accessType="Sign Up"
    >
    </mlp-social-login>
</ng-container>

<mlp-ng-button
    *ngIf="checkout"
    class="mt-1"
    label=" "
    labelSecondary="Continue as guest"
    fullWidth="1"
    btnStyle="secondary"
    (click)="guest.emit()"
></mlp-ng-button>
