import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";

import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable()
export class HeaderService {
    showPhone = new BehaviorSubject<boolean>(true);
    showPhoneObservable = this.showPhone.asObservable();

    constructor(private router: Router) {
        this.router.events
            .pipe(filter((e) => e instanceof NavigationStart))
            .subscribe((_) => {
                this.updateShowPhone(true);
            });
    }

    updateShowPhone(state: boolean): void {
        this.showPhone.next(state);
    }
}
