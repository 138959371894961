import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import {
    NgbDropdownModule,
    NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ButtonSharedModule, IconSharedModule } from "pf_styleguide";

import { SelectTimeComponent } from "./select-time.component";

@NgModule({
    declarations: [SelectTimeComponent],
    imports: [
        CommonModule,
        FormsModule,

        NgbDropdownModule,
        NgbTimepickerModule,

        ButtonSharedModule,
        IconSharedModule,
    ],
    exports: [SelectTimeComponent],
    providers: [],
})
export class SelectTimeModule {}
