<div class="row d-flex flex-wrap justify-content-center">
    <div
        *ngFor="let card of venue; let i = index"
        class="city-card mx-1 px-2 mb-3"
        [ngClass]="{ 'd-none d-md-block': activeImage !== i }"
    >
        <mlp-ng-card-plain [card]="card">
            <a [routerLink]="card.cardButton.link">
                <mlp-ng-button-atom
                    [label]="card.cardButton.title"
                    btnStyle="secondary"
                    btnSize="small"
                ></mlp-ng-button-atom>
            </a>
        </mlp-ng-card-plain>
    </div>
</div>

<div class="text-center d-md-none mb-4">
    <mlp-ng-shared-prev-next
        [max]="venue.length"
        (visible)="isVisible($event)"
    ></mlp-ng-shared-prev-next>
</div>
