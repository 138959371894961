<div class="row">
    <section
        class="col-md-4 sg-mb-2"
        *ngFor="let blog of blogs; let i = index"
        [ngClass]="{ 'd-none d-md-block': activeImage !== i }"
    >
        <a [href]="blog.link" class="">
            <img
                [attr.data-src]="blog.featured_image"
                class="card-img-top sg-mb-1 lazyload"
                [alt]="blog.title"
                imgService="w=510"
            />
        </a>

        <h3 class="sg-heading__h4 sg-color__primary">
            <a [href]="blog.link" [title]="blog.title">{{ blog.title }}</a>
        </h3>

        <div class="d-flex d-flex justify-content-start mt-1 sg-mb-1">
            <div class="sg-divider__short"></div>
        </div>

        <div class="sg-subheading__h4 mb-4">
            {{ blog.meta_description }}
        </div>

        <div class="sg-subheading__h4 mb-4">
            {{ blog.date | date: "mediumDate" }}
        </div>

        <div>
            <a [href]="blog.link" class="sg-link font-weight-bold">Read more</a>
        </div>
    </section>
</div>

<div class="sg-mb-2 text-center d-md-none">
    <mlp-ng-button-nav
        side="left"
        (click)="previousImage()"
    ></mlp-ng-button-nav>
    <mlp-ng-button-nav side="right" (click)="nextImage()"></mlp-ng-button-nav>
</div>
