import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

import { CitySelectService } from "../shared/city-select.service";

@Component({
    selector: "pf-not-found",
    templateUrl: "not-found.component.html",
})
export class NotFoundComponent implements OnInit {
    constructor(
        private _meta: Meta,
        private _title: Title,
        public citySelectService: CitySelectService
    ) {}

    ngOnInit(): void {
        const title = "Playfinder | 404 Page not found!";

        if (this._title.getTitle() !== title) {
            this._title.setTitle(title);
        }

        this._meta.updateTag({ name: "description", content: "404" });
        this._meta.updateTag({ name: "keywords", content: "404" });
    }
}
