import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    ButtonNavSharedModule,
    ImgServiceDirectiveModule,
} from "pf_styleguide";
import { BlogService } from "../../blog.service";

import { BlogsSharedComponent } from "./blogs.component";

@NgModule({
    declarations: [BlogsSharedComponent],
    imports: [CommonModule, ButtonNavSharedModule, ImgServiceDirectiveModule],
    exports: [BlogsSharedComponent],
    providers: [BlogService],
})
export class BlogsSharedModule {}
