<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center sg-mt-1">
                <h1 class="sg-heading__h1 text-uppercase mb-3">
                    404 page not found
                </h1>
                <p class="sg-subheading__h1 sg-mb-1">
                    Looks like you've missed the target!
                </p>
                <img
                    class="sg-mb-1"
                    src="/ng-assets/not-found-guy.png"
                    height="120"
                    alt="404 fail man image"
                />
                <p class="sg-mb-1 sg-heading__h3 text-uppercase">
                    Have another shot:
                </p>
                <p class="sg-mb-1">
                    <a class="sg-link" href="/" [routerLink]="'/'">Homepage</a>
                </p>
                <p class="sg-mb-1 sg-heading__h3 text-uppercase">
                    Find and book sports facilities in:
                </p>
                <ul class="list-unstyled sg-mb-2">
                    <li
                        *ngFor="let city of citySelectService.cities | async"
                        class="d-xs-block mr-md-5 d-md-inline-block justify-content-center align-items-center"
                    >
                        <a class="sg-link" [routerLink]="['/', city.slug]">{{
                            city.name
                        }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
