import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { config } from "./app-config";
import { AppConfigInterface } from "./app-config.interface";
import { ConfigureParamsInterface } from "./configure.params.interface";

@Injectable()
export class ConfigureService {
    params: ConfigureParamsInterface;
    paramsLoadedSubject: BehaviorSubject<ConfigureParamsInterface> =
        new BehaviorSubject(undefined);
    config: AppConfigInterface = config;

    private isBrowser: boolean = isPlatformBrowser(this.platformId);

    constructor(
        private http: HttpClient,
        @Inject(PLATFORM_ID) private platformId
    ) {}

    load(): Promise<any> {
        return this.http
            .get(this.getConfigFile())
            .toPromise()
            .then((params: any) => {
                this.params = params;
                this.paramsLoadedSubject.next(params);
            });
    }

    private getConfigFile(): string {
        let url = `config.json?t=${this.uniqueId()}`;

        if (!this.isBrowser) {
            url = `http://localhost:4000/${url}`;
        }

        return url;
    }

    // tslint:disable-next-line:prefer-function-over-method
    private uniqueId(): string {
        let text = "";
        const possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 5; i++) {
            text += possible.charAt(
                Math.floor(Math.random() * possible.length)
            );
        }

        return text;
    }
}
