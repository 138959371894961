<div class="container">
    <div class="row">
        <div class="col-12 text-center pt-3">
            <h1 class="sg-heading--secondary mb-3">
                This page no longer exists
            </h1>
            <p class="mb-0">
                <strong
                    >Playfinder no longer lists facilities for
                    {{ sport }}.</strong
                >
            </p>
            <p
                class="mb-1"
                [innerHtml]="discontinuedData[sport].description"
            ></p>

            <div
                class="icon icon-mlp_{{ sport }} sport__icon"
                style="font-size: 120px"
            ></div>

            <p>
                Alternatively return to our homepage to book facilities for
                other sports:
            </p>
            <p><a [routerLink]="['/']" class="sg-color">Homepage</a></p>
            <p>Find and book sports facilities in:</p>
            <div class="d-flex flex-wrap justify-content-center mb-3">
                <a
                    [routerLink]="['/', city.slug]"
                    *ngFor="let city of cityService.cities | async"
                    class="mb-2 mr-2"
                    >{{ city.name }}</a
                >
            </div>
        </div>
    </div>
</div>
