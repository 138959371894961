import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonNavSharedModule } from "pf_styleguide";

import { PrevNextSharedComponent } from "./prev.next.btn.component";

@NgModule({
    declarations: [PrevNextSharedComponent],
    imports: [CommonModule, ButtonNavSharedModule],
    exports: [PrevNextSharedComponent],
    providers: [],
})
export class PrevNextSharedModule {}
