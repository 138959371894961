<div [formGroup]="formGroup" class="position-relative">
    <mlp-ng-input
        formControlName="input"
        placeholder="Insert a location or a venue"
        inputStyle="secondary"
        [inputId]="uniqueInputId"
        icon="style-two-pin-marker"
        iconSecondary="compass-arrow"
        *ngIf="!venue"
        (keydown)="keyboardControl($event)"
        (focus)="focusInput()"
        (secondaryButtonClick)="getLocation()"
        [errors]="
            input.dirty && !input.valid
                ? [{ title: 'Please specify a location or venue' }]
                : []
        "
        [loading]="gettingLocation"
        loadingSpinner="/ng-assets/styleguide/svg/spinner.svg"
    ></mlp-ng-input>

    <div
        class="autocomplete sg-rounded"
        *ngIf="googleResults.length > 0 || venueResults.length > 0"
        (ngxClickOutside)="close()"
    >
        <ul class="list-group mb-0">
            <li
                class="list-group-item list-group-item-action autocomplete__item px-0 py-0"
                *ngFor="
                    let result of googleResults | slice: 0:3;
                    let index = index
                "
                [class.active-item]="selection === index"
                (mouseenter)="selection = index"
                (click)="openFromGooglePlace(result)"
            >
                <div class="d-flex flex-row">
                    <div class="col-xs-2 px-2 text-center icon__container">
                        <mlp-ng-icon name="style-two-pin-marker"></mlp-ng-icon>
                    </div>

                    <div
                        class="col-xs-10 pl-0 pr-1 autocomplete__relative text-left"
                    >
                        <p class="mb-0 font size12 autocomplete__ellipsis">
                            {{ result.structured_formatting.main_text }}
                        </p>

                        <p class="sub">
                            <sub class="font size10 text-capitalize">{{
                                result.structured_formatting.secondary_text
                            }}</sub>
                        </p>
                    </div>
                </div>
                <span class="clearfix"></span>
            </li>

            <li
                class="list-group-item list-group-item-action autocomplete__item px-0 py-0"
                *ngFor="let venue of venueResults; let index = index"
                [class.active-item]="
                    selection === index + (googleResults | slice: 0:3).length
                "
                (mouseenter)="
                    selection =
                        index +
                        (googleResults.length <= 3 ? googleResults.length : 3)
                "
                (click)="openFromVenue(venue)"
            >
                <div class="d-flex flex-row">
                    <div
                        class="col-xs-2 pl-0 pr-0 text-center icon__container sg-color__secondary"
                    >
                        <mlp-ng-icon name="football"></mlp-ng-icon>
                    </div>

                    <div
                        class="col-xs-10 pl-0 pr-1 autocomplete__relative text-left font-black"
                    >
                        <p class="mb-0 font size12 autocomplete__ellipsis">
                            {{ venue.attributes.name }}
                        </p>

                        <p class="sub">
                            <sub class="font size10 text-capitalize">{{
                                sport ? sport.attributes.name : ""
                            }}</sub>
                        </p>
                    </div>
                </div>
            </li>

            <li
                class="list-group-item list-group-item-action px-2 py-0 autocomplete__close text-left small"
                [class.active-item]="
                    selection ===
                    (googleResults | slice: 0:3).length +
                        (venueResults | slice: 0:3).length
                "
                (mouseenter)="
                    selection =
                        (googleResults.length <= 3 ? googleResults.length : 3) +
                        (venueResults.length <= 3 ? venueResults.length : 3)
                "
                (click)="close()"
            >
                Close
            </li>
        </ul>
    </div>

    <section class="selected-result sg-rounded" *ngIf="venue">
        <mlp-ng-chip
            class="d-block mb-3"
            [data]="{ name: venue.attributes.name, icon: sport.id }"
            (remove)="removeVenue()"
            *ngIf="venue"
        >
        </mlp-ng-chip>

        <div class="selected-result__list" *ngIf="pitches.length">
            <mlp-ng-radio
                class="col"
                formControlName="pitch"
                name="pitch"
                [radioValue]="pitch"
                *ngFor="let pitch of pitches"
                >{{ pitch.attributes.format }}
                <span *ngIf="pitch.attributes.surface !== 'n/a'">|</span>
                <span
                    class="text-capitalize"
                    *ngIf="pitch.attributes.surface !== 'n/a'"
                    >{{ pitch.attributes.surface }}</span
                ></mlp-ng-radio
            >
        </div>
    </section>

    <div
        class="search-by-time sg-color mt-3 mb-2 mt-lg-0 pt-lg-2 py-3 px-3"
        [ngClass]="{
            'mt-lg-0 pt-lg-2 mx-lg-3 px-5 px-sm-3 mx-n5 mx-sm-0':
                layout === 'horizontal'
        }"
        *ngIf="sbt"
    >
        <p class="mb-2 font size14">
            <strong>Great!</strong>
            You are now able to search by date and time
        </p>

        <div class="row">
            <mlp-select-date
                class="col-7 col-sm-6 pr-1"
                formControlName="date"
                maxDateDays="30"
            ></mlp-select-date>
            <span
                class="d-none d-sm-inline-flex col-1 pt-2 mt-1 px-0 font size14 text-right"
                >From</span
            >
            <mlp-select-time
                class="col-5 pl-1"
                formControlName="time"
            ></mlp-select-time>
        </div>
    </div>
</div>
