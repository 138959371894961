import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
    ButtonSharedModule,
    IconSharedModule,
    ImageSharedModule,
} from "pf_styleguide";
import { PartnerBannerComponent } from "./partner-banner.component";
import { ItemComponent } from "./item.component";

@NgModule({
    imports: [
        CommonModule,

        ButtonSharedModule,
        ImageSharedModule,
        IconSharedModule,
    ],
    declarations: [PartnerBannerComponent, ItemComponent],
    exports: [PartnerBannerComponent],
    providers: [],
})
export class PartnerBannerModule {}
