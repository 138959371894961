import { Component, ElementRef, ViewChild } from "@angular/core";

import { ModalService } from "pf_styleguide";

import { AuthService } from "../auth.service";
import { USERLINKS } from "./../../../../user/user.links";

@Component({
    selector: "pf-sign-in-modal",
    templateUrl: "sign-in-modal.component.html",
})
export class SignInModalComponent {
    @ViewChild("signInContent") signInContent: ElementRef;
    success = false;
    dashboardLink = USERLINKS.nav.mainLinks[0].url;

    constructor(
        private authService: AuthService,
        private modalService: ModalService
    ) {}

    switchModal(): void {
        this.authService.openSignUpEvent.emit();
    }

    open(content): void {
        this.reset();
        this.signInContent = content;
        this.modalService.open(this.signInContent, {
            windowClass: "sg-modal-secondary -title-left",
        });
    }

    loginSuccess($event): void {
        this.success = $event;
    }

    private reset(): void {
        this.success = false;
    }
}
