import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FooterSharedModule, SocialBannerSharedModule } from "pf_styleguide";

import { FooterComponent } from "./footer.component";

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, SocialBannerSharedModule, FooterSharedModule],
    exports: [FooterComponent],
})
export class FooterModule {}
