<div class="row">
    <div class="col-sm-4 text-center">
        <mlp-ng-icon name="search" size="large"></mlp-ng-icon>
        <h3 class="sg-heading__h3 text-uppercase mt-4">Find</h3>
        <div class="d-flex d-flex justify-content-center mt-1 sg-mb-1">
            <div class="sg-divider__short"></div>
        </div>
        <p class="sg-mb-1 sg-mb-md-2">{{ steps?.discover }}</p>
    </div>

    <div class="col-sm-4 text-center">
        <mlp-ng-icon name="calendar-1" size="large"></mlp-ng-icon>
        <h3 class="sg-heading__h3 text-uppercase mt-4">Book</h3>
        <div class="d-flex d-flex justify-content-center mt-1 sg-mb-1">
            <div class="sg-divider__short"></div>
        </div>
        <p class="sg-mb-1 sg-mb-md-2">{{ steps?.book }}</p>
    </div>

    <div class="col-sm-4 text-center">
        <mlp-ng-icon name="custom-sports" size="large"></mlp-ng-icon>
        <h3 class="sg-heading__h3 text-uppercase mt-4">Play</h3>
        <div class="d-flex d-flex justify-content-center mt-1 sg-mb-1">
            <div class="sg-divider__short"></div>
        </div>
        <p class="sg-mb-1 sg-mb-md-2">{{ steps?.play }}</p>
    </div>
</div>
