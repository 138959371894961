import * as i0 from "@angular/core";
var DateService = /** @class */ (function () {
    function DateService() {
    }
    DateService.prototype.prependZero = function (data) {
        return ("0" + data).slice(-2);
    };
    DateService.prototype.addHours = function (date, hours) {
        if (hours === void 0) { hours = 3; }
        return new Date(date.setTime(date.getTime() + hours * 60 * 60 * 1000));
    };
    DateService.prototype.createFilterDate = function (year, month, day) {
        var updateMonth = this.prependZero(month);
        var updateDay = this.prependZero(day);
        return year + "-" + updateMonth + "-" + updateDay;
    };
    DateService.prototype.createFilterTime = function (hours, minutes) {
        var updateHours = this.prependZero(hours);
        var updateMinutes = this.prependZero(minutes);
        return updateHours + ":" + updateMinutes;
    };
    DateService.prototype.createFullDateObject = function (date, timeData) {
        var time = { hour: 0, minute: 0, second: 0 };
        if (typeof timeData === "object") {
            time = timeData;
            time.second = 0;
        }
        if (typeof date === "string") {
            time = this.formatTimeString(date);
            date = this.formatDateString(date);
        }
        return new Date(date.year, date.month, date.day, time.hour, time.minute, time.second);
    };
    /**
     * String format 2017-08-08 04:00
     * The space in between date and time is important and order.
     */
    DateService.prototype.formatDateString = function (sentDate, toDateObj) {
        if (toDateObj === void 0) { toDateObj = true; }
        var splitDate = sentDate.split(" ");
        var dateHolder = splitDate[0];
        var dateArray = dateHolder.split("-");
        var date = {
            year: parseInt(dateArray[0], 10),
            month: toDateObj
                ? parseInt(dateArray[1], 10) - 1
                : parseInt(dateArray[1], 10),
            day: parseInt(dateArray[2], 10),
        };
        return date;
    };
    DateService.prototype.formatTimeString = function (date) {
        var splitTime = date.split(" ")[1].split(":");
        var time = {
            hour: parseInt(splitTime[0], 10),
            minute: parseInt(splitTime[1], 10),
            second: 0,
        };
        return time;
    };
    DateService.prototype.fullDateSearchFormat = function (date) {
        var newDate = this.createFilterDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
        var newTime = this.createFilterTime(date.getHours(), date.getMinutes());
        return newDate + " " + newTime;
    };
    DateService.prototype.getTime = function (date) {
        return date.getTime();
    };
    DateService.prototype.sameDay = function (d1, d2) {
        return (d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate());
    };
    DateService.prototype.now = function () {
        return new Date();
    };
    DateService.prototype.createTimeRange = function (hourOnly) {
        if (hourOnly === void 0) { hourOnly = false; }
        var data = [];
        var val;
        for (var v = 7; v < 24; v++) {
            if (hourOnly)
                val = ("0" + v).slice(-2);
            if (!hourOnly)
                val = ("0" + v + ":00").slice(-5);
            data.push(val);
        }
        return data;
    };
    DateService.ngInjectableDef = i0.defineInjectable({ factory: function DateService_Factory() { return new DateService(); }, token: DateService, providedIn: "root" });
    return DateService;
}());
export { DateService };
