/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sign-in-modal/sign-in-modal.component.ngfactory";
import * as i2 from "./sign-in-modal/sign-in-modal.component";
import * as i3 from "./auth.service";
import * as i4 from "pf_styleguide";
import * as i5 from "./sign-up-modal/sign-up-modal.component.ngfactory";
import * as i6 from "./sign-up-modal/sign-up-modal.component";
import * as i7 from "./auth.component";
import * as i8 from "@angular/router";
var styles_AuthComponent = [];
var RenderType_AuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { signUpModalComponent: 0 }), i0.ɵqud(402653184, 2, { signInModalComponent: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 1, "pf-sign-in-modal", [], null, null, null, i1.View_SignInModalComponent_0, i1.RenderType_SignInModalComponent)), i0.ɵdid(3, 49152, [[2, 4], ["signIn", 4]], 0, i2.SignInModalComponent, [i3.AuthService, i4.ModalService], null, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "pf-sign-up-modal", [], null, null, null, i5.View_SignUpModalComponent_0, i5.RenderType_SignUpModalComponent)), i0.ɵdid(6, 49152, [[1, 4], ["signUp", 4]], 0, i6.SignUpModalComponent, [i3.AuthService, i4.ModalService], null, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_AuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i0.ɵdid(1, 49152, null, 0, i7.AuthComponent, [i8.ActivatedRoute, i3.AuthService], null, null)], null, null); }
var AuthComponentNgFactory = i0.ɵccf("mlp-auth", i7.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
