import { Component } from "@angular/core";
import { IAppBanner } from "pf_styleguide";
import { MlpHelper } from "../../mlpHelper";

@Component({
    selector: "pf-app-banner",
    templateUrl: "./app-banner.component.html",
})
export class AppBannerComponent {
    backgroundImage =
        "/ng-assets/recreate-background-banner-app.jpg?w=1490&" +
        MlpHelper.imageServiceDefaultParams();
    appBanner: IAppBanner = {
        heading: "Playfinder",
        subheading: "Mobile APP",
        list: [
            "Instantly book sports facilities across the UK",
            "Split Payments with your friends using PaySplit",
            "Keep track of upcoming bookings",
            "Favorite nearby venues for easy booking",
        ],
    };
}
