import { NgModule } from "@angular/core";
import { AppBannerSharedModule } from "pf_styleguide";

import { AppLinkPipesModule } from "../pipe/app-link.module";
import { AppBannerComponent } from "./app-banner.component";

@NgModule({
    declarations: [AppBannerComponent],
    imports: [AppLinkPipesModule, AppBannerSharedModule],
    exports: [AppBannerComponent],
    providers: [],
})
export class AppBannerModule {}
