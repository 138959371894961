import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

import { CookieService } from "ngx-cookie";
import { ButtonSharedModule, ModalSharedModule } from "pf_styleguide";

import { ErrorsModule } from "../errors/errors.module";
import { AuthComponent } from "./auth.component";
import { SignInModalComponent } from "./sign-in-modal/sign-in-modal.component";
import { SignInModule } from "./sign-in/sign-in.module";
import { SignUpModalComponent } from "./sign-up-modal/sign-up-modal.component";
import { SignUpModule } from "./sign-up/sign-up.module";

@NgModule({
    declarations: [AuthComponent, SignInModalComponent, SignUpModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ModalSharedModule,

        ButtonSharedModule,
        ErrorsModule,
        SignUpModule,
        SignInModule,
        NgbModalModule, // do not remove this as it will break if removed after aot build completes
    ],
    exports: [AuthComponent],
    providers: [CookieService],
})
export class AuthModule {}
