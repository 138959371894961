import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PartnerBannerStatic } from "./partner-banner.static";

@Component({
    selector: "mlp-partner-banner",
    templateUrl: "./partner-banner.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerBannerComponent {
    data: PartnerBannerStatic = {
        title: "PLAYFINDER for Sports Venue Operators",
        description:
            "Whether you're a council, school, community group or leisure centre, becoming a Playfinder Partner can benefit your sports venue in number of ways ",
        items: [
            {
                icon: "flash",
                title: "Online Bookings",
                description:
                    "We enable venues to take online bookings, resulting in increased booking conversion rates and revenue",
            },
            {
                icon: "megaphone",
                title: "Marketing Expertise",
                description:
                    "Benefit from Playfinder marketing campaigns giving your venue increased exposure",
            },
            {
                icon: "headphones-customer-support-human-1",
                title: "Operational Support",
                description:
                    "We manage all email and phone enquiries, as well as invoices and block booking waiting lists",
            },
        ],
    };
}
