/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../sign-up/sign-up.component.ngfactory";
import * as i2 from "../sign-up/sign-up.component";
import * as i3 from "../auth.service";
import * as i4 from "../../../eventTracking.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/router";
import * as i7 from "../../../generic.service";
import * as i8 from "../../../../configure/configure.service";
import * as i9 from "../../../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i10 from "pf_styleguide";
import * as i11 from "@angular/common";
import * as i12 from "./sign-up-modal.component";
var styles_SignUpModalComponent = [];
var RenderType_SignUpModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignUpModalComponent, data: {} });
export { RenderType_SignUpModalComponent as RenderType_SignUpModalComponent };
function View_SignUpModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pf-sign-up", [], null, [[null, "closeModal"], [null, "showSignIn"], [null, "loginSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_v.parent.context.close() !== false);
        ad = (pd_0 && ad);
    } if (("showSignIn" === en)) {
        _v.parent.context.close();
        _co.switchModal();
        var pd_1 = (false !== false);
        ad = (pd_1 && ad);
    } if (("loginSuccess" === en)) {
        var pd_2 = (_co.loginSuccess($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_SignUpComponent_0, i1.RenderType_SignUpComponent)), i0.ɵdid(1, 114688, null, 0, i2.SignUpComponent, [i3.AuthService, i4.EventTrackingService, i5.FormBuilder, i6.Router, i7.GenericService, i8.ConfigureService], null, { showSignIn: "showSignIn", loginSuccess: "loginSuccess" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SignUpModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["class", "mb-3 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    Thanks for signing up. You are now logged in.\n                "])), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "mlp-ng-button", [["btnType", "submit"], ["fullWidth", "1"], ["label", "Book Sports Facilities!"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ButtonSharedComponent_0, i9.RenderType_ButtonSharedComponent)), i0.ɵdid(8, 49152, null, 0, i10.ButtonSharedComponent, [], { btnType: [0, "btnType"], fullWidth: [1, "fullWidth"], label: [2, "label"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var currVal_0 = "submit"; var currVal_1 = "1"; var currVal_2 = "Book Sports Facilities!"; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SignUpModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 15, "mlp-ng-modal", [], null, null, null, i9.View_ModalSharedComponent_0, i9.RenderType_ModalSharedComponent)), i0.ɵdid(2, 49152, null, 0, i10.ModalSharedComponent, [i10.ModalService], null, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "section", [["class", "sg-heading__h5"], ["header", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Welcome to Playfinder"])), (_l()(), i0.ɵted(-1, null, ["\n\n        "])), (_l()(), i0.ɵeld(7, 0, null, 1, 8, "section", [["body", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignUpModalComponent_2)), i0.ɵdid(10, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n            "])), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SignUpModalComponent_3)), i0.ɵdid(14, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.success; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.success; _ck(_v, 14, 0, currVal_1); }, null); }
export function View_SignUpModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { signUpContent: 0 }), (_l()(), i0.ɵand(0, [[1, 2], ["signUpContent", 2]], null, 0, null, View_SignUpModalComponent_1)), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_SignUpModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pf-sign-up-modal", [], null, null, null, View_SignUpModalComponent_0, RenderType_SignUpModalComponent)), i0.ɵdid(1, 49152, null, 0, i12.SignUpModalComponent, [i3.AuthService, i10.ModalService], null, null)], null, null); }
var SignUpModalComponentNgFactory = i0.ɵccf("pf-sign-up-modal", i12.SignUpModalComponent, View_SignUpModalComponent_Host_0, {}, {}, []);
export { SignUpModalComponentNgFactory as SignUpModalComponentNgFactory };
