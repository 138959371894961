import { ActivatedRoute } from "@angular/router";
import { CitySelectService } from "../shared/city-select.service";
import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { discontinuedData } from "./discontinued-sport-data.static";

@Component({
    selector: "mlp-discontinued-sport",
    templateUrl: "discontinued-sport.component.html",
})
export class DiscontinuedSportComponent implements OnInit {
    sport: string;
    discontinuedData = discontinuedData;

    constructor(
        private _meta: Meta,
        private _title: Title,
        private activatedRoute: ActivatedRoute,
        public cityService: CitySelectService
    ) {}

    ngOnInit() {
        this.activatedRoute.data.subscribe((data) => {
            this.sport = data["sport"];
        });

        const title = "Playfinder | 404 Page not found!";

        if (this._title.getTitle() !== title) {
            this._title.setTitle(title);
        }

        this._meta.updateTag({ name: "description", content: "404" });
        this._meta.updateTag({ name: "keywords", content: "404" });
    }
}
