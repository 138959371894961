import { Component, Input } from "@angular/core";

@Component({
    selector: "pf-process",
    templateUrl: "./process.component.html",
    styleUrls: ["./process.component.scss"],
})
export class ProcessComponent {
    @Input() sport: string;
}
