import { HttpClient} from '@angular/common/http';
import { SocialUser } from 'angular-6-social-login';
import { LoginProvider } from "angular-6-social-login/entities/login-provider";
import { v1 as uuidV1 } from 'uuid';
export class GoogleNewLoginProvider implements LoginProvider {
    public static readonly PROVIDER_ID: string = 'GOOGLE';
    public oAuthWindow: any;
    public sess: number;

    constructor(
        private clientId: string,
        private http: HttpClient,
        private apiClientUri: string,
    ) {
    }

    public initialize(): Promise<SocialUser> {
        this.sess = uuidV1();
        return new Promise((resolve, _reject) => {});
    }

    public async signIn(signInOptions?: any): Promise<SocialUser> {
        return new Promise<SocialUser>((resolve, reject) => {
            this.oAuthWindow = window.open(
              `${this.apiClientUri}/login_google_oauth?sess=${this.sess}`,
              '_blank'
            );
            this.oAuthWindow.blur();
            window.focus();
            try {
                let user = new SocialUser();
                setTimeout(() => {
                    const request = this.http.get(`${this.apiClientUri}/login_google_oauth?sess=${this.sess}`).subscribe(
                      (data: any) => {
                          user.idToken = data.token;
                          user.token = data.token;
                          resolve(user);
                      }
                    );
                }, 4000);
            } catch (er) {
                reject('Please complete the Google signin process to sign in via Google.');
            }
        });
    }

    public signOut(revoke?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            // AppleID doesnt have revoke method
            resolve();
        });
    }

    protected loadScript(
        id: string,
        src: string,
        onload: any,
        parentElement = null
    ): void {
    }
}
