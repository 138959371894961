import { Angulartics2 } from "angulartics2";
import { MlpHelper } from "./mlpHelper";
import { MinutesToTextPipe } from "../pipe/minutes-to-text.pipe";
import format from "date-fns/format";
import parse from "date-fns/parse";
import diffInMinutes from "date-fns/differenceInMinutes";
import { SportFormatSurfaceTextPipe } from "../pipe/sport-format-surface-text.pipe";
import { BrowserService } from "./browser.service";
import * as i0 from "@angular/core";
import * as i1 from "angulartics2";
import * as i2 from "./browser.service";
var EventTrackingService = /** @class */ (function () {
    function EventTrackingService(angulartics2, browserService) {
        this.angulartics2 = angulartics2;
        this.browserService = browserService;
    }
    EventTrackingService.prototype.trackOptInMarketing = function (source, value) {
        this.angulartics2.eventTrack.next({
            action: "Opt-in Email Subscription",
            properties: {
                category: source,
                label: "" + value,
            },
        });
    };
    EventTrackingService.prototype.trackEnquiry = function (enquiry, pitch, city) {
        var category = "Enquiry sent MLP pitch";
        var action = "Enquiry sent MLP pitch";
        if (!pitch.data.attributes.mlp) {
            category = "Enquiry sent non MLP pitch";
            action = "Enquiry sent non MLP pitch";
        }
        var label = this.createLabelFromEnquiry(enquiry, pitch, city);
        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    };
    EventTrackingService.prototype.trackEnquiryBlockBookingConverted = function (enquiry, pitch, city) {
        var category = "Enquiry sent MLP pitch";
        var action = "Enquiry sent MLP pitch - Convert to Block from Checkout";
        if (!pitch.data.attributes.mlp) {
            category = "Enquiry sent non MLP pitch";
            action =
                "Enquiry sent non MLP pitch - Convert to Block from Checkout";
        }
        var label = this.createLabelFromEnquiry(enquiry, pitch, city);
        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    };
    /**
     * CITY | Google place | searchTerm
     *
     * @param googlePlace
     * @param searchTerm
     */
    EventTrackingService.prototype.trackAutoCompleteGooglePlace = function (googlePlace, searchTerm) {
        var category = "General - Autocomplete";
        var action = "Google places suggestion";
        var label = googlePlace + " | " + searchTerm;
        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    };
    /**
     *
     * __example__
     * trackAuto('Category', 'action', 'label')
     *
     * CITY | Venue name | searchTerm
     * @param action
     * @param category
     * @param label
     */
    EventTrackingService.prototype.trackEvent = function (category, action, label) {
        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    };
    /**
     * CITY | Venue name | searchTerm
     * @param venueName
     * @param searchTerm
     */
    EventTrackingService.prototype.trackAutoCompleteVenueSuggestion = function (venueName, searchTerm) {
        var category = "General - Autocomplete";
        var action = "MLP venue suggestion";
        var label = venueName + " | " + searchTerm;
        this.angulartics2.eventTrack.next({
            action: action,
            properties: { category: category, label: label },
        });
    };
    /**
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD
     * @param slot
     * @param pitch
     * @param city
     * @param booking
     * @param finalPrice
     * @param discountData: undefined | DiscountData
     * @returns {string}
     */
    EventTrackingService.prototype.trackBooking = function (city, pitch, slot, booking, finalPrice, discountData) {
        var category = "Booking confirmed";
        var action = "Customer paid";
        var label = this.createLabelFromSlot(slot, pitch, city, discountData);
        gtag ? gtag('event', action, {
            category: category,
            label: label
        }) : null;
        fbq("track", "Purchase", {
            value: finalPrice,
            currency: slot.attributes.currency,
        });
    };
    /**
     *
     * @param action
     * @param label
     */
    EventTrackingService.prototype.trackFilters = function (action, label) {
        // Turn off tracking filters
        return;
    };
    /**
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD | Block booking: 10
     * @param enquiry
     * @param pitch
     * @param city
     * @returns {string}
     */
    EventTrackingService.prototype.createLabelFromEnquiry = function (enquiry, pitch, city) {
        var minutesToTextPipe = new MinutesToTextPipe();
        var sportFormatSurfacePipe = new SportFormatSurfaceTextPipe();
        var date = parse(enquiry.data.attributes.date + " " + enquiry.data.attributes.time, 'yyyy-MM-dd HH:mm:ss', new Date());
        var blockBooking = enquiry.data.attributes.block_booking.toLowerCase() == "yes"
            ? " | Block booking: " + enquiry.data.attributes.weeks
            : "";
        return (MlpHelper.uppercaseFirstLetter(city) +
            " | " +
            pitch.data.attributes.name +
            " | " +
            pitch.data.attributes.sport +
            " | " +
            sportFormatSurfacePipe.transform(pitch.data) +
            " | " +
            enquiry.data.attributes.time +
            " | " +
            minutesToTextPipe.transform(enquiry.data.attributes.duration) +
            " | " +
            format(date, "dddd") +
            " | " +
            enquiry.data.attributes.date +
            blockBooking);
    };
    /**
     * City | Venue Name | Sport | Format | Surface | 21:00 | Duration | Day of week | YYYY-MM-DD
     * @param slot
     * @param pitch
     * @param city
     * @param discountData: undefined | DiscountData
     * @returns {string}
     */
    EventTrackingService.prototype.createLabelFromSlot = function (slot, pitch, city, discountData) {
        var minutesToTextPipe = new MinutesToTextPipe();
        var sportFormatSurfacePipe = new SportFormatSurfaceTextPipe();
        var ends = new Date(slot.attributes.ends);
        var starts = new Date(slot.attributes.starts);
        var durationInMinutes = diffInMinutes(ends, starts);
        var promoCode = discountData ? " | Promo: " + discountData.code : "";
        return (MlpHelper.uppercaseFirstLetter(city) +
            " | " +
            pitch.data.attributes.name +
            " | " + // | Promo: WORLDCUP
            pitch.data.attributes.sport +
            " | " +
            sportFormatSurfacePipe.transform(pitch.data) +
            " | " +
            format(starts, "HH:mm") +
            " | " +
            minutesToTextPipe.transform(durationInMinutes) +
            " | " +
            format(starts, "dddd") +
            " | " +
            format(starts, "yyyy-MM-dd") +
            promoCode);
    };
    EventTrackingService.ngInjectableDef = i0.defineInjectable({ factory: function EventTrackingService_Factory() { return new EventTrackingService(i0.inject(i1.Angulartics2), i0.inject(i2.BrowserService)); }, token: EventTrackingService, providedIn: "root" });
    return EventTrackingService;
}());
export { EventTrackingService };
