import { Injectable } from "@angular/core";

import { CookieService } from "ngx-cookie";
import { MlpHelper } from "./mlpHelper";

@Injectable()
export class CookiePolicyService {
    hasAccepted = false;
    cookieName = "acceptCookie";

    constructor(private cookieService: CookieService) {}

    acceptCookies(): void {
        this.setCookie();
        this.hasAccepted = true;
    }

    checkCookie(): boolean {
        return !!this.cookieService.get(this.cookieName);
    }

    setCookie(): void {
        this.cookieService.put(
            this.cookieName,
            "true",
            MlpHelper.oneYearFromNow()
        );
    }
}
