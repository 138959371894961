import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";

import { CookieService } from "ngx-cookie";
import { BehaviorSubject, zip } from "rxjs";
import { filter, map } from "rxjs/operators";

import { HttpService } from "../../server/httpService";
import { BrowserService } from "./browser.service";
import { MlpHelper } from "./mlpHelper";
import { Cities } from "./model/cities";
import { City } from "./model/city";
import { ConfigureService } from "../configure/configure.service";

@Injectable({
    providedIn: "root",
})
export class CitySelectService {
    cityHolder: string;
    city: City;
    citiesStaticData: Array<City>;
    currentCity: BehaviorSubject<City> = new BehaviorSubject(null);
    cities: BehaviorSubject<Array<City>> = new BehaviorSubject([]);
    citiesMenu = new BehaviorSubject<any>(null);

    constructor(
        private readonly cookie: CookieService,
        private readonly httpService: HttpService,
        private readonly browserService: BrowserService,
        private readonly router: Router,
        private readonly rootRoute: ActivatedRoute,
        private readonly configService: ConfigureService,
    ) {
        this.configService.paramsLoadedSubject.pipe(filter((data) => !!data))
            .subscribe(() => {
                this.httpService
                        .get("/cities")
                        .pipe(map((cities: Cities) => this.filterCities(cities)))
                        .subscribe((cities) => {
                            this.cities.next(cities);
                        });

                this.router.events
                        .pipe(
                                filter((e) => e instanceof NavigationEnd),
                                map((e) => this.getParams(this.rootRoute))
                        )
                        .subscribe((params) => {
                            this.cityHolder = params.city;
                            this.setCurrentCity(params.city);
                        });

                zip(this.currentCity, this.cities).subscribe((res) => {
                    this.setCurrentCity(this.cityHolder);
                    this.setCityMenu(res[1]);
                });
            });
    }

    protected filterCities(cities: Cities): Array<City> {
        const citiesResult: Array<City> = [];
        cities.data.forEach((city) => {
            city.attributes.name =
                city.attributes.slug === "uk"
                    ? "Rest of the UK"
                    : city.attributes.name;
            citiesResult.push(city.attributes);
        });

        this.citiesStaticData = citiesResult;

        return citiesResult;
    }

    setCurrentCity(cityPassed: string): void {
        this.findSelectedCity(cityPassed);
    }

    private findSelectedCity(slug): void {
        this.cityHolder = slug;

        if (this.citiesStaticData && this.citiesStaticData.length && slug) {
            const citySelected = this.citiesStaticData.find(
                (city) => city.slug === slug
            );
            this.city = citySelected;
            this.currentCity.next(citySelected);
            this.setCityMenu(this.citiesStaticData);
        } else {
            this.currentCity.next(undefined);
        }
    }

    private setCityMenu(cities): void {
        const createCities = cities
            ? {
                  currentCity: this.city ? this.city.name : "Select city",
                  cities,
              }
            : null;

        this.citiesMenu.next(createCities);
    }

    // tslint:disable-next-line:prefer-function-over-method
    private getParams(route: ActivatedRoute): Params {
        // route param names (eg /a/:personId) must be ditinct within
        // a route otherwise they'll be overwritten
        let params = route.snapshot.params;
        params = {
            ...route.snapshot.queryParams,
            ...params,
            ...route.snapshot.data,
        };
        if (route.children) {
            for (const r of route.children) {
                params = { ...this.getParams(r), ...params };
            }
        }

        return params;
    }
}
