import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AuthServiceConfig,
    FacebookLoginProvider,
} from "angular-6-social-login";
import { AppleLoginProvider } from "./apple-login/apple.provider";
import { GoogleNewLoginProvider } from "./google-login/google.provider";

// tslint:disable-next-line:no-require-imports
const configs = require("./../../../../config.json");

@Injectable()
export class SocialAuthHelper extends AuthServiceConfig {

    constructor(private readonly http: HttpClient) {
        super([]);

        const providers = [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider(configs.facebookAppId),
            },
            {
                id: GoogleNewLoginProvider.PROVIDER_ID,
                provider: new GoogleNewLoginProvider(configs.googleAuthId, http, configs.apiClientUri),
            },
            {
                id: AppleLoginProvider.PROVIDER_ID,
                provider: new AppleLoginProvider(
                  configs.appleAppId,
                  {scope: 'email name'},
                  configs.canonicalDomain
                ),
            }
        ];
        this.providers = new Map();
        for (let i = 0; i < providers.length; i++) {
            const element = providers[i];
            this.providers.set(element.id, element.provider);
        }
    }


}
