import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { HeaderSharedComponent } from "pf_styleguide";
import { Observable } from "rxjs";

import { CitySelectService } from "../shared/city-select.service";
import { AuthService } from "../shared/module/auth/auth.service";
import { USERLINKS, USERVERTICALLINKS } from "./../user/user.links";
import { HeaderService } from "./header.service";

@Component({
    selector: "pf-header",
    templateUrl: "header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    phoneNumber: string;
    showPhone;
    USERLINKS = USERLINKS;
    USERVERTICALLINKS = USERVERTICALLINKS;
    @ViewChild(HeaderSharedComponent) header: HeaderSharedComponent;

    cities: Observable<{}>;

    links = [
        {
            link: "overview",
            label: "Dashboard",
        },
        {
            link: "bookings",
            label: "Bookings",
        },
        {
            link: "venues",
            label: "My Venues",
        },
        {
            link: "profile",
            label: "Profile",
        },
    ];

    trailing = {
        label: "List your venue",
        href: "https://www.bookteq.com/list-your-venue/",
    };

    constructor(
        public authService: AuthService,
        public headerService: HeaderService,
        public citySelectService: CitySelectService,
        private router: Router
    ) {
        this.citySelectService.currentCity.subscribe((city) => {
            if (city && city.slug && city.meta) {
                this.phoneNumber = city.meta.telDisplay;
            }
        });
    }

    ngOnInit(): void {
        this.headerService.showPhoneObservable.subscribe(
            (res) => (this.showPhone = res)
        );
    }

    logIn(): void {
        this.authService.openSignInEvent.emit();

        this.close();
    }

    signUp(): void {
        this.authService.openSignUpEvent.emit();

        this.close();
    }

    logout(): void {
        this.router.navigate([], {
            queryParams: { popup: null },
            queryParamsHandling: "merge",
        });
        this.authService.logout();

        this.close();
    }

    close(): void {
        this.header.closeSearchMobileNav();
    }
}
