import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppModule } from "./app.module";
import { AppComponent } from "./app.component";
import {
    BrowserModule,
    BrowserTransferStateModule,
} from "@angular/platform-browser";

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: "playfinder" }),
        BrowserTransferStateModule,
        AppModule,
    ],
    bootstrap: [AppComponent],
})
export class AppBrowserModule {}
