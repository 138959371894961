import { ISubNavVerticalCollection } from "pf_styleguide";
import { ISubSection } from "pf_styleguide/src/patterns/organisms/sub-section/sub-section.interface";

export const SECTION = "user";

export const USERSECTIONS = {
    overview: "overview",
    bookings: "bookings",
    venues: "venues",
    profile: "profile",
};

export const PROFILESECTIONS = {
    personal: "personal",
    sport: "favourite-sport",
    password: "password",
    email: "email",
    marketing: "marketing",
    payments: "payments",
};

export const USERLINKS: ISubSection = {
    title: "",
    nav: {
        mainLinks: [
            { name: "Dashboard", url: `/${SECTION}/${USERSECTIONS.overview}` },
            { name: "Bookings", url: `/${SECTION}/${USERSECTIONS.bookings}` },
            { name: "My Venues", url: `/${SECTION}/${USERSECTIONS.venues}` },
            { name: "Profile", url: `/${SECTION}/${USERSECTIONS.profile}` },
        ],
    },
};

export const USERVERTICALLINKS: ISubNavVerticalCollection = {
    data: [
        {
            name: "Personal Info",
            url: `/${SECTION}/${USERSECTIONS.profile}/${PROFILESECTIONS.personal}`,
        },
        {
            name: "Favourite Sport",
            url: `/${SECTION}/${USERSECTIONS.profile}/${PROFILESECTIONS.sport}`,
        },
        {
            name: "Password",
            url: `/${SECTION}/${USERSECTIONS.profile}/${PROFILESECTIONS.password}`,
        },
        {
            name: "Account Settings",
            url: `/${SECTION}/${USERSECTIONS.profile}/${PROFILESECTIONS.email}`,
        },
        {
            name: "Email preferences",
            url: `/${SECTION}/${USERSECTIONS.profile}/${PROFILESECTIONS.marketing}`,
        },
        {
            name: "Payment Methods",
            url: `/${SECTION}/${USERSECTIONS.profile}/${PROFILESECTIONS.payments}`,
        },
    ],
};
