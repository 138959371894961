import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import {
    ButtonSharedModule,
    CardSharedModule,
    IconSharedModule,
} from "pf_styleguide";

import { PartnerBannerModule } from "../partner/partials/banner/partner-banner.module";
import { ApiEndpoints } from "../shared/apiEndpoints";
import { LinkService } from "../shared/link.service";
import { LocationSelectModule } from "../shared/location-select/location-select.module";
import { AppBannerModule } from "../shared/module/app-banner/app-banner.module";
import { BlogsSharedModule } from "../shared/module/blogs/blogs.module";
import { BookingProcessSharedModule } from "../shared/module/booking-process/booking.process.module";
import { ImageTextSharedModule } from "../shared/module/image-text/image.text.module";
import { PrevNextSharedModule } from "../shared/module/prev-next-btn/prev.next.module";
import { StatsSharedModule } from "../shared/module/stats/stats.module";
import { SportsIconsModule } from "../shared/sports-icons/sports-icons.module";
import { CityVenueComponent } from "./city-venue/city.venue.component";
import { CityComponent } from "./city.component";
import { CityResolver } from "./city.resolver";

@NgModule({
    declarations: [CityComponent, CityVenueComponent],
    imports: [
        CommonModule,
        StatsSharedModule,
        BlogsSharedModule,
        BookingProcessSharedModule,
        ImageTextSharedModule,
        FormsModule,
        ReactiveFormsModule,
        PrevNextSharedModule,
        NgbDatepickerModule,
        PartnerBannerModule,
        RouterModule,
        AppBannerModule,
        IconSharedModule,
        CardSharedModule,
        ButtonSharedModule,
        LocationSelectModule,
        SportsIconsModule,
    ],
    providers: [LinkService, ApiEndpoints, CityResolver],
    exports: [],
})
export class CityModule {}
