import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

import { ISelectMolecule } from "pf_styleguide";
import { Observable, Subscription } from "rxjs";

import { CitySelectService } from "../shared/city-select.service";
import { CityTemplate } from "../shared/model/city.template.interface";
import { SportSelectService } from "../shared/sport-select.service";
import { ApiEndpoints } from "./../shared/apiEndpoints";
import { Sport } from "./../shared/model/sport";
import { City } from "../shared/model/city";

@Component({
    selector: "mlp-ng-city",
    templateUrl: "./city.component.html",
    styleUrls: ["./city.component.scss"],
})
export class CityComponent implements OnInit, OnDestroy {
    cityStatic: CityTemplate;
    city: City;
    sports: Observable<Array<Sport>>;
    subscription: void | Subscription;

    private subscribed: Subscription;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly meta: Meta,
        private readonly router: Router,
        private readonly title: Title,
        private readonly apiEndpoints: ApiEndpoints,
        public citySelectService: CitySelectService,
        public sportSelectService: SportSelectService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
                if (!data.general) {
                    return;
                }
                this.city = data.general;
                this.cityStatic = this.city.meta;
                this.sports = this.sportSelectService.getSportsAsync(this.city.slug);
                if (this.city.meta.slug) {
                    this.setMeta(this.cityStatic);
                }
            });
    }

    ngOnDestroy(): void {
        if (this.subscribed) {
            this.subscribed.unsubscribe();
        }
    }

    submit(formGroup: FormGroup): void {
        const sport = formGroup.controls.sport.value as ISelectMolecule;
        const location = formGroup.controls.location.value;

        if (formGroup.valid) {
            this.subscribed = this.citySelectService.currentCity.subscribe(
                (city) => {
                    if (city) {
                        this.router.navigate([
                            city.slug,
                            "results",
                            sport.data.id,
                            location,
                        ]);
                    }
                }
            );
        }
    }

    private setMeta(data: CityTemplate): void {
        this.title.setTitle(data.meta.title);
        this.meta.updateTag({
            name: "description",
            content: data.meta.description,
        });
        this.meta.updateTag({ name: "keywords", content: data.meta.keywords });
    }
}
