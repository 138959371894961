import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ImageTextSharedComponent } from "./image.text.component";

@NgModule({
    declarations: [ImageTextSharedComponent],
    imports: [CommonModule, RouterModule],
    exports: [ImageTextSharedComponent],
})
export class ImageTextSharedModule {}
