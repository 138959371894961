<div class="container-fluid mb-3 pb-3">
    <div class="container pt-3 mt-5">
        <div class="row">
            <div class="col-12">
                <h2 class="sg-heading__h3 text-center mb-3 pb-3 text-uppercase">
                    {{ data.title }}
                </h2>
                <h3 class="sg-subheading__h3 text-center mb-0">
                    {{ data.description }}
                </h3>
            </div>
        </div>
    </div>

    <div class="container mt-3 pt-3 mt-md-5">
        <div class="row">
            <div
                *ngFor="let item of data.items"
                class="col-12 col-md-4 d-flex align-items-start"
            >
                <div class="sg-px-1">
                    <mlp-item [item]="item"></mlp-item>
                </div>
            </div>
        </div>
    </div>
</div>
