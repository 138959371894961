/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/pf_styleguide/pf_styleguide.ngfactory";
import * as i2 from "pf_styleguide";
import * as i3 from "./app-banner.component";
var styles_AppBannerComponent = [];
var RenderType_AppBannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppBannerComponent, data: {} });
export { RenderType_AppBannerComponent as RenderType_AppBannerComponent };
export function View_AppBannerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mlp-ng-app-banner", [["appPreviewImage", "/ng-assets/phone-screens.png"], ["downloadAndroidImg", "/ng-assets/styleguide/banner/google-play-badge.svg"], ["downloadIOSImg", "/ng-assets/styleguide/banner/download-app.svg"]], null, null, null, i1.View_AppBannerSharedComponent_0, i1.RenderType_AppBannerSharedComponent)), i0.ɵdid(1, 49152, null, 0, i2.AppBannerSharedComponent, [], { appBanner: [0, "appBanner"], backgroundImage: [1, "backgroundImage"], appPreviewImage: [2, "appPreviewImage"], downloadAndroidImg: [3, "downloadAndroidImg"], downloadIOSImg: [4, "downloadIOSImg"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appBanner; var currVal_1 = _co.backgroundImage; var currVal_2 = "/ng-assets/phone-screens.png"; var currVal_3 = "/ng-assets/styleguide/banner/google-play-badge.svg"; var currVal_4 = "/ng-assets/styleguide/banner/download-app.svg"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AppBannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pf-app-banner", [], null, null, null, View_AppBannerComponent_0, RenderType_AppBannerComponent)), i0.ɵdid(1, 49152, null, 0, i3.AppBannerComponent, [], null, null)], null, null); }
var AppBannerComponentNgFactory = i0.ɵccf("pf-app-banner", i3.AppBannerComponent, View_AppBannerComponent_Host_0, {}, {}, []);
export { AppBannerComponentNgFactory as AppBannerComponentNgFactory };
