import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

import { AlertService } from "pf_styleguide";
import { tap } from "rxjs/operators";

import { AuthService } from "../auth.service";
import { EventTrackingService } from "./../../../../shared/eventTracking.service";
import { GenericService } from "./../../../../shared/generic.service";

@Component({
    selector: "mlp-social-login",
    templateUrl: "social-login.component.html",
})
export class SocialLoginComponent {
    @Input() socialPlatform: "facebook" | "google" | "apple";
    @Input() accessType: "Log In" | "Sign Up" = "Log In";
    @Input() trackevent = true;
    @Output() readonly loginSuccess = new EventEmitter();

    constructor(
        private authService: AuthService,
        private router: Router,
        private genericService: GenericService,
        private alertService: AlertService,
        private eventTrackingService: EventTrackingService
    ) {}

    socialSignIn(): void {
        this.authService
            .socialVerification(this.socialPlatform)
            .pipe(
                tap((_) => {
                    if (this.trackevent) {
                        this.eventTrackingService.trackEvent(
                            `Account ${this.accessType}`,
                            this.socialPlatform,
                            this.router.url
                        );
                    }
                })
            )
            .subscribe(
                (_) => {
                    this.loginSuccess.emit(true);
                },
                (errors) => {
                    errors.map((val) =>
                        this.alertService.setAlert({
                            message: val.title,
                            status: "danger",
                        })
                    );
                    this.loginSuccess.emit(false);
                    this.genericService.handleError(errors);
                }
            );
    }
}
