import { CommonModule } from "@angular/common";
import { ConfigureService } from "./configure.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";

@NgModule({
    imports: [CommonModule, HttpClientModule],
    declarations: [],
    providers: [
        ConfigureService,
        {
            provide: APP_INITIALIZER,
            useFactory: init,
            multi: true,
            deps: [ConfigureService, HttpClient],
        },
    ],
})
export class ConfigureModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ConfigureModule,
            providers: [ConfigureService],
        };
    }
}

export function init(configService: ConfigureService, http: HttpClient) {
    return () => configService.load();
}
