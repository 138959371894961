import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { HttpService } from "../../server/httpService";
import { BrowserService } from "./browser.service";
import { MlpHelper } from "./mlpHelper";
import { Sport } from "./model/sport";

@Injectable({
    providedIn: "root",
})
export class SportSelectService {
    sports: BehaviorSubject<Array<Sport>> = new BehaviorSubject([]);
    sportsCollected: Array<Sport> = [];

    constructor(
        private readonly httpService: HttpService,
        private readonly cookie: CookieService,
        private readonly browserService: BrowserService
    ) {}

    getSportsAsync(city?: string): Observable<Array<Sport>> {
        return this.httpService
            .get(`/sports${city ? `?filter[city]=${city}` : ""}`)
            .pipe(map((data: { data: Array<Sport> }) => data.data));
    }

    getSports(): void {
        this.getSportsAsync().subscribe((items) => {
            this.sportsCollected = items;
            this.sports.next(items);
        });
    }

    setSport(sport: string): void {
        if (this.browserService.isBrowser) {
            this.cookie.put("sport", sport, MlpHelper.oneYearFromNow());
        }
    }

    getSport(): string {
        if (this.browserService.isBrowser) {
            return this.cookie.get("sport");
        }
    }
}
