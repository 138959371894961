import { Component, Input } from "@angular/core";
import { CityVenueCardInterface } from "./city.venue.card.interface";

@Component({
    selector: "mlp-ng-city-venue",
    templateUrl: "./city.venue.component.html",
    styleUrls: ["./city.venue.component.scss"],
})
export class CityVenueComponent {
    activeImage: number;
    @Input() venue: Array<CityVenueCardInterface> = [];

    isVisible(newIndex: number): void {
        this.activeImage = newIndex;
    }
}
