// tslint:disable: no-null-keyword

import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

import { Observable, of } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    mapTo,
    mergeMap,
    switchMap,
    tap,
} from "rxjs/operators";

import { DateService } from "../../date.service";
import { EventTrackingService } from "../../eventTracking.service";
import { Pitch } from "../../model/pitch";
import { Sport } from "../../model/sport";
import { Venue } from "../../model/venue";
import { DateTimeSelection } from "./auto-suggest.interface";
import { AutoSuggestService } from "./auto-suggest.service";

export const INPUT_CUSTOM_INPUT_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-forward-ref no-use-before-declare
    useExisting: forwardRef(() => AutoSuggestComponent),
    multi: true,
};

@Component({
    selector: "pf-auto-suggest",
    templateUrl: "./auto-suggest.component.html",
    styleUrls: ["./auto-suggest.component.scss"],
    providers: [INPUT_CUSTOM_INPUT_ACCESSOR],
})
export class AutoSuggestComponent implements ControlValueAccessor, OnInit {
    @Input() layout = "vertical";
    @Input() uniqueInputId: string;

    // tslint:disable-next-line:prefer-output-readonly
    @Input() countries: string[] = ["UK", "IE"];
    @Input() sport: Sport;
    @Input() forceReload = new EventEmitter();

    // tslint:disable-next-line:prefer-output-readonly
    @Output() venueSelected: EventEmitter<Venue> = new EventEmitter();
    // tslint:disable-next-line:prefer-output-readonly
    @Output() pitchSelected: EventEmitter<Pitch> = new EventEmitter();
    // tslint:disable-next-line:prefer-output-readonly
    @Output() locationSelected: EventEmitter<google.maps.places.PlaceResult> =
        new EventEmitter();
    // tslint:disable-next-line:prefer-output-readonly
    @Output() dateTimeSelected: EventEmitter<DateTimeSelection> =
        new EventEmitter();
    // tslint:disable-next-line:prefer-output-readonly
    @Output() closeDropdown: EventEmitter<any> = new EventEmitter<any>();
    // tslint:disable-next-line:prefer-output-readonly no-output-named-after-standard-event
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();

    formGroup: FormGroup;

    googleLocation: google.maps.places.PlaceResult;
    googleResults: Array<google.maps.places.AutocompletePrediction> = [];
    venueResults: Array<Venue> = [];

    selection = -1;

    venue: Venue;
    location: google.maps.places.AutocompletePrediction;
    pitches: Array<Pitch> = [];

    closed = true;
    gettingLocation = false;
    sbt = false;

    private readonly allowedSbtSports = [
        "football",
        "badminton",
        "tennis",
        "squash",
    ];
    private _value: any;

    get input(): AbstractControl {
        return this.formGroup.get("input");
    }
    get pitch(): AbstractControl {
        return this.formGroup.get("pitch");
    }
    get time(): FormControl {
        return this.formGroup.get("time") as FormControl;
    }
    get date(): FormControl {
        return this.formGroup.get("date") as FormControl;
    }
    get value(): string {
        return this._value;
    }
    set value(val) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    // tslint:disable-next-line:no-empty
    onChange: any = () => {};
    // tslint:disable-next-line:no-empty
    onTouched: any = () => {};

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly autoSuggestService: AutoSuggestService,
        private readonly eventTrackingService: EventTrackingService,
        private readonly router: Router,
        public dateService: DateService
    ) {
        this.buildForm();
        this.setupSuggestions();
        this.setupPitches();
        this.setupDateTime();
    }

    ngOnInit(): void {
        this.selection = -1;
    }

    focusInput(): void {
        if (this.input.value && this.closed) {
            this.googleLocation = undefined;

            this.forceReload.emit();
        }
    }

    getLocation(): void {
        if (navigator.geolocation) {
            this.gettingLocation = true;

            navigator.geolocation.getCurrentPosition((position) => {
                this.autoSuggestService
                    .callGoogleGeoCoderLatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    )
                    .subscribe((data) => {
                        this.googleLocation = data[0];
                        this.locationSelected.emit(this.googleLocation);

                        this.formGroup.setValue({
                            input: [data[0].formatted_address],
                            pitch: null,
                            date: null,
                            time: null,
                        });

                        this.gettingLocation = false;

                        if (this.sport) {
                            this.autoSuggestService
                                .callPitchesByLocationSport(
                                    this.googleLocation.geometry.location,
                                    this.sport.id
                                )
                                .subscribe((pitches) => {
                                    if (
                                        pitches.meta.total_online_venues &&
                                        this.allowedSbtSports.indexOf(
                                            this.sport.id
                                        ) !== -1
                                    ) {
                                        this.showSbt();
                                    }
                                });
                        }
                    });
            });
        } else {
            alert("Could not determine your current location.");
        }
    }

    buildForm(): void {
        this.formGroup = this.formBuilder.group({
            input: [this.autoSuggestService.location, Validators.required],
            pitch: null,
            date: null,
            time: null,
        });
    }

    close(): void {
        this.closed = true;
        this.selection = -1;

        this.googleResults = [];
        this.venueResults = [];
    }

    showSbt(): void {
        this.sbt = true;
    }

    openFromGooglePlace(
        result: google.maps.places.AutocompletePrediction
    ): void {
        this.autoSuggestService
            .callGoogleGeoCoder(result.place_id)
            .pipe(
                tap((_) => {
                    this.close();

                    this.selection = -1;
                }),
                tap((data) => (this.googleLocation = data[0])),
                // tslint:disable-next-line: no-void-expression
                tap((data) => this.setInputLocation()),
                // tslint:disable-next-line: no-void-expression
                map((_) => this.locationSelected.emit(this.googleLocation)),
                filter((_) => !!this.sport),
                mergeMap((_) =>
                    this.autoSuggestService.callPitchesByLocationSport(
                        this.googleLocation.geometry.location,
                        this.sport.id
                    )
                )
            )
            .subscribe((pitches) => {
                this.location = result;

                this.pitches = [];
                this.googleResults = [];
                this.venueResults = [];

                if (
                    pitches &&
                    pitches.meta &&
                    pitches.meta.total_online_venues &&
                    this.allowedSbtSports.indexOf(this.sport.id) !== -1
                ) {
                    this.showSbt();
                } else {
                    this.input.setValue(result.structured_formatting.main_text);

                    const queryParams = {
                        // tslint:disable-next-line: no-unbound-method
                        latitude: this.googleLocation.geometry.location.lat,
                        // tslint:disable-next-line: no-unbound-method
                        longitude: this.googleLocation.geometry.location.lng,
                    };

                    this.closeDropdown.emit();
                    // tslint:disable-next-line: no-floating-promises
                    this.router.navigate(["uk", "results", this.sport.id], {
                        queryParams,
                    });
                }
            });

        this.eventTrackingService.trackAutoCompleteGooglePlace(
            result.structured_formatting.main_text,
            this.input.value
        );
    }

    setInputLocation(): void {
        let location = this.googleLocation.address_components[0].long_name;

        // Prevent location from being "40" when it should be "40 Goodge Street"
        // tslint:disable-next-line: radix
        if (
            parseInt(location).toString() === location &&
            this.googleLocation.address_components.length > 1
        ) {
            location += ` ${this.googleLocation.address_components[1].long_name}`;
        }

        this.input.setValue(location);
    }

    removeLocation(): void {
        this.location = undefined;
    }

    disableSBT(): void {
        this.sbt = false;
    }

    openFromVenue(venue: Venue): void {
        this.autoSuggestService.lastInputValue = this.input.value;
        this.input.reset();

        this.pitches = [];
        const pitches = this.getPitchesFromVenueWithSelectedSport(
            venue,
            this.sport.id
        );

        if (pitches.length === 1) {
            this.input.markAsPristine();
            this.closeDropdown.emit();
            this.trackOpenFromPitch(pitches[0]);
            // tslint:disable-next-line: no-floating-promises
            this.router.navigate([pitches[0].links.weblink]);
        } else if (pitches.length !== 0) {
            this.pitches = pitches;
            this.venue = venue;
            this.venueSelected.emit(venue);
            this.googleResults = [];
            this.venueResults = [];
            this.selection = -1;
        }

        this.close();
    }

    removeVenue(): void {
        this.venue = undefined;
    }

    registerOnChange(fn): void {
        this.onChange = fn;
    }

    writeValue(value): void {
        this.value = value;
    }

    registerOnTouched(fn): void {
        this.onTouched = fn;
    }

    markAsDirty(): void {
        this.input.markAsDirty();
    }

    // tslint:disable-next-line: cyclomatic-complexity
    keyboardControl(event: KeyboardEvent): void {
        if (this.closed) {
            if (event.key === "Enter") {
                this.submit.emit();
            }
        } else {
            // Enter: select or close results list
            if (event.key === "Enter") {
                event.preventDefault();

                if (
                    this.googleResults &&
                    this.selection >= 0 &&
                    this.selection < Math.min(this.googleResults.length, 3)
                ) {
                    this.openFromGooglePlace(
                        this.googleResults[this.selection]
                    );
                } else if (
                    this.googleResults &&
                    this.venueResults &&
                    this.selection >= Math.min(this.googleResults.length, 3) &&
                    this.selection <
                        Math.min(this.googleResults.length, 3) +
                            Math.min(this.venueResults.length, 3)
                ) {
                    this.openFromVenue(
                        this.venueResults[
                            this.selection -
                                Math.min(this.googleResults.length, 3)
                        ]
                    );
                } else if (this.selection === -1) {
                    this.submit.emit();
                } else {
                    this.close();
                }
            }

            // Arrow Down or Tab, move selection down if possible
            if (
                (event.key === "ArrowDown" ||
                    (event.key === "Tab" && !event.shiftKey)) &&
                // tslint:disable-next-line: max-line-length
                this.selection <
                    (this.googleResults ? this.googleResults.length : 0) +
                        (this.venueResults
                            ? Math.min(this.venueResults.length, 3)
                            : 0)
            ) {
                event.preventDefault();

                this.selection++;
            }

            // Arrow Up or Shift + Tab, move selection up if possible
            if (
                (event.key === "ArrowUp" ||
                    (event.key === "Tab" && event.shiftKey)) &&
                this.selection > 0
            ) {
                event.preventDefault();

                this.selection--;
            }
        }
    }

    protected getPitchesFromVenueWithSelectedSport(
        venue: Venue,
        sportSlug: string
    ): Array<Pitch> {
        return venue.relationships.pitches.data.filter(
            (pitch) => pitch.attributes.sport.replace(" ", "-") === sportSlug
        );
    }

    private setupSuggestions(): void {
        this.input.valueChanges
            .pipe(
                // tslint:disable-next-line: no-void-expression
                tap((_) => this.removeLocation()),
                tap((value) => (this.autoSuggestService.location = value)),
                tap((value) => (this.value = value)), // we update parent formcontrol to stay sync
                filter((_) => !!this.sport),
                tap((value) => (!value ? this.resetSuggestions() : "")),
                debounceTime(250),
                // tslint:disable-next-line: no-void-expression
                tap((_) => this.disableSBT()),
                distinctUntilChanged(),
                switchMap((value) => this.getData(value))
            )
            .subscribe();
    }

    private setupPitches(): void {
        this.pitch.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((pitch) => {
                this.pitchSelected.emit(pitch);
            });
    }

    private setupDateTime(): void {
        this.date.valueChanges.subscribe((date) => {
            // tslint:disable-next-line: no-object-literal-type-assertion object-literal-shorthand
            this.dateTimeSelected.emit({
                date: date,
                time: this.time.value,
            } as DateTimeSelection);
        });

        this.time.valueChanges.subscribe((time) => {
            // tslint:disable-next-line: no-object-literal-type-assertion
            this.dateTimeSelected.emit({
                date: this.date.value,
                time,
            } as DateTimeSelection);
        });
    }

    private resetSuggestions(): void {
        this.googleLocation = undefined;
        this.googleResults = [];
        this.venueResults = [];
        this.selection = -1;
    }

    private getData(valuePassed): Observable<any> {
        return of(valuePassed).pipe(
            filter((value) => value),
            switchMap((value) =>
                this.autoSuggestService
                    .callAutoComplete(value, this.countries)
                    .pipe(
                        tap((data) => {
                            if (
                                !this.googleLocation &&
                                !this.venue &&
                                value === this.input.value
                            ) {
                                this.googleResults = data;
                                this.closed = false;
                            }
                        }),
                        mapTo(value)
                    )
            ),
            switchMap((value) =>
                this.autoSuggestService
                    .callVenueByNameSport(value, this.sport.id)
                    .pipe(
                        map((venues) => {
                            if (
                                !this.googleLocation &&
                                !this.location &&
                                value === this.input.value
                            ) {
                                this.venueResults = venues;
                                this.closed = false;
                            }
                        }),
                        mapTo(value)
                    )
            )
        );
    }

    private trackOpenFromPitch(pitch: Pitch): void {
        this.eventTrackingService.trackAutoCompleteVenueSuggestion(
            `${pitch.attributes.name} | ${pitch.attributes.sport} | ${pitch.attributes.format} | ${pitch.attributes.surface}`,
            this.autoSuggestService.lastInputValue
        );
    }
}
