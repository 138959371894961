import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "../auth.service";
var KeepMeLoggedInComponent = /** @class */ (function () {
    function KeepMeLoggedInComponent(fb, authService) {
        this.fb = fb;
        this.authService = authService;
    }
    Object.defineProperty(KeepMeLoggedInComponent.prototype, "keepMeLoggedIn", {
        get: function () {
            return this.keepLogged.get("keepMeLoggedIn");
        },
        enumerable: true,
        configurable: true
    });
    KeepMeLoggedInComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.keepLogged = this.fb.group({
            keepMeLoggedIn: [null, []],
        });
        this.keepMeLoggedIn.valueChanges.subscribe(function (val) { return (_this.authService.rememberMe = val); });
    };
    return KeepMeLoggedInComponent;
}());
export { KeepMeLoggedInComponent };
