import { Component, Input } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import {
    customSelectRequiredValidator,
    ISelect,
    ISelectMolecule,
} from "pf_styleguide";

@Component({
    selector: "pf-city-select",
    templateUrl: "./city-select.component.html",
})
export class CitySelectComponent {
    @Input() options: Array<ISelect> = [];

    form: FormGroup;

    get city(): AbstractControl {
        return this.form.get("city");
    }

    constructor(private fb: FormBuilder, private router: Router) {
        this.form = this.fb.group({
            city: [
                {
                    description: "Select City",
                    // tslint:disable-next-line:no-null-keyword
                    data: null,
                },
                customSelectRequiredValidator(),
            ],
        });
    }

    submitted(): void {
        if (this.city.valid && this.city.value.data) {
            this.router.navigate([this.city.value.data]);
        }
    }
}
