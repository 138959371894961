<ng-template #signInContent let-closeModal="close">
    <mlp-ng-modal>
        <section header class="sg-heading__h5">Welcome to Playfinder</section>

        <section body>
            <ng-container *ngIf="!success">
                <pf-sign-in
                    (closeModal)="closeModal()"
                    (showSignUp)="closeModal(); switchModal(); (false)"
                    (loginSuccess)="loginSuccess($event)"
                ></pf-sign-in>
            </ng-container>

            <ng-container *ngIf="success">
                <p class="mb-3 text-center">You have been signed in.</p>

                <div class="mb-3">
                    <a [routerLink]="dashboardLink" (click)="closeModal()">
                        <mlp-ng-button
                            label="Go to dashboard"
                            fullWidth="1"
                        ></mlp-ng-button>
                    </a>
                </div>

                <div class="mb-2">
                    <mlp-ng-button
                        label="Close"
                        fullWidth="1"
                        (click)="closeModal()"
                    ></mlp-ng-button>
                </div>
            </ng-container>
        </section>
    </mlp-ng-modal>
</ng-template>
