import { Component, Input } from "@angular/core";

@Component({
    selector: "pf-sports-icons",
    templateUrl: "./sports-icons.component.html",
    styleUrls: ["./sports-icons.component.scss"],
})
export class SportsIconsComponent {
    @Input() sports;
    @Input() title: string;
    @Input() city: string;
    @Input() latLong: { lat: number; long: number };
    isOpen = false;
}
