import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
    ButtonSharedModule,
    CheckBoxSharedModule,
    InputSharedModule,
    PasswordStrengthSharedModule,
} from "pf_styleguide";

import { ErrorsModule } from "../../errors/errors.module";
import { PfSocialLoginModule } from "../social-login/social-login.module";
import { SignUpComponent } from "./sign-up.component";

@NgModule({
    declarations: [SignUpComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,

        InputSharedModule,
        ButtonSharedModule,
        ErrorsModule,
        PfSocialLoginModule,
        CheckBoxSharedModule,
        PasswordStrengthSharedModule,
    ],
    exports: [SignUpComponent],
})
export class SignUpModule {}
