import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
} from "@angular/core";

/* tslint:disable-next-line */
const MouseEvent = (global as any).MouseEvent as MouseEvent;
/* tslint:disable-next-line */
const HTMLElement = (global as any).HTMLElement as HTMLElement;

@Directive({
    selector: "[ngxClickOutside]",
})
export class ClickOutsideDirective {
    @Output() ngxClickOutside = new EventEmitter<MouseEvent>();

    constructor(private _elementRef: ElementRef) {}

    @HostListener("document:click", ["$event", "$event.target"])
    onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside =
            this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.ngxClickOutside.emit(event);
        }
    }
}
