import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { DiscontinuedSportComponent } from "./discontinued-sport.component";
import { DiscontinuedSportService } from "./discontinued-sport.service";

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [DiscontinuedSportComponent],
    providers: [DiscontinuedSportService],
})
export class DiscontinuedSportModule {}
