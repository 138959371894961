import { EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, Validators, } from "@angular/forms";
import { Router } from "@angular/router";
import { V_EMAIL, V_FULL_NAME, V_PASSWORD, V_PASSWORD_REPEAT, } from "pf_styleguide/shared";
import { EventTrackingService } from "../../../eventTracking.service";
import { GenericService } from "../../../generic.service";
import { CreateUserApi } from "../../../model/createUserApi";
import { ErrorCollection } from "../../../model/errorCollection";
import { AuthService } from "../auth.service";
import { ConfigureService } from "./../../../../configure/configure.service";
var SignUpComponent = /** @class */ (function () {
    function SignUpComponent(authService, eventTracking, fb, router, genericService, config) {
        this.authService = authService;
        this.eventTracking = eventTracking;
        this.fb = fb;
        this.router = router;
        this.genericService = genericService;
        this.config = config;
        this.showSignIn = new EventEmitter();
        this.loginSuccess = new EventEmitter();
        this.loginError = new EventEmitter();
        this.back = new EventEmitter();
        this.guest = new EventEmitter();
        this.checkout = false;
        this.success = false;
        this.errorCollection = new ErrorCollection();
        this.V_EMAIL = V_EMAIL;
        this.V_FULL_NAME = V_FULL_NAME;
        this.V_PASSWORD = V_PASSWORD;
        this.V_PASSWORD_REPEAT = V_PASSWORD_REPEAT;
        this.resetForm();
    }
    Object.defineProperty(SignUpComponent.prototype, "fullName", {
        get: function () {
            return this.formGroup.get("fullName");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignUpComponent.prototype, "email", {
        get: function () {
            return this.formGroup.get("email");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignUpComponent.prototype, "password", {
        get: function () {
            return this.formGroup.get("password");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignUpComponent.prototype, "isAgreedOnTerms", {
        get: function () {
            return this.formGroup.get("isAgreedOnTerms");
        },
        enumerable: true,
        configurable: true
    });
    SignUpComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    SignUpComponent.prototype.buildForm = function () {
        var _this = this;
        this.formGroup = this.fb.group({
            fullName: ["", V_FULL_NAME.requirements],
            email: ["", V_EMAIL.requirements],
            password: ["", V_PASSWORD.requirements],
            isAgreedOnTerms: [null, [Validators.required]],
        });
        this.formGroup.controls.email.valueChanges.subscribe(function (v) { return (_this.emailErrors = undefined); });
    };
    SignUpComponent.prototype.onSubmit = function () {
        var _this = this;
        this.genericService.markFormControlsAsTouched(this.formGroup);
        if (this.formGroup.valid && this.isAgreedOnTerms.value) {
            this.resetForm();
            var user = new CreateUserApi(this.fullName.value, this.email.value, this.password.value);
            this.authService.signUp(user).subscribe(
            // tslint:disable-next-line: no-void-expression
            function () { return _this.processSuccess(); }, function (errorData) {
                _this.errorCollection.errors = errorData.filter(function (e) { return e.source !== "users.email"; });
                _this.emailErrors = errorData.filter(function (e) { return e.source === "users.email"; });
                _this.loginError.emit();
            });
        }
    };
    SignUpComponent.prototype.resetForm = function () {
        this.success = false;
        this.errorCollection.reset();
        this.emailErrors = undefined;
    };
    SignUpComponent.prototype.loginSuccessProcess = function ($event) {
        if ($event)
            this.processSuccess();
        if (!$event)
            this.unprocessed();
    };
    SignUpComponent.prototype.unprocessed = function () {
        this.success = false;
        this.loginSuccess.emit(false);
    };
    SignUpComponent.prototype.processSuccess = function () {
        this.success = true;
        this.loginSuccess.emit(true);
        this.eventTracking.trackEvent("Account Sign Up", "User", this.router.url);
    };
    return SignUpComponent;
}());
export { SignUpComponent };
