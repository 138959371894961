import { Component, forwardRef, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms";

import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

import { MlpHelper } from "../../mlpHelper";

@Component({
    selector: "mlp-select-time",
    templateUrl: "./select-time.component.html",
    styleUrls: ["./select-time.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => SelectTimeComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectTimeComponent,
        },
    ],
})
export class SelectTimeComponent
    implements OnInit, ControlValueAccessor, Validator
{
    // tslint:disable-next-line:no-input-rename
    @Input("value") _value: NgbTimeStruct;
    @Input() placeholder = "Select Time";
    @Input() showError: boolean;

    time: NgbTimeStruct;

    formControl: AbstractControl;

    get value(): NgbTimeStruct {
        return this._value;
    }
    set value(val: NgbTimeStruct) {
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    ngOnInit(): void {
        const now = new Date();

        let minutes = Math.round(now.getMinutes() / 15) * 15; // round time to 15 minutes
        let hours = now.getHours();

        if (minutes === 60) {
            minutes = 0;
            hours++;
        }

        this.time = { hour: hours, minute: minutes, second: 0 };
    }

    writeValue(value: NgbTimeStruct): void {
        this.value = value;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        this.formControl = c;

        return c.valid ? undefined : c.errors;
    }

    // tslint:disable-next-line:no-empty
    onChange: any = () => {};
    // tslint:disable-next-line:no-empty
    onTouched: any = () => {};

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    selectedTime($event: NgbTimeStruct): void {
        if ($event.hour && $event.minute) {
            this.value = $event;
        }
    }

    selected(): void {
        this.writeValue(this.time);
    }

    formatValue(value: NgbTimeStruct): string {
        return `${MlpHelper.pad(value.hour, 2)}:${MlpHelper.pad(
            value.minute,
            2
        )}`;
    }
}
