import { OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator, } from "@angular/forms";
import { MlpHelper } from "../../mlpHelper";
var SelectTimeComponent = /** @class */ (function () {
    function SelectTimeComponent() {
        this.placeholder = "Select Time";
        // tslint:disable-next-line:no-empty
        this.onChange = function () { };
        // tslint:disable-next-line:no-empty
        this.onTouched = function () { };
    }
    Object.defineProperty(SelectTimeComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this._value = val;
            this.onChange(val);
            this.onTouched();
        },
        enumerable: true,
        configurable: true
    });
    SelectTimeComponent.prototype.ngOnInit = function () {
        var now = new Date();
        var minutes = Math.round(now.getMinutes() / 15) * 15; // round time to 15 minutes
        var hours = now.getHours();
        if (minutes === 60) {
            minutes = 0;
            hours++;
        }
        this.time = { hour: hours, minute: minutes, second: 0 };
    };
    SelectTimeComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    SelectTimeComponent.prototype.validate = function (c) {
        this.formControl = c;
        return c.valid ? undefined : c.errors;
    };
    SelectTimeComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    SelectTimeComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    SelectTimeComponent.prototype.selectedTime = function ($event) {
        if ($event.hour && $event.minute) {
            this.value = $event;
        }
    };
    SelectTimeComponent.prototype.selected = function () {
        this.writeValue(this.time);
    };
    SelectTimeComponent.prototype.formatValue = function (value) {
        return MlpHelper.pad(value.hour, 2) + ":" + MlpHelper.pad(value.minute, 2);
    };
    return SelectTimeComponent;
}());
export { SelectTimeComponent };
