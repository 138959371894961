/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stats.components.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stats.component";
var styles_StatsSharedComponent = [i0.styles];
var RenderType_StatsSharedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatsSharedComponent, data: {} });
export { RenderType_StatsSharedComponent as RenderType_StatsSharedComponent };
function View_StatsSharedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "sg-heading__h3 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n                    ", "\n                "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.amount; _ck(_v, 1, 0, currVal_0); }); }
function View_StatsSharedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "stats sg-rounded text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "a", [["class", "stats__link"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsSharedComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "sg-subheading__h3 text-uppercase mb-4"]], [[2, "pt-3", null]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["\n                    ", "\n                "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.amount; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.link; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_v.parent.context.$implicit.amount; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.sport; _ck(_v, 8, 0, currVal_3); }); }
function View_StatsSharedComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "sg-heading__h3 pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n                    ", "\n                "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.amount; _ck(_v, 1, 0, currVal_0); }); }
function View_StatsSharedComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "stats sg-rounded text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsSharedComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n                "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "sg-subheading__h3 text-uppercase mb-4"]], [[2, "pt-3", null]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\n                    ", "\n                "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.amount; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = !_v.parent.context.$implicit.amount; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.sport; _ck(_v, 7, 0, currVal_2); }); }
function View_StatsSharedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-sm-6 col-md-3 sg-mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsSharedComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵand(0, [["links", 2]], null, 0, null, View_StatsSharedComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_StatsSharedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsSharedComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stats; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_StatsSharedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mlp-ng-shared-stats", [], null, null, null, View_StatsSharedComponent_0, RenderType_StatsSharedComponent)), i1.ɵdid(1, 49152, null, 0, i3.StatsSharedComponent, [], null, null)], null, null); }
var StatsSharedComponentNgFactory = i1.ɵccf("mlp-ng-shared-stats", i3.StatsSharedComponent, View_StatsSharedComponent_Host_0, { stats: "stats" }, {}, []);
export { StatsSharedComponentNgFactory as StatsSharedComponentNgFactory };
