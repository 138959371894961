<div ngbDropdown #dropDownSelectDate="ngbDropdown" autoClose="outside">
    <div ngbDropdownAnchor id="dropdownSelectDuration">
        <button
            class="pf-form-button"
            type="button"
            ngbDropdownToggle
            [ngClass]="{
                '-active': value,
                '-error':
                    (formControl.touched && !formControl.valid) || showError
            }"
        >
            {{ !value ? placeholder : formatValue(value) }}
            <mlp-ng-icon
                *ngIf="!value"
                class="pf-form-button__icon"
                name="arrow-down-1"
            ></mlp-ng-icon>
        </button>
    </div>

    <div
        class="dropdown-menu shadow-sm p-0 border-0"
        ngbDropdownMenu
        aria-labelledby="dropdownSelectDate"
    >
        <ngb-datepicker
            #dp
            formControlName="date"
            [minDate]="minDate"
            [maxDate]="maxDate"
            (select)="selectedDate($event); dropDownSelectDate.close()"
        ></ngb-datepicker>
    </div>
</div>
