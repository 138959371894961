import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IconSharedModule } from "pf_styleguide";
import { SportToPitchCourtTextPipe } from "../../../pipe/sport-to-pitch-court-text.pipe";

import { BookingProcessSharedComponent } from "./booking.process.component";
import { BookingProcessSmallSharedComponent } from "./booking.process.small/booking.process.small.component";

@NgModule({
    declarations: [
        BookingProcessSharedComponent,
        BookingProcessSmallSharedComponent,
    ],
    imports: [CommonModule, IconSharedModule],
    exports: [
        BookingProcessSharedComponent,
        BookingProcessSmallSharedComponent,
    ],
    providers: [SportToPitchCourtTextPipe],
})
export class BookingProcessSharedModule {}
