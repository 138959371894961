import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import {
    ButtonSharedModule,
    ChipSharedModule,
    IconSharedModule,
    InputSharedModule,
    RadioSharedModule,
} from "pf_styleguide";

import { TransformApiData } from "pf_styleguide/shared";

import { AutoSuggestComponent } from "./auto-suggest.component";
import { AutoSuggestService } from "./auto-suggest.service";
import { SelectDateModule } from "../select-date/select-date.module";
import { SelectTimeModule } from "../select-time/select-time.module";

import { ClickOutsideSharedDirectiveModule } from "../../../directive/click-outside/click.outside.module";

@NgModule({
    declarations: [AutoSuggestComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        ButtonSharedModule,
        ChipSharedModule,
        InputSharedModule,
        IconSharedModule,
        RadioSharedModule,
        SelectDateModule,
        SelectTimeModule,
        ClickOutsideSharedDirectiveModule,
    ],
    exports: [AutoSuggestComponent],
    providers: [AutoSuggestService, TransformApiData],
})
export class AutoSuggestModule {}
