<div class="row">
    <div class="col-sm-6 col-md-3 sg-mb-1" *ngFor="let stat of stats">
        <div class="stats sg-rounded text-center" *ngIf="stat.link; else links">
            <a [href]="stat.link" class="stats__link">
                <h4 class="sg-heading__h3 pt-4" *ngIf="stat.amount">
                    {{ stat.amount }}
                </h4>
                <p
                    class="sg-subheading__h3 text-uppercase mb-4"
                    [class.pt-3]="!stat.amount"
                >
                    {{ stat.sport }}
                </p>
            </a>
        </div>
        <ng-template #links>
            <div class="stats sg-rounded text-center">
                <h4 class="sg-heading__h3 pt-4" *ngIf="stat.amount">
                    {{ stat.amount }}
                </h4>
                <p
                    class="sg-subheading__h3 text-uppercase mb-4"
                    [class.pt-3]="!stat.amount"
                >
                    {{ stat.sport }}
                </p>
            </div>
        </ng-template>
    </div>
</div>
