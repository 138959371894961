import {
    AfterViewInit,
    Component,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { BrowserService } from "./shared/browser.service";

import { CookiePolicyService } from "./shared/cookie.policy.service";

import { ConfigureService } from "./configure/configure.service";
import { LinkService } from "./shared/link.service";
import { WindowRefService } from "./shared/window.service";

@Component({
    // tslint:disable-next-line: component-selector
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    // tslint:disable-next-line: use-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
    hasCookie = true;

    constructor(
        private readonly _router: Router,
        private readonly _meta: Meta,
        private readonly _title: Title,
        private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        private readonly browserService: BrowserService,
        private readonly windowRef: WindowRefService,
        private readonly config: ConfigureService,
        private readonly linkService: LinkService,
        public cookiePolicyService: CookiePolicyService
    ) {}

    ngOnInit(): void {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // tslint:disable-next-line: switch-default
                switch (event.urlAfterRedirects) {
                    case "/":
                        this._title.setTitle(
                            "Playfinder | Book sports facilities online"
                        );
                        // tslint:disable-next-line: max-line-length
                        this._meta.updateTag({
                            name: "description",
                            content:
                                "Playfinder helps sports players find and book sports facilities in the UK and Ireland. From football pitches to badminton courts, hire sports facilities for 16 different sports on a variety of playing surfaces.",
                        });
                        break;
                    case "/app":
                        this._title.setTitle(
                            "Download the Playfinder App | Available on iOS and Android"
                        );
                        this._meta.updateTag({
                            name: "description",
                            // tslint:disable-next-line: max-line-length
                            content:
                                "Download the Playfinder app to find and book sports facilities on your iOS or Android device. Manage your bookings and pay securely through the app.",
                        });
                        this._meta.updateTag({
                            name: "keywords",
                            content:
                                "Sports facilities, London, Dublin, Manchester, book, hire, find, download, ios app, iphone, ipad",
                        });
                        break;
                }
                if (this.shouldBeAddedToCanonical(event.urlAfterRedirects)) {
                    // tslint:disable-next-line: max-line-length
                    this.linkService.addTag({
                        rel: "canonical",
                        href: `${
                            this.config.params.canonicalDomain
                        }${this.removeUrlParams(event.urlAfterRedirects)}`,
                    });
                }
            }
        });

        if (this.browserService.isBrowser) {
            this.hasCookie = this.cookiePolicyService.checkCookie();
            // always scroll to the top and exclude links with anchors (#)
            this._router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    filter(
                        (event: NavigationEnd) => event.url.indexOf("#") === -1
                    )
                )
                .subscribe((_) => {
                    this.windowRef.nativeWindow.scroll(0, 0);
                });
        }
    }

    ngAfterViewInit(): void {
        if (this.browserService.isBrowser) {
            setTimeout(
                (_) => {
                    this.windowRef.nativeWindow.dataLayer.push({
                        event: "optimize.activate",
                    });
                },
                0,
                false
            );
        }
    }

    removeUrlParams(url: string): string {
        const URLARR = url.split("/");
        let tempUrl = url;
        if (tempUrl.includes("?")) {
            tempUrl = tempUrl.substring(0, tempUrl.lastIndexOf("?"));
        }

        if (URLARR.indexOf("venue") > -1) {
            const urlpart = tempUrl.substring(0, tempUrl.lastIndexOf("/"));
            tempUrl = urlpart.endsWith("venue")
                ? url.lastIndexOf("?") > -1
                    ? this.removeCheckoutParams(
                          url.substring(0, url.lastIndexOf("?"))
                      )
                    : this.removeCheckoutParams(tempUrl)
                : this.removeCheckoutParams(urlpart);
        }

        return tempUrl;
    }

    shouldBeAddedToCanonical(url: string): boolean {
        return !url.includes("latitude")
            ? true
            : url.split("/").filter((item) => item !== "results" && item !== "")
                  .length > 2;
    }

    removeCheckoutParams(url: string): string {
        if (!url.includes("checkout")) {
            return url;
        }

        return `/${url
            .substring(0, url.indexOf("/checkout"))
            .split("/")
            .slice(0, 4)
            .filter((item) => item !== "")
            .join("/")}`;
    }
}
