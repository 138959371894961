var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { MEGAFOOTERDATA } from "../app.mega.footer.data";
import { ConfigureService } from "../configure/configure.service";
import { CitySelectService } from "../shared/city-select.service";
import { EventTrackingService } from "../shared/eventTracking.service";
import { SportSelectService } from "../shared/sport-select.service";
var FooterComponent = /** @class */ (function () {
    function FooterComponent(citySelectService, sportSelectService, config, router, eventTrackingService) {
        this.citySelectService = citySelectService;
        this.sportSelectService = sportSelectService;
        this.config = config;
        this.router = router;
        this.eventTrackingService = eventTrackingService;
        this.data = MEGAFOOTERDATA;
        this.icons = [
            {
                iconName: "social-media-facebook-1",
                iconLink: "https://www.facebook.com/playfinder",
                className: "col-3",
            },
            {
                iconName: "social-media-twitter",
                iconLink: "https://twitter.com/Playfinder",
                className: "col-3",
            },
            {
                iconName: "social-instagram",
                iconLink: "https://www.instagram.com/playfinder/",
                className: "col-3",
            },
            {
                iconName: "professional-network-linkedin",
                iconLink: "https://www.linkedin.com/company/playfinder",
                className: "col-3",
            },
        ];
        this.action = "https://playfinder.us7.list-manage.com/subscribe/post?u=a8becbd3c11bcdb30e3ca06cb&amp;id=c2e0463071";
        this.cities = { data: [] };
        this.eventUrl = "";
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.citySelectService.cities.subscribe(function (cities) {
            cities.forEach(function (city) {
                var cityClone = __assign({}, city, { slug: "/" + city.slug });
                _this.cities.data.push({ attributes: cityClone });
            });
        });
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }))
            .subscribe(function (navEnd) {
            _this.eventUrl = navEnd.urlAfterRedirects;
        });
    };
    FooterComponent.prototype.trackEvent = function (item) {
        this.eventTrackingService.trackEvent("Social footer", this.resolveName(item.iconName), this.eventUrl);
    };
    FooterComponent.prototype.resolveName = function (iconName) {
        var arrayResolvedNames = [
            {
                iconName: "social-media-facebook-1",
                gaName: "Facebook",
            },
            {
                iconName: "social-media-twitter",
                gaName: "Twitter",
            },
            {
                iconName: "social-instagram",
                gaName: "Instagram",
            },
            {
                iconName: "professional-network-linkedin",
                gaName: "LinkedIn",
            },
        ];
        return arrayResolvedNames.find(function (item) { return item.iconName === iconName; }).gaName;
    };
    return FooterComponent;
}());
export { FooterComponent };
