import { ApiErrorsInterface } from "./api.interface";

export class ErrorCollection implements ApiErrorsInterface {
    errors: Array<{
        title: string;
        source?: string;
        description?: string;
    }> = [];

    reset(): void {
        this.errors = [];
    }
}
