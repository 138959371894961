<section class="pf-process__container text-center text-md-left">
    <section class="container">
        <mlp-ng-icon
            class="pf-process__icon d-inline-block mr-md-2"
            name="search"
        ></mlp-ng-icon>
        <span class="d-none d-xl-inline-block align-middle"
            >Find sports facilities</span
        >
        <span class="d-none d-md-inline-block d-xl-none align-middle"
            >Find</span
        >

        <mlp-ng-icon
            class="pf-process__chevron d-inline-block"
            name="arrow-right-1"
        ></mlp-ng-icon>

        <mlp-ng-icon
            class="pf-process__icon mr-md-2 d-inline-block"
            name="calendar-1"
        ></mlp-ng-icon>
        <span class="d-none d-md-inline-block d-xl-none align-middle"
            >Book</span
        >
        <span class="d-none d-xl-inline-block align-middle"
            >Book online or enquire</span
        >

        <mlp-ng-icon
            class="pf-process__chevron d-inline-block"
            name="arrow-right-1"
        ></mlp-ng-icon>

        <mlp-ng-icon
            class="pf-process__icon mr-md-2 d-inline-block"
            [name]="sport ? sport : 'custom-sports'"
        ></mlp-ng-icon>
        <span class="d-none d-md-inline-block d-xl-none align-middle"
            >Play</span
        >
        <span class="d-none d-xl-inline-block align-middle"
            >Play your game</span
        >
    </section>

    <section class="pf-process__shard">
        <div class="-container">
            <a href="https://www.bookteq.com">
                <img
                    src="/ng-assets/styleguide/logo/playfinder_bookteq_stacked.svg"
                    alt="Bookteq Powered by Playfinder"
                    class="lazyload"
                    loading="lazy"
                />
            </a>
        </div>
    </section>
</section>
